<template>
  <div class="offRoom">
    <div class="offRoomCont">
      <div class="offRoomContTitle">
        <span v-if="betPramas.protocol === 2">{{ $t('liveRoom.endLive') }}</span>
        <span v-if="betPramas.protocol === 5">{{ $t('liveRoom.kickOutLiveRoomTips') }}</span>
      </div>
      <img class="avatar" v-if="betPramas && betPramas.avatar" v-real-img="betPramas.avatar" />
      <img class="avatar" v-else src="../../assets/img/errorImg/2.png" />
      <p class="nickName">{{ betPramas.nickname }}</p>
      <div @click.stop="follow" class="btn">
        {{ betPramas.isFollow ? $t('my.follow.followed') : `${$t('my.follows')}` }}
      </div>
    </div>
    <div class="offRoomBtn" :class="showChangeBtn ? 'flexBetween' : 'flexCenterBL'">
      <div @click="goBack" class="defulat">{{ $t('liveRoom.goHome') }}</div>
      <div v-show="showChangeBtn" @click="changeRoom">{{ $t('liveRoom.changeRoom') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { liveFollow } from "@/api/index";
import interLive from '@/pages/mixin/interRoom';
export default {
  name: "liveRoomOffRoom",
  mixins: [interLive],
  data() {
    return {
      showChangeBtn: false
    }
  },
  computed: {
    ...mapState(['member', 'betPramas', 'liveList', 'entry']),
    isManage() {
      let flag = false;
      if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
        let index = this.member.badgeList.findIndex(item => {
          return item == '4'
        });

        if (index != -1) {
          flag = true;
        }
      }
      return flag;
    }
  },
  created() {
    if (this.liveList && this.liveList.length > 1) {
      this.showChangeBtn = true;
    }
  },
  methods: {
    goBack() {
      let arr = ['/home', '/leaderboard', '/mine/follow'],
      entry = '/home',
      index = arr.findIndex(item => {
        return this.entry.indexOf(item) != -1
      });

      if (index != -1) {
        this.$router.push(this.entry);
        entry = this.entry;
      }
      this.$router.push(entry);
    },
    // 关注 - 取关
    follow() {
      let params = {
        isFollow: this.betPramas.isFollow ? false : true,
        targetId: this.betPramas.uid ? this.betPramas.uid : this.betPramas.anchorId
      }
      liveFollow(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          let str = this.betPramas.isFollow ? this.$t('my.follow.Unfollowed') : this.$t('my.follow.followed');
          this.$toast(str);
          let obj = JSON.parse(JSON.stringify(this.betPramas));
          obj.isFollow = params.isFollow;
          this.$store.commit("setState", { betPramas: obj });
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    changeRoom() {
      let nextIndex = 0,
        arr = this.liveList && this.liveList.length > 0 ? JSON.parse(JSON.stringify(this.liveList)) : [],
        index = arr.findIndex(item => {
          return item.liveId && item.liveId == this.betPramas.liveId;
        });

      if (index != -1 && index != arr.length - 1) {
        nextIndex = index + 1;
      }
      if (nextIndex != -1) {
        this.getAnchorBase(arr[nextIndex]);
      }
    },
  }
}
</script>

<style lang="scss">
.offRoom {
  color: #fff;
  font-size: 0.28rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  .offRoomCont {
    padding-top: 2rem;

    .offRoomContTitle {
      font-size: 0.4rem;
    }

    .avatar {
      width: 1.28rem;
      height: 1.28rem;
      border-radius: 50%;
      margin-top: 0.4rem;
    }

    .nickName {
      margin: 0.2rem auto;
    }

    .btn {
      background-color: #f74995;
      padding: 0.1rem 0.4rem;
      border-radius: 0.4rem;
      display: inline-block;
    }
  }

  .offRoomBtn {
    position: fixed;
    bottom: 0.4rem;
    width: 100%;
    padding: 0 0.28rem;

    div {
      padding: 0.1rem 0.4rem;
      border-radius: 0.4rem;
      display: inline-block;
      background-color: #f74995;
    }
  }
}
</style>