.<template>
    <div class="powder next">
        <div class="title">
            <span v-show="type == 'follow'"> {{ $t('my.follow.follow') }} </span>
            <span v-show="type == 'fans'"> {{ $t('my.follow.fans') }} </span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div v-if="list.length > 0" class="powderBox">
            <div class="flexBetween powderItem" v-for="(item, index) in list" :key="`${index}--powder`">
                <div class="flexCenter">
                    <div class="powderAvatarBox">
                        <img @click="goUserDetail(item)" class="powderAvatar" v-real-img="item.avatar" />
                        <img class="userInLive" v-if="item.liveId && type == 'follow'" src="../../assets/img/home/leaderboard/live.gif" />
                    </div>
                    <span>{{ item.nickname }}</span>
                    
                    <img v-if="item.sex == 2" src="../../assets/img/my/icon/woman.png" />
                    <img v-else src="../../assets/img/my/icon/man.png" />

                    <div class="tableIcon">
                        <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                        <span :class="{'small':item.userLevel<10}">{{ item.userLevel }}</span>
                    </div>
                </div>

                <div v-if="type == 'follow'" @click="follow(item)"  class="interactiveBtn notFocusOn">
                    {{ $t('my.follow.followed') }}
                </div>

                <div v-if="type == 'fans'" @click="follow(item)"  class="interactiveBtn" :class="item.isFollow ? 'notFocusOn' : 'focusOn'">
                    {{ item.isFollow ? $t('my.follow.followed') :  `${$t('my.follows')}` }}
                </div>
            </div>
        </div>

        <div v-else class="noData">
            <img src="../../assets/img/record/noData.png" />
            <p>{{ $t('common.noData') }}</p>
        </div>

        <userInfo v-if="showUserInfo" @colsePopup="colsePopup" @refresh="refresh"></userInfo>
    </div>
</template>
  
<script>
import { followList, fansList, liveFollow } from "@/api/index";
import userInfo from "@/pages/components/userInfo.vue";
import interLive from '@/pages/mixin/interRoom';
export default {
    components: {
        userInfo
    },
    mixins: [interLive],
    data() {
        return {
            type: 'follow',
            list: [],
            page: 0,
            showUserInfo: false
        }
    },
    created() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        this.getList();
        this.$store.commit("setState", { entry: '/mine/follow' });
    },
    methods: {
        // 点击用户头像
        goUserDetail(item) {
            if (!item.liveId) {
                let obj = JSON.parse(JSON.stringify(item));
                obj.isSelf = false; // 是否是查看自己的资料
                obj.isFollow = true; //是否已关注
                obj.isFresh = true;
                this.$store.commit("setState", { betPramas: obj });
                this.showUserInfo = true;
            } else {
                this.goLive(item);
            }
        },
        goLive(item) {
            if (!this.member) return this.$router.push('/login');
            if (item.isAd && this.CheckUrl(item.adJumpUrl)) {
                this.$store.commit("setState", { otherWebUrl: item.adJumpUrl, isShowOtherWeb: true });
            }
            if (!item.uid) return;
            let obj = JSON.parse(JSON.stringify(item));
            obj.anchorId = item.uid;
            this.getAnchorBase(obj);
        },
        // 关闭用户资料弹框
        colsePopup() {
            this.showUserInfo = false;
        },
        // 关注 / 取关
        follow(item) {
            let params = {
                targetId: item.uid
            }

            if (this.type == 'follow') {
                params.isFollow = false;
            } else {
                params.isFollow = item.isFollow ? false : true;
            }
            liveFollow(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let str = params.isFollow ? this.$t('my.follow.followed') : this.$t('my.follow.Unfollowed');
                    this.$toast(str);
                    this.getList();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        colseSearch() {
            this.$emit('colseSearch')
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
        // 列表
        getList() {
            let param = {
                page: this.page
            }
            let fun = this.type == 'follow' ? followList : fansList;

            fun(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.list = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }

            }).catch(() => { })
        },
        // 刷新列表
        refresh() {
            this.getList();
        }
    }
}
</script>
  
<style lang="scss" scoped>
.powder {
    padding-top: 0.88rem;

    .powderBox {
        .powderItem {
            padding: 0 0.28rem;
            height: 1.28rem;
            background-color: #fff;
            border-bottom: 1px solid #f9f9f9;

            &:last-child {
                border-color: #fff;
            }

            img {
                margin-right: 0.2rem;
                width: 0.4rem;
            }

            span {
                margin-right: 0.2rem;
            }

            .powderAvatarBox {
                width: 0.92rem;
                height: 0.92rem;
                position: relative;
                margin-right: 0.2rem;

                .powderAvatar {
                    width: 0.92rem;
                    height: 0.92rem;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .userInLive {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 0.44rem;
                    margin-right: 0;
                }
            }

            

            .interactiveBtn {
                line-height: 0.52rem;
                height: 0.52rem;
                color: #fff;
                text-align: center;
                // width: 1.68rem;
                border-radius: 0.8rem;
                font-size: 0.24rem;
                padding: 0 0.4rem;

                &.focusOn {
                    background: -webkit-linear-gradient(bottom, #f15f9d, #ffb2cd);
                    background: linear-gradient(180deg, #f15f9d, #ffb2cd);
                }

                &.notFocusOn {
                    background-color: #d8d8d8;
                }
            }

            .tableIcon {
                position: relative;
                line-height: 0.4rem;
                height: 0.4rem;

                img {
                    width: 0.8rem;
                    height: 0.4rem;
                    object-fit: cover;
                }

                span {
                    position: absolute;
                    right: 0.12rem;
                    color: #fff;
                    font-size: 0.2rem;

                    &.small {
                        right: 0.18rem;
                    }
                }

                &.big {
                    line-height: 0.5rem;
                    height: 0.5rem;

                    img {
                        width: 1rem;
                        height: 0.5rem;
                    }

                    span {
                        right: 0.1rem;
                    }
                }
            }
        }
    }
}</style>