<template>
  <div class="next withDrawOrder">
    <div class="title">
        <span> {{ $t('my.finance.orderDetail') }}  </span>
        <div @click="goOtherPage('/mine')" class="title_ellipsis">
            {{$t('common.finish')}}
        </div>
    </div>

    <div class="orderDetail">
        <div class="orderSuccess flexCenterBL">
            <img src="../../../assets/img/success.png" />
            {{$t('common.submitSuccess')}}
        </div>
        <div class="orderTop"><span>{{ $t('my.finance.withdrawalAmount') }}</span> {{numFormat(digitalNumber(orderDetail.money))}} <span>{{orderDetail.currency}}</span></div>
        <div class="orderSuTop">{{ $t('my.finance.exchangeRate1') }}：1{{$t('my.finance.currency')}} ≈ {{digitalNumber(1*orderDetail.rate)}}{{orderDetail.currency}}</div>
        <div class="orderItem flexBetween">
            <div class="left">{{ $t('my.finance.transactionType') }}</div>
            <div class="right">{{orderDetail.transactionType}}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{ $t('my.finance.transactionItem') }}</div>
            <div class="right">{{orderDetail.transactionWay}}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{ $t('my.finance.status') }}</div>
            <div class="right">
                <span class="isAudit">{{orderDetail.status}}</span>
            </div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{ $t('my.finance.transactionHour') }}</div>
            <div class="right">{{getTime(orderDetail.date)}}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{ $t('my.finance.OrderNumber') }}</div>
        </div>
        <div class="orderItem flexBetweenT">
            <div class="right">{{orderDetail.orderNo}}</div>
            <img @click="copyClick(orderDetail.orderNo)" src="../../../assets/img/record/copyIcon.png" />
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import utils from "@/utils/index";
export default {
    data() {
        return {
            type: "",
            bankInfoObj: {},
            customize: false,
            orderDetail: ""
        }
    },
    created() {
        if (sessionStorage.getItem('orderInfo')) {
            this.orderDetail = JSON.parse(sessionStorage.getItem('orderInfo'));
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        }
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm:ss DD-MM-YYYY');
            }
            return "";
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
    }
}
</script>

<style lang="scss" scoped>
.withDrawOrder {
    .title_ellipsis {
        color: #eb457e;
    }
    .orderDetail {
        background-color: #fff;
        color: #333;
        font-size: 0.27rem;
        padding: 0 0.28rem 0.42rem;

        .orderSuccess {
            width: 100vw;
            font-size: 0.4rem;
            padding-top: 0.5rem;

            img {
                width: 0.8rem;
                margin-right: 0.2rem;
            }
        }

        .orderTop {
            padding: 0.34rem 0 0.12rem;
            font-size: 0.52rem;
            color: #C41F1A;

            span {
                font-size: 0.32rem;
                color: #243050;
            }
        }

        .orderSuTop {
            font-size: 0.24rem;
            padding-bottom: 0.12rem;

            span {
                color: #C41F1A;
                margin: 0 0.1rem;
                font-size: 0.32rem;
            }
        }

        .orderItem {
            padding-top: 0.37rem;
            .left {
                color: #788597;
            }

            img {
                width: 0.31rem;
                margin-left: 0.15rem;
            }

            .right {
                .isAudit {
                    color: #6aaaf5;
                }
                .isSuccess {
                    color: #00B887;
                }
                .isFail {
                    color: #C41F1A;
                }
            }

            &.flexBetweenT {
                display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
                display: -moz-box; /* Firefox 17- */
                display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
                display: -moz-flex; /* Firefox 18+ */
                display: -ms-flexbox; /* IE 10 */
                display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
            
                align-items: flex-start;
                -webkit-align-items: flex-start;
                box-align: flex-start;
                -moz-box-align: flex-start;
                -webkit-box-align: flex-start;
            }
        }
    }
}
</style>