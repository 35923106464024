<template>
  <div class="scrollBox" @scroll="whereScorll">
    <div v-for="item in showChat" :key="`${item.msgId}--liveRoom-chat`">
        <div v-if="item.protocol==-2" class="user scrollItem">
            {{ $t('liveRoom.wellcome') }} <span class="greenBlue">{{ liveInfo.nickname }}</span> {{ $t('liveRoom.liveRoom') }}
        </div>

        <!-- 主播离开回来样式 -->
        <div v-if="item.protocol==3" class="leave scrollItem">
            <span v-if="item.leave"> {{ $t('liveRoom.anchorLeave') }} </span>
            <span v-else> {{ $t('liveRoom.anchorComeBack') }} </span>
        </div>

        <!-- 关注 -->
        <div v-if="item.protocol==4" class="scrollItem" :class="isNoble(item) ? 'noble' : 'user'">
            <div class="nobleLevel">
                <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                <span :class="{'small': item.userLevel<10}">{{item.userLevel}}</span>
            </div>
            <div class="userIcon" v-if="item && item.badgeShowList && item.badgeShowList.length > 0">
                <img v-for="(xitem, xindex) in item.badgeShowList" :key="`${xindex}--liveRoom-msg-badge`" v-real-img="xitem.logoUrl"/>
            </div>
            <span class="greenBlue" @click="goUserCard(item)">{{ item.chatHide ? $t('liveRoom.mysteryMan') : item.nickname }}</span>
            <span>{{ $t('liveRoom.focusOnTips') }}</span>
        </div>

        <!-- 进房 -->
        <div v-if="item.protocol==5" class="scrollItem" :class="isNoble(item) ? 'noble' : 'user'">
            <div v-if="item.userLevel" class="nobleLevel">
                <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                <span :class="{'small': item.userLevel<10}">{{item.userLevel}}</span>
            </div>
            <div class="userIcon" v-if="item && item.badgeShowList && item.badgeShowList.length > 0">
                <img v-for="(xitem, xindex) in item.badgeShowList" :key="`${xindex}--liveRoom-msg-badge`" v-real-img="xitem.logoUrl"/>
            </div>
            <span class="greenBlue" @click="goUserCard(item, 'roomHide')">{{ item.roomHide ? $t('liveRoom.mysteryMan') : item.nickname }}</span>

            <span v-if="getHonorableShow(item.badgeList)">{{ $t('liveRoom.honorable') }}</span>
            <span v-else>{{ $t('liveRoom.wellcomeN') }}</span>
        </div>

        <!-- 送礼 -->
        <div v-if="item.protocol==7" class="scrollItem" :class="isNoble(item) ? 'noble' : 'user'">
            <div v-if="item.userLevel" class="nobleLevel">
                <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                <span :class="{'small': item.userLevel<10}">{{item.userLevel}}</span>
            </div>
            <!-- 徽章列表 -->
            <div class="userIcon" v-if="item && item.badgeShowList && item.badgeShowList.length > 0">
                <img v-for="(xitem, xindex) in item.badgeShowList" :key="`${xindex}--liveRoom-msg-badge`" v-real-img="xitem.logoUrl"/>
            </div>
            <span class="greenBlue" @click="goUserCard(item, 'chatHide')">{{ item.chatHide ? $t('liveRoom.mysteryMan') : item.nickname }}</span>
            
            <span>{{ $t('liveRoom.sendOut') }}</span>

            <span>{{ item.gname }}</span>
            <img class="gamePng" v-real-img="item.cover" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
        </div>


        <!-- 禁言 / 取消禁言 -->
        <div v-if="item.protocol==8" class="leave scrollItem">
            <span class="greenBlue" @click="goUserCard(item, 'chatHide')">{{ item.chatHide ? $t('liveRoom.mysteryMan') : item.nickname }}</span>
            
            <span v-if="item.isBlack">{{ $t('liveRoom.noTalking') }}</span>
            <span v-else>{{ $t('liveRoom.talking') }}</span>
        </div>

        <!-- 聊天 -->
        <div v-if="item.protocol==9" class="scrollItem" :class="isNoble(item) ? 'noble' : 'user'">
            <div v-if="item.userLevel" class="nobleLevel">
                <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                <span :class="{'small': item.userLevel<10}">{{item.userLevel}}</span>
            </div>
            <div class="userIcon" v-if="item && item.badgeShowList && item.badgeShowList.length > 0">
                <img v-for="(xitem, xindex) in item.badgeShowList" :key="`${xindex}--liveRoom-msg-badge`" v-real-img="xitem.logoUrl"/>
            </div>
            <span class="greenBlue" @click="goUserCard(item, 'chatHide')">{{ item.chatHide ? $t('liveRoom.mysteryMan') : item.nickname }}</span>
            <span>{{ item.msg }}</span>
        </div>

        <!-- 直播间公告 -->
        <div v-if="item.protocol==13" class="user scrollItem">
            <span class="yellow" style="margin: 0;" v-html="item.content"></span>
        </div>

        <!-- 踢人 -->
        <div v-if="item.protocol==15" class="leave scrollItem">
            <span class="greenBlue" @click="goUserCard(item, 'chatHide')">{{ item.chatHide ? $t('liveRoom.mysteryMan') : item.nickname }}</span>
            {{ $t('liveRoom.kickOutLiveRoom') }}
        </div>

        <!-- 房间价格变动 -->
        <div v-if="item.protocol==21" class="user scrollItem">
            <span>{{ item.content }}</span>
        </div>

        <!-- 下注 -->
        <div v-if="item.protocol==26" class="user scrollItem">
            <span class="systemBtn">{{ $t('liveRoom.system') }}</span>
            <span class="greenBlue">{{ item.nickName }}</span>
            {{ $t('liveRoom.betMsgIn') }} <span class="yellow">{{ item.name }}</span> {{ $t('liveRoom.betMsgBet') }} <span class="yellow">{{ numFormat(item.totalCoin) }}</span> {{ $t('my.finance.currency') }}
            <span class="betBtn" v-show="baseInfo.withLotterySwitch && item.code != 'yn_hncp' && item.payList && item.payList.length > 0" @click.stop="followUp(item, 'chat')">{{ $t('liveRoom.followUp') }} ></span>
        </div>

        <!-- 彩票中奖 -->
        <div v-if="item.protocol==2227" class="user scrollItem">
            <span class="lottery">{{ $t('liveRoom.winLottery') }}</span>
            <span class="greenBlue">{{ item.nickName }}</span>
            {{ $t('liveRoom.betMsgIn') }} <span class="yellow">{{ item.name }}</span> {{ $t('liveRoom.winLotteryMsg') }} <span class="yellow">{{ numFormat(item.winMoney) }}</span> {{ $t('my.finance.currency') }}
        </div>

        <!-- 设置或取消房管消息 -->
        <div v-if="item.protocol==28" class="user scrollItem" @click="goUserCard(item)">
            <span class="systemBtn">{{ $t('liveRoom.system') }}</span>
            <div class="userIcon" v-if="item && item.badgeShowList && item.badgeShowList.length > 0">
                <img v-for="(xitem, xindex) in item.badgeShowList" :key="`${xindex}--liveRoom-msg-badge`" v-real-img="xitem.logoUrl"/>
            </div>
            <span v-if="item.type === 1">{{ $t('liveRoom.isHousingManagement') }}<span class="greenBlue">{{ item.nickname }}</span>{{ $t('liveRoom.isHousingManagement2') }}
            </span>
            <span v-if="item.type === 2">{{ $t('liveRoom.noHousingManagement') }}<span class="greenBlue">{{ item.nickname }}</span>{{ $t('liveRoom.noHousingManagement2') }}</span>
        </div>

        <!-- 开启关闭连麦 -->
        <div v-if="item.protocol==42" class="user scrollItem">
            <span v-if="item.status == 1">{{ $t('liveRoom.openVoice') }}</span>
            <span v-if="item.status == 2 && item.changeType == 2">{{ $t('liveRoom.openFreeVoice') }}</span>
            <span v-if="item.status == 2 && item.changeType == 1">{{ $t('liveRoom.openPayVoice') }} {{ item.price }} {{ $t('liveRoom.openPayVoice1') }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import lotteryData from '@/pages/liveRoom/mixin/lottery';
export default {
    name: "liveChat",
    mixins: [lotteryData],
    data() {
        return {
            showChat: [],
            chatTimer: null, //获取消息体时间容器
        }
    },
    computed: {
        ...mapState(['chat', 'liveInfo', 'baseInfo', 'member'])
    },
    watch: {
        showChat(val, old) {
            if ( val && old && val.length > old.length) {
                let box = document.getElementsByClassName('scrollBox')[0];
                this.scrollGap =  box.scrollHeight - box.scrollTop - box.offsetHeight ;
                this.$emit('aditScrollGap', this.scrollGap);

                if (this.scrollGap <= 50) {
                    this.$emit('aditMsgNum', 0);
                    this.msgTimer = setTimeout(() => {
                        let box = document.getElementsByClassName('scrollBox')[0];
                        box.scrollTop = box.scrollHeight;

                        if (this.$route.path != '/liveRoom') {
                            clearTimeout(this.msgTimer);
                        }
                    }, 100);
                    
                } else {
                    this.$emit('aditMsgNum', 1);
                }
            }
        },
    },
    mounted() {
        this.getShowChat();
    },
    methods: {
        refreshChat() {
            this.showChat = [];
        },
        // 信息展示区滚动事件
        whereScorll() {
            let box = document.getElementsByClassName('scrollBox')[0];
            this.scrollGap =  box.scrollHeight - box.scrollTop - box.offsetHeight ;
            this.$emit('aditScrollGap', this.scrollGap);

            if (this.scrollGap <= 50) {
                this.$emit('aditMsgNum', 0);
            }
        },
        getShowChat() {
            let that = this;
            clearInterval(this.chatTimer);
            this.chatTimer = setInterval(() => {
                let old = JSON.parse(JSON.stringify(that.showChat)), 
                    chat = JSON.parse(JSON.stringify(that.chat)), 
                    index = 0, 
                    lastOld = old.length > 0 ? old[old.length - 1] : {},
                    needChat = [];
                
                    if (lastOld.msgId) {
                        let chatIndex = chat.findIndex(item => {
                            return item.msgId == lastOld.msgId
                        });

                        if (chatIndex != -1) {
                            index = chatIndex;
                        }
                    }

                    if (this.chat.length > 1) {
                        needChat = chat.splice(index, 2);
                    } else {
                        needChat = chat;
                    }

                    old = [...old, ...needChat];

                    old = old.filter((obj, index, self) => {
                        return index === self.findIndex((o) => (
                            o.msgId === obj.msgId
                        ));
                    });

                    // if (old.length > 100) {
                    //     old = old.splice(old.length-100);
                    // }

                    that.showChat = JSON.parse(JSON.stringify(old));
            }, 1500);
        },
        // 发送成功添加
        addMsg(msg) {
            let member = JSON.parse(JSON.stringify(this.member)),
            obj = {
                badgeList: member.badgeList,
                badgeShowList: member.badgeShowList,
                chatHide: member.chatHide,
                liveId: this.liveInfo.liveId,
                msg: msg,
                msgId: new Date().getTime(),
                nickname: member.nickname,
                sendRoomId: this.liveInfo.liveId,
                uid: member.uid,
                userLevel: member.userLevel,
                protocol: 9
            }

            this.$store.dispatch('addChat', obj);
            this.showChat.push(obj);

            let box = document.getElementsByClassName('scrollBox')[0];
            this.scrollGap =  box.scrollHeight - box.scrollTop - box.offsetHeight;
            this.$emit('aditScrollGap', this.scrollGap);

            if (this.scrollGap <= 50) {
                this.$emit('aditMsgNum', 0);
                this.msgTimer = setTimeout(() => {
                    let box = document.getElementsByClassName('scrollBox')[0];
                    box.scrollTop = box.scrollHeight;

                    if (this.$route.path != '/liveRoom') {
                        clearTimeout(this.msgTimer);
                    }
                }, 100);
                
            } else {
                this.$emit('aditMsgNum', 1);
            }
        },
        // 消息盒子回到底部
        goBottom() {
            let box = document.getElementsByClassName('scrollBox')[0];
            box.scrollTop = box.scrollHeight;
        },
        // 是否是贵族
        isNoble(item) {
            let flag = false;
            if (item.badgeList && item.badgeList.length > 0) {
                item.badgeList.forEach(item => {
                    if ([6,7,8,9,10].includes(item)) {
                        flag = true;
                    }
                })
            }
            return flag;
        },
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
        // 是否是贵族
        getHonorableShow(arr){
            let flag = false;
            arr.forEach(item => {
                if (item > 5) {
                    flag = true;
                }
            })
            return flag;
        },

        // 前往用户卡片
        goUserCard(item, key) {
            if (key && item[key]) return;
            let obj = JSON.parse(JSON.stringify(item));
            if (!obj.uid) {
                obj.uid = obj.anchorId;
            }
            this.$store.commit("setState", { betPramas: obj });
            this.$emit('openPopup', 'showUserCard');
        },
    },
    // if (this.chatTimer) clearTimeout(this.chatTimer);
}
</script>

<style lang="scss" scoped>
.scrollBox {
    max-height: 3.56rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 5.2rem;
    margin-left: 0.24rem;
    position: absolute;
    bottom: 1rem;
    left: 0;
    z-index: 6;

    .scrollItem {
        width: 100%;
        padding: 0.1rem 0.24rem;
        border-radius: 0.1rem;
        margin-bottom: 0.1rem;
        text-align: left;
        line-height: 0.35rem;

        &.user {
            background-color: rgba(0, 0, 0, 0.36);
        }

        &.leave {
            background-color: rgba(0, 0, 0, 0.36);
        }

        &.noble {
            background: -webkit-linear-gradient(left,#a629ff, #ff8cff);
            background: linear-gradient(90deg,#a629ff, #ff8cff);
        }

        .greenBlue {
            color: #8cd5ff;
            margin: 0 0.1rem;
        }

        .systemBtn {
            background: -webkit-linear-gradient(left,#4ea2db, #30EAFF);
            background: linear-gradient(90deg,#4ea2db, #30EAFF);
            color: #fff;
            padding: 0.02rem 0.1rem;
            border-radius: 0.2rem;
            font-size: 0.2rem;
            margin-right: 0.05rem;
        }

        .lottery {
            background: -webkit-linear-gradient(left,#ffb2cd, #ff54a8);
            background: linear-gradient(90deg,#ffb2cd, #ff54a8);
            color: #fff;
            padding: 0.02rem 0.1rem;
            border-radius: 0.2rem;
            font-size: 0.2rem;
            margin-right: 0.05rem;
        }

        .betBtn {
            background: -webkit-linear-gradient(left, #ff54a8,#ffb2cd);
            background: linear-gradient(90deg, #ff54a8,#ffb2cd);
            color: #fff;
            padding: 0.02rem 0.1rem;
            border-radius: 0.2rem;
            font-size: 0.2rem;
            margin-right: 0.05rem;
        }

        .yellow {
            color: #eedd35;
            margin: 0 0.1rem;
        }

        .nobleIcon {
            width: 0.35rem;
            height: 0.35rem;
            margin-left: 0.1rem;
        }

        .nobleLevel {
            position: relative;
            height: 0.35rem;
            width: 0.7rem;
            display: inline-block;
            vertical-align: top;
            margin-right: 0.05rem;

            img {
                width: 0.7rem;
                height: 0.35rem;
            }

            span {
                position: absolute;
                right: 0.06rem;
                color: #fff;
                line-height: 0.35rem;
                font-size: 0.2rem;

                &.small {
                    right: 0.12rem;
                }
            }
        }

        .userIcon {
            vertical-align: middle;
            display: inline-block;

            img {
                height: 0.35rem;
                vertical-align: middle;
            }
        }

        .gamePng {
            height: 0.24rem;
            margin-left: 0.1rem;
        }
    }
    }
</style>