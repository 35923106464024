<template>
  <div class="maintain">
    <div class="main_customerService">
      <img class="headIcon" src="../assets/img/maintain/icon.png" />
      <div class="head">
        {{ mainObj.title }}
      </div>
      <div class="content">
        {{ mainObj.content_en }}
      </div>
    </div>

    <div class="online_service_box">
      <div class="online_service flexCenterBL"  @click="goBack">
        <div>
          <img src="../assets/img/maintain/customerService.png" />
        </div>
        <div class="font flexCenter">
          CSKH tiếng việt
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maintainData from "@/assets/maintain";
export default {
  data() {
    return {
      mainObj: {}
    }
  },
  created() {
    this.mainObj = maintainData.mainObj;
  },
  methods: {
    // 返回
    goBack() {
      this.$router.push('/customerService');
    },
  }
}
</script>

<style lang="scss" scoped>
.maintain {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  background: #F9F9F9;
  background-image: url("../assets/img/maintain/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 0.24rem;
  padding-top: 3rem;

  .head {
    font-size: 0.32rem;
    text-align: center;
    height: 0.88rem;
    line-height: 0.88rem;
    background-color: transparent;
    position: relative;
    margin-bottom: 0.2rem;
    color: #243f78;
  }

  .main_customerService {
    position: relative;
    background-color: rgba($color: #fff, $alpha: 0.5);
    box-sizing: border-box;
    padding: 0.68rem 0.48rem 0.48rem 0.48rem;
    box-shadow: 0 0.08rem 0.4rem #e9eefb;
    border-radius: 0.48rem;
    margin: 0 0.28rem;

    .content {
      font-size: 0.28rem;
      text-align: left;
      color: #616a7d;
      word-wrap: break-word;
      word-break: normal;
    }

    .headIcon {
      position: absolute;
      top: -0.8rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 1.72rem;
    }
  }

  .online_service_box {
    padding: 0 0.28rem;

    .online_service {
      margin-top: 0.24rem;
      height: 1.36rem;
      padding-left: 0.26rem;
      color: #616a7d;
      font-size: 0.32rem;

      img {
        width: 0.64rem;
        margin-right: 0.16rem;
      }
    }
  }
}
</style>