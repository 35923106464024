<template>
    <div class="next exchange">
        <div class="title">
            <span> {{$t('my.invite.exchangeRecord')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>

            <div class="searchItem">
              <div class="searchItem_item">
                <span v-for="(item, index) in dayList" :key="`${index}--dayList`" :class="searchDayActive==index ? 'searchItem_item_active' : ''" @click="changeStatus(index)">
                  {{ item }}
                </span>
              </div>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableHead flexBetween">
                <div>{{$t('my.finance.currency')}}</div>
                <div>{{$t('my.invite.time')}}</div>
                <div>{{ $t('my.finance.status') }}</div>
            </div>
            <div v-for="(item, index) in tableList" :key="`${index}--tableList`" class="tableItem flexBetween">
                <div>{{item.money}}</div>
                <div>{{item.time}}</div>
                <div class="sucess">{{item.status}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dayList: [this.$t('my.invite.status.all'), this.$t('my.invite.status.success'), this.$t('my.invite.status.underReview'), this.$t('my.invite.status.turnDown')],
            searchDayActive: 0,
            tableList:[]
        }
    },
    methods: {
        gaBack() {
            this.$router.push("/mine/invite");
        },
        changeStatus(index) {
            this.searchDayActive = index;
        }
    }
}
</script>

<style lang="scss" scoped>
.exchange {
    padding-top: 2.5rem;

    .title {
        height: auto;
    }
    .searchItem {
       border-top: 1px solid #F9F9F9;
      .searchItem_item {
        padding: 0.2rem 0.08rem 0.2rem 0.28rem;

        span {
          display: inline-block;
          height: 0.64rem;
          width: 1.58rem;
          line-height: .64rem;
          border-radius: 0.16rem;
          text-align: center;
          margin-right: 0.2rem;
          color: #a5a9b3;
          font-size: .28rem;
          box-sizing: border-box;
          -webkit-flex-grow: 0;
          flex-grow: 0;
          -webkit-flex-shrink: 0;
          flex-shrink: 0;
          -webkit-flex-basis: calc(25% - 0.2rem);
          flex-basis: calc(25% - 0.2rem);
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform-origin: left top;
            transform-origin: left top;
            border: 1px solid #cbced8;
            border-radius: 0.32rem;
            pointer-events: none;
            width: 200%;
            height: 200%;
            -webkit-transform: scale(.5);
            transform: scale(.5);
          }

          &.searchItem_item_active {
            background: -webkit-linear-gradient(left,#62b3ff,#3d72fa);
            background: linear-gradient(90deg,#62b3ff,#3d72fa);
            color: #fff;
            border: none;
            background: linear-gradient(90deg,#62b3ff 0,#3d72fa);

            &::after {
                border: 1px solid #fff;
            }
          }
        }
      }
    }

    .exchangeData {
        .tableHead {
           background-color: #F1F1F1;
           padding: 0.2rem 0.28rem;
        }

        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0.31rem 0.28rem;

            .sucess {
                color: #00B887;
            }

            .inaudit {
                color: #FF8900;
            }
        }
    }
}
</style>