<template>
  <div class="next">
    <div class="title">
        <span>{{ $t("my.aboutTilte") }}</span>
        <div class="fixed" @click="goBack">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
    </div>

    <div class="content">
        <div>
            <div class="bold">{{ $t("about.tips") }}</div>
            <div v-html="cont"></div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title1") }}</div>
            <div>{{ $t("about.cont1") }}</div>
            <div>{{ $t("about.cont2") }}</div>
            <div>{{ $t("about.cont3") }}</div>
            <div>{{ $t("about.cont4") }}</div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title2") }}</div>
            <div>{{ $t("about.cont21") }}</div>
            <div>{{ $t("about.cont22") }}</div>
            <div>{{ $t("about.cont23") }}</div>
            <div>{{ $t("about.cont22") }}</div>
            <div>{{ $t("about.cont24") }}</div>
            <div>{{ $t("about.cont25") }}</div>
            <div>{{ $t("about.cont26") }}</div>
            <div>{{ $t("about.cont27") }}</div>
            <div>{{ $t("about.cont28") }}</div>
            <div>{{ $t("about.cont29") }}</div>
            <div>{{ $t("about.cont220") }}</div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title3") }}</div>
            <div>{{ $t("about.cont31") }}</div>
            <div>{{ $t("about.cont32") }}</div>
            <div>{{ $t("about.cont33") }}</div>
            <div>{{ $t("about.cont34") }}</div>
            <div>{{ $t("about.cont35") }}</div>
            <div>{{ $t("about.cont36") }}</div>
            <div>{{ $t("about.cont37") }}</div>
            <div>{{ $t("about.cont38") }}</div>
            <div>{{ $t("about.cont39") }}</div>
            <div>{{ $t("about.cont320") }}</div>
            <div>{{ $t("about.cont321") }}</div>
            <div>{{ $t("about.cont322") }}</div>
            <div>{{ $t("about.cont323") }}</div>
            <div>{{ $t("about.cont324") }}</div>
            <div>{{ $t("about.cont325") }}</div>
            <div>{{ $t("about.cont326") }}</div>
            <div>{{ $t("about.cont327") }}</div>
            <div>{{ $t("about.cont328") }}</div>
            <div>{{ $t("about.cont329") }}</div>
            <div>{{ $t("about.cont330") }}</div>
            <div>{{ $t("about.cont331") }}</div>
            <div>{{ $t("about.cont332") }}</div>
            <div>{{ $t("about.cont333") }}</div>
            <div>{{ $t("about.cont334") }}</div>
            <div>{{ $t("about.cont335") }}</div>
            <div>{{ $t("about.cont336") }}</div>
            <div>{{ $t("about.cont337") }}</div>
            <div>{{ $t("about.cont338") }}</div>
            <div>{{ $t("about.cont339") }}</div>
            <div>{{ $t("about.cont340") }}</div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title4") }}</div>
            <div>{{ $t("about.cont41") }}</div>
            <div>{{ $t("about.cont42") }}</div>
            <div>{{ $t("about.cont43") }}</div>
            <div>{{ $t("about.cont44") }}</div>
            <div>{{ $t("about.cont45") }}</div>
            <div>{{ $t("about.cont46") }}</div>
            <div>{{ $t("about.cont47") }}</div>
            <div>{{ $t("about.cont48") }}</div>
            <div>{{ $t("about.cont49") }}</div>
            <div>{{ $t("about.cont420") }}</div>
            <div>{{ $t("about.cont421") }}</div>
            <div>{{ $t("about.cont422") }}</div>
            <div>{{ $t("about.cont423") }}</div>
            <div>{{ $t("about.cont424") }}</div>
            <div>{{ $t("about.cont425") }}</div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title5") }}</div>
            <div>{{ $t("about.cont51") }}</div>
            <div>{{ $t("about.cont52") }}</div>
            <div>{{ $t("about.cont53") }}</div>
            <div>{{ $t("about.cont54") }}</div>
            <div>{{ $t("about.cont55") }}</div>
            <div>{{ $t("about.cont56") }}</div>
            <div>{{ $t("about.cont57") }}</div>
            <div>{{ $t("about.cont58") }}</div>
            <div>{{ $t("about.cont59") }}</div>
            <div>{{ $t("about.cont520") }}</div>
            <div>{{ $t("about.cont521") }}</div>
            <div>{{ $t("about.cont522") }}</div>
            <div>{{ $t("about.cont523") }}</div>
            <div>{{ $t("about.cont524") }}</div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title6") }}</div>
            <div>{{ $t("about.cont61") }}</div>
        </div>

        <div>
            <div class="bold">{{ $t("about.title7") }}</div>
            <div>{{ $t("about.cont71") }}</div>
            <div>{{ $t("about.cont72") }}</div>
            <div>{{ $t("about.cont73") }}</div>
            <div>{{ $t("about.cont74") }}</div>
            <div>{{ $t("about.cont75") }}</div>
            <div>{{ $t("about.cont76") }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            cont: this.$t("about.cont"),
            fromPath: ""
        }
    },
    beforeRouteEnter(to, from, next) {
      next((e) => {
        e.fromPath = from.path;
      });
    },
    methods: {
        // goOtherPage('/mine') path
        goBack() {
            if (this.fromPath == '/mine/set') {
                this.$router.push('/mine/set');
            } else {
                this.$router.push('/mine');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.next {
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 0.2rem;
    .content {
        padding: 0 0.28rem;
        text-align: left;
        font-size: 0.27rem;
        line-height: 0.5rem;

        &>div {
            margin-top: 0.2rem;
            
        }

        .bold {
            font-weight: bold;
        }
    }
}
</style>