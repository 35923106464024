<template>
    <div class="myMall next">
        <div class="title">
            <span> {{ $t('my.listView.myProps') }} </span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <ul v-if="list.length>0" class="listView">
            <li v-for="(item, index) in list" :key="`${index}--list-myMall`" @click="itemClick(item)" v-show="!item.flag">
                <div class="left">
                    <img @click.stop="initMachineSVGA(item)" v-real-img="item.cover" />
                    <img @click.stop="initMachineSVGA(item)" class="playIcon" src="../../assets/img/my/play.png" />
                    <div class="mallInfo">
                        <p>{{ item.gname }}</p>
                        <p>{{ $t('my.mall.end') }}: {{ getTime(item.endTime) }}</p>
                    </div>
                </div>
                <div class="right">
                    <van-icon v-show="item.gid == showGid" name="checked" />
                    <span v-show="item.gid != showGid"></span>
                </div>
            </li>
        </ul>

        <div v-else class="noData">
          <img src="../../assets/img/record/noData.png"/>
          <p>{{$t('common.noData')}}</p>
        </div>

        <div v-show="showAnimation" id="demoCanvas">
            <van-icon @click="stopAnimation" name="close" />
        </div>
    </div>
</template>
  
<script>
import { propCar, propSetShowCar } from "@/api/index";
import { configProp } from "@/api/config";
import SVGA from "svgaplayerweb";
import moment from 'moment';
import utils from "@/utils/index";
import md5 from 'js-md5';
export default {
    data() {
        return {
            languageActive: 0,
            currencyArr: [], //货币列表
            currencyActive: 0,
            list: [], //购买
            showBuyInfo: false,
            activeObj: {},
            buyInfo: [7, 30, 90, 180],
            activeIndex: 0,
            player: null,
            showAnimation: false,
            allList: [], //所有配置的座驾
            showGid: -1
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    created() {
        this.configProp();
    },
    methods: {
        getTime(time) {
            if (time) {
                return moment(time).format('HH:mm DD-MM-YYYY');
            } 
            return "--";
        },
        initMachineSVGA(item){
            let that = this;
            this.showAnimation = true;
            this.player = new SVGA.Player('#demoCanvas');
            this.parser = new SVGA.Parser('#demoCanvas');
            this.player.loops ++;
            // 必须是服务器地址或者是线上的地址本地是不可以的 会报错
            this.parser.load(`${item.resourceUrl}`, (videoItem) => {
                that.player.setVideoItem(videoItem);
                that.player.startAnimation();
                that.player.onFinished(function(){
                    that.showAnimation = false;
                });
            }, err => {
                console.log(err);
                this.showAnimation = false;
            })
        },
        stopAnimation(){
            this.player.stopAnimation();
            this.showAnimation = false;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 单项点击 逻辑待添加
        itemClick(item) {
            this.activeObj = JSON.parse(JSON.stringify(item));
            let that = this;
            this.$dialog.confirm({
                message: `${this.$t('my.mall.changeActiveMallTips')} ${item.gname}`,
                confirmButtonColor: "#eb457e",
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                overlay: true
            })
                .then(() => {
                    that.propSetShowCar();
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 座驾列表
        configProp() {
            configProp(this.member.uid).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let arr = res.data.filter((item) => {
                        return item.type == 1 && item.isShow
                    });

                    this.allList = JSON.parse(JSON.stringify(arr)); 
                    this.propCar();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 用户购买的
        propCar() {
            let uid = localStorage.getItem('uid') || utils.guid(),
            params = {
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid(),
                uid:  this.member ? this.member.uid : '',
                page: 0,
                language: 'YN'
            }
            propCar(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data && res.data.carList && res.data.carList.length > 0) {
                        let arr = []
                        res.data.carList.forEach(item => {
                            this.allList.forEach(xitem => {
                                if (xitem.gid === item.gid) {
                                    arr.push({ ...item, ...xitem }); 
                                }
                            })
                        });
                        this.showGid = res.data.showGid;
                        this.list = JSON.parse(JSON.stringify(arr)); 
                    } else {
                        this.list = [];
                        this.showGid = -1;
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.list = [];
                    this.showGid = -1;
                }
            }).catch(err => {
                console.log(err);
                this.list = [];
                this.showGid = -1;
            });
        },
        // 设置直播间展示座驾
        propSetShowCar() {
            let params = {
                uid: this.member.uid,
                gid: this.activeObj.gid
            }
            propSetShowCar(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('my.mall.changeSuccess'));
                    this.propCar();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>
  
<style lang="scss" scoped>
.myMall {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    background-color: #f9f9f9;

    .listView {
        li {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding-right: 0.28rem;
            background-color: #fff;
            height: 1.48rem;
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0.28rem;
                bottom: 0;
                border-bottom: 1px solid #eee;
                -webkit-transform: scaleY(.5);
                transform: scaleY(.5);
            }

            &:last-child::after {
                border-bottom: 1px solid #fff;
            }

            .left {
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                margin-left: 0.28rem;
                position: relative;

                .playIcon {
                    width: 0.48rem;
                    height: 0.48rem;
                    position: absolute;
                    left: 0.4rem;
                }

                img {
                    width: 1.28rem;
                    height: 1.28rem;
                }

                span {
                    font-size: .28rem;
                    color: #333;
                    margin-left: 0.2rem;
                }

                div {
                    margin-left: 0.2rem;
                    text-align: left;

                    p:nth-child(1) {
                        color: #ff54a8;
                        font-size: 0.3rem;
                        font-weight: 500;
                    }

                    p:nth-child(2) {
                        color: #969696;
                        font-size: 0.24rem;
                        margin-top: 0.3rem;
                    }
                }
            }

            .right {
                color: #f86fab;
                font-size: 0.4rem;
                text-align: right;

                span {
                    display: inline-block;
                    width: 0.4rem;
                    height: 0.4rem;
                    background-color: #e5e5e5;
                    border-radius: 50%;
                }
            }
        }
    }

    .currencyLanguage {
        background-color: #fff;

        .cardsTab {
            padding: 0.28rem;
            background: #fff;

            ul {
                -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
                background: #EBEFF1;
                border-radius: 0.16rem;
                padding: 0.02rem;

                li {
                    flex: 1 1;
                    text-align: center;
                    line-height: 0.58rem;
                    height: 0.58rem;
                    color: #bbb;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.27rem;
                    margin: 0.02rem;

                    &.active {
                        background: #fff;
                        border-radius: 0.16rem;
                        color: #333333;
                    }
                }
            }
        }

        .bankListCont {
            margin-bottom: 0.3rem;

            .bankListCont_item {
                padding: 0.35rem 0;
                margin: 0 0.28rem;
                border-bottom: 1px solid #EBEFF1;

                .bankListCont_item_left {
                    text-align: left;
                    color: #333;
                    font-size: 0.28rem;
                }

                img {
                    width: 0.4rem;
                }
            }
        }
    }

    #demoCanvas {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 10;

        i {
            position: absolute;
            top: 0.88rem;
            right: 0.28rem;
            font-size: 0.5rem;
            color: #fff;
        }
    }
}
</style>