import request from "../utils/requestUpload";
export const uploadAvatar = (params = {}) => {
    return request.post(`/center-client/center/upload/userIcon`, params);
};

// 图片上传 /file/uoloadImg  @"/file-client/file/uploadImg"   3个参数type,file必填,oldFilePath是要删除的旧文件，type类型根据业务传
export const uoloadImg = (params = {}) => {
    return request.post(`/file-client/file/uploadImg`, params);
};

export default {
    uploadAvatar,
    uoloadImg
}