import { mapState } from 'vuex';
import { getLotteryPower } from "@/api/index";
const lotteryData = {
    data() {
        return {
            allLotteryPower: [], //所有赔率数据
            showGameList: false, //游戏列表是否展示
            showRecordLottery: false, //开奖记录弹框是否展示
            showLotteryBet: false, //开奖结果/历史投注记录弹框是否显示
            showLotteryResult: false, //每一种彩种最后一期的开奖结果
            showLotteryRecord: false, //投注记录弹框
            showLotteryBetDetail: false, //投注详情弹框
            showSingleLottery: false, //彩票弹框(可下注)
            showLotteryBetSure: false, //彩票投注确认弹框是否显示
            lotteryActvieBet: [], //目前选中准备下注的彩票详情

            countdownTimer: null, //倒计时时间容器
            second: 0, // 倒计时时间

            showfirstLottery: false, //第一条开奖飘屏是否展示
            firstLotteryTimer: null, // 第一条开奖飘屏时间容器 
            showsecondLottery: false, //第二条开奖飘屏是否展示
            secondLotteryTimer: null, // 第二条开奖飘屏时间容器

            winLotteryTimer: null, //中奖飘屏时间容器
            showwinLottery: false, // 中间飘屏是否展示
            winLottery: {}, // 中奖飘屏内容

            winLuxuryGiftTimer: null, //中奖飘屏时间容器
            showLuxuryGift: false, // 中奖飘屏是否展示
            Luxury: {}, // 中奖飘屏内容

            gameData: [],
            supportImg: ['yuxx', 'jsks', 'sd'],
        }
    },
    computed: {
        ...mapState([
            'member', 
            'liveInfo', 
            'countTime', 
            'lotteryRouter',
            'firstLottery', 
            'secondLottery', 
            'winLotteryScreen', 
            'baseInfo', 
            'betPramas',
            'weightList',
            'multipleArr',
            'suBetPramas',
        ]),
        leftMenu() {
            return this.$store.state.tmModule.leftMenu;
        },
        firstGame() {
            let obj = {};
            if (this.anchorDetail.liveStartLottery && this.anchorDetail.liveStartLottery.length > 0) {
                if (this.lotteryRouter.indexOf(this.anchorDetail.liveStartLottery[0].cpName) != -1) {
                    obj = JSON.parse(JSON.stringify(this.anchorDetail.liveStartLottery[0]));
                    obj.name = obj.cpName;
                    obj.chinese = obj.lotteryName;
                }
            }
            return obj;
        },
        secondGame() {
            let obj = {};
            if (this.anchorDetail.liveStartLottery && this.anchorDetail.liveStartLottery.length > 1) {
                if (this.lotteryRouter.indexOf(this.anchorDetail.liveStartLottery[1].cpName) != -1) {
                    obj = JSON.parse(JSON.stringify(this.anchorDetail.liveStartLottery[1]));
                    obj.name = obj.cpName;
                    obj.chinese = obj.lotteryName;
                }
            }

            return obj;
        },
        sdMiddle() {
            let arr = JSON.parse(JSON.stringify(this.allLotteryPower)),
            detail = arr.filter(item => {
                return item.lottery.toLowerCase() == 'sd'
            }),
            last = [];
            if (detail.length > 0) {
                last = detail.filter(item => {
                    return isNaN(item.methodShow[0])
                });
            }
            return last;
        }
    },
    watch: {
        countTime(val) {
            if (val > 0) {
                this.goDown(val-1);
            }
        },
        firstLottery(val) {
            if (val && val.expect) {
                clearInterval(this.firstLotteryTimer);
                this.showfirstLottery = true;
                let that = this;
                this.firstLotteryTimer = setInterval(() => {
                    that.showfirstLottery = false;
                    that.$store.commit("setState", { firstLottery: {} });
                    clearInterval(that.firstLotteryTimer);
                }, 3000)
            }
        },
        secondLottery(val) {
            if (val && val.expect) {
                clearInterval(this.secondLotteryTimer);
                this.showsecondLottery = true;
                let that = this;
                this.secondLotteryTimer = setInterval(() => {
                    that.showsecondLottery = false;
                    that.$store.commit("setState", { secondLottery: {} });
                    clearInterval(that.secondLotteryTimer);
                }, 3000)
            }
        },
        winLotteryScreen(val) {
            this.openWinLottery(val);
        },
    },
    created() {
        // 目前都是 一分彩种 暂时写死
        if (this.liveInfo && this.liveInfo.liveStartLottery && this.liveInfo.liveStartLottery.length > 0) {
            this.$store.dispatch("getissue", this.liveInfo.liveStartLottery[0].cpName);
        }

        this.getLotteryPower();
        this.goDown(this.countTime - 1);
    },
    methods: {
        // 点击
        lotteryItem(item) {
            let index = this.lotteryActvieBet.findIndex(xitem => {
                return xitem.playName == item.playName;
            });
            if (index != -1) {
                this.lotteryActvieBet.splice(index, 1);
            } else {
                this.lotteryActvieBet.push(item);
            }
        },
        spliceLotteryItem(index) {
            this.lotteryActvieBet.splice(index, 1);
        },
        initLotteryBet() {
            this.lotteryActvieBet = [];
        },
        // 倒计时
        goDown(time) {
            clearInterval(this.countdownTimer);
            this.second = time;
            this.$store.commit("setState", { countTime: 0 });
            this.countdownTimer = setInterval(() => {
              if (time > 0) {
                time --;
              } else {
                clearInterval(this.countdownTimer);
              }
              this.second = time;
            }, 1000);
        },
        // 开启中大奖动画
        openWinLottery(arr) {
            if (arr.length > 0 && !this.showwinLottery) {
                this.showwinLottery = true;
                clearInterval(this.winLotteryTimer);
                let that = this;
                this.winLottery = JSON.parse(JSON.stringify(arr[0]));
                this.winLotteryTimer = setInterval(() => {
                    that.showwinLottery = false;
                    clearInterval(that.winLotteryTimer);
                    let winLotteryScreen = JSON.parse(JSON.stringify(arr));
                    winLotteryScreen.splice(0, 1);
                    that.$store.commit("setState", { winLotteryScreen });
                }, 12000)
            }
        },
        // 中奖飘屏关闭
        closeLotteryScreen(val) {
            this[`show${val}Lottery`] = false;
            clearInterval(this[`${val}LotteryTimer`]);
        },
        // 打开单个彩票
        openSingleLottery() {
            if (this.allLotteryPower.length > 0) {
                this.getGameData();
            } else {
              this.getLotteryPower('showSingleLottery');
            }
        },
        // 点击icon 前往单个彩票投注
        goLottery(item) {
            let obj = JSON.parse(JSON.stringify(item));
            if (obj.lorretyIcon) obj.icon = obj.lorretyIcon;
            this.$store.commit("setState", { betPramas: obj });
            this.openSingleLottery();
        },
        // 打开 单个游戏的开奖记录 
        gosingleLotteryResult(item) {
            let obj = {
                lotteryName: item.name,
                nickName: item.chinese
            }
            this.$store.commit("setState", { suBetPramas: obj });
            this.showRecordLottery = true;
        },
        // 获取赔率
        getLotteryPower(val) {
            let params = {
                name: "",
                issue: "202208241070"
            }
            getLotteryPower(params).then(res => {
                if (res.code == 0) {
                    this.allLotteryPower = res.data;
                    if (val) this.getGameData();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 跟投逻辑
        followUp(item, com) {
            let obj = JSON.parse(JSON.stringify(item));
            obj.name = obj.code;
            obj.chinese = item.name;
            this.$store.commit("setState", { betPramas: obj });
            this.getGameData('false');

            let arr = [];
            if (obj.code == 'sd') {
                let arrData = [...this.sdMiddle, ...this.gameData];
                arrData.forEach(xitem => {
                    let index = obj.payList.findIndex(sitem => {
                        return sitem.type == xitem.type;
                    });
    
                    if (index != -1) {
                        let itemObj = JSON.parse(JSON.stringify(xitem));
                        itemObj.money = obj.payList[index].money;
                        arr.push(itemObj)
                    }
                });
            } else {
                this.gameData.forEach(gItem => {
                    gItem.arr.forEach(gNextItem => {
                        let index = obj.payList.findIndex(sitem => {
                            return sitem.type == gNextItem.type && sitem.num == gNextItem.method;
                        });

                        if (index != -1) {
                            let itemObj = JSON.parse(JSON.stringify(gNextItem));
                            itemObj.money = obj.payList[index].money;
                            arr.push(itemObj)
                        }
                    })
                });
            }

            if (arr.length > 0) {
                let numIndex = this.weightList.findIndex(numItem => {
                    return numItem == obj.payList[0].money;
                }),
                multipleIndex = this.multipleArr.findIndex(multipleItem => {
                    return multipleItem == obj.times
                });

                if (numIndex ==  -1) {
                    // , customizeValueConfirm: obj.payList[0].money
                    this.$store.commit("setState", { weightListConfirm: this.weightList.length -1 });
                } else {
                    this.$store.commit("setState", { weightListConfirm: numIndex });
                }

                if (multipleIndex != -1) {
                    this.$store.commit("setState", { multiple: obj.times });
                }
                
                this.openLotteryBetSure(arr, com);
            }
        },
        // 打开彩票投注确认弹框
        openLotteryBetSure(activeBet, com) {
            if (com) {
                this.$emit('openFollowConfirm', activeBet)
            } else {
                this.lotteryActvieBet = JSON.parse(JSON.stringify(activeBet));
                this.showLotteryBetSure = true;
            }
            
        },
        // 赔率分配
        getGameData(key) {
            this.gameData = [];
            let bar = [];
            let detail = this.allLotteryPower.filter(item => {
                return item.lottery.toUpperCase() == this.betPramas.name.toUpperCase()
            });

            detail.forEach(item => {
                bar.push(item);
            });
            var obj = {};
            bar = bar.reduce(function (item, next) {
            obj[next.lotteryType] ? '' : obj[next.lotteryType] = true && item.push(next);
                return item;
            }, []);
            this.gameData = bar;
            if (this.gameData.length > 0) {
                if (this.gameData[0].lottery != 'sd') {
                    this.gameData.forEach(item => {
                        item.arr = this.getDetail(item.lotteryType);
                    });
                } else {
                    this.gameData = this.getSdDetail();
                } 
            }
            if (!key) this.lotteryActvieBet = [], this.showSingleLottery = true; 
        },
        // 获取赔率内容详情
        getDetail(val) {
            let arr = JSON.parse(JSON.stringify(this.allLotteryPower)),
            detail = arr.filter(item => {
                return item.lotteryType == val && item.lottery.toLowerCase() == this.betPramas.name.toLowerCase()
            }),
            index = this.supportImg.findIndex(item => {
                return item == this.betPramas.name;
            });

            if (detail.length > 0) {
                detail.forEach(item => {
                    item.methodArr = item.methodShow.split(',');
                    let classArr = '';
                    if (detail.length == 3) {
                        classArr = 'thirdItem oneLines';
                    }
                    if (detail.length == 4) {
                        classArr = 'fourthItem oneLines';
                    }
                    if (detail.length == 6) {
                        classArr = 'thirdItem twoLines'
                    }
                    if (detail.length == 10) {
                        classArr = 'fifthItem twoLines'
                    }
                    if (detail.length > 10) {
                        classArr = 'fifthItem threeLines'
                    }
                    item.className = classArr;
                    if (!isNaN(item.methodArr[0]) && index != -1) {
                        item.methodArr.forEach((xitem, xindex) => {
                            item[`icon${xindex}`] = require(`../../../assets/img/lottery/${this.betPramas.name}/${xitem}.png`);
                        });
                    }
                });
            }

            return detail;
        },
        getSdDetail() {
            let arr = JSON.parse(JSON.stringify(this.allLotteryPower)),
            detail = arr.filter(item => {
                return item.lottery.toLowerCase() == this.betPramas.name.toLowerCase()
            }),
            last = [];

            if (detail.length > 0) {
                detail.forEach(item => {
                    item.methodArr = item.methodShow.split(',');
                    if (item.methodArr.length == 8) {
                        item.methodArr = item.methodShow.split(',').splice(0, 4);
                        item.methodArr1 = item.methodShow.split(',').splice(4);
                    }
                });

                last = detail.filter(item => {
                    return !isNaN(item.methodShow[0])
                });
            }
            return last;
        },
    },
    beforeRouteLeave(to,from) {
        if (from.path == '/liveRoom') {
            clearInterval(this.countdownTimer);
            clearInterval(this.firstLotteryTimer);
            clearInterval(this.secondLotteryTimer)
            clearInterval(this.winLotteryTimer);
        }
    }
}
export default lotteryData;