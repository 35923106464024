<template>
   <div class="gameInfo">
        <div class="flexBetween gameInfoTop">
            <div>
                {{ lottery.nickName }} 
                <span>-</span>
                <span>{{ lottery.expect }}</span>
            </div>
            <div @click.stop="closeLotteryScreen('second')" class="colseIcon"><van-icon size="0.24rem" name="cross" /></div>
        </div>

        <div v-if="lottery.name == 'sd' || lottery.name == 'yuxx' || lottery.name == 'jsks'">
            <div class="gameInfoBottom flexCenter" v-if="lottery.resultList && lottery.resultList.length > 0">
                <img class="gamesIcon" v-for="(xitem, index) in lottery.resultList" :key="`${index}--lottery.resultList`" v-real-img="require(`../../assets/img/lottery/${lottery.name}/${xitem}.png`)" />
            </div>
        </div>

        <div v-else-if="lottery.name == 'pk10' || lottery.name == 'txssc'">
            <div class="gameInfoBottom flexCenter" v-if="lottery.resultList && lottery.resultList.length > 0">
                <span class="gameItem" v-for="(xitem, index) in lottery.resultList" :key="`${index}--lottery.resultList`">{{xitem}}</span>
            </div>
        </div>

        <div v-else-if="lottery.name == 'yflhc'">
            <div class="gameInfoBottom flexCenter" v-if="lottery.resultList && lottery.resultList.length > 0">
                <span class="gameItem" v-for="(xitem, xindex) in lottery.resultList" :key="`${xindex}--orderDetail.resultList-yflhc`" v-show="xindex < 6"> {{xitem}}</span>
                <van-icon name="plus" />
                <span class="gameItem" v-show="lottery.resultList[7] == 1" style="background-color:#f90007;"> {{ lottery.resultList[6] }}</span> 
                <span class="gameItem" v-show="lottery.resultList[7] == 2" style="background-color:#04f906;"> {{ lottery.resultList[6] }}</span>
                <span class="gameItem" v-show="lottery.resultList[7] == 3" style="background-color:#2a00f2;"> {{ lottery.resultList[6] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'liveRoomLotteryDraw',
    props: ['lottery'],
    data() {
        return {

        }
    },
    methods: {
        closeLotteryScreen(val) {
            this.$emit('closeLotteryScreen', val);
        }
    }
}
</script>

<style lang="scss">
.gameInfo {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.1rem;
    margin: 0.16rem 0 0.16rem 0.24rem;
    width: 80vw;

    .gameInfoTop {
        padding: 0.082rem 0.2rem;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.53);

        span {
            margin-left: 0.05rem;
        }

        .colseIcon {
            margin-left: 0.4rem;
        }
    }

    .gameInfoBottom {
        height: 0.72rem;
        padding: 0 0.2rem;

        img {
            width: 0.31rem;
            margin-right: 0.15rem;

            &.gamesIcon {
                width: 0.6rem;
                margin-right: 0.2rem;
            }
        }

        .gameItem {
            display: inline-block;
            width: 0.3rem;
            height: 0.35rem;
            background-color: #eb457e;
            line-height: 0.37rem;
            color: #fff;
            margin-right: 0.05rem;
            font-size: 0.24rem;
        }
    }
}
</style>