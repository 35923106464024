<template>
    <div class="next recordTransacitonDetail">
        <div class="title">
            <span> {{ $t('my.finance.orderDetail') }} </span>
            <div class="fixed" @click="goOtherPage('/record/transaciton')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="orderBox center">
            <div>
                <!-- 图片逻辑取值待添加 -->
                <img class="orderTypeIcon" src="../../assets/img/my/next/walletIcon.png" />
            </div>
            <p class="orderNum">{{ numFormat(orderDetail.goldCoin) }}</p>
            <p>{{ $t('record.transactionSuccess') }}</p>
        </div>

        <div class="orderBox">
            <div>
                <span>{{ $t('record.projectName') }}</span> {{ orderDetail.name }}
            </div>
            <div>
                <span>{{ $t('my.finance.transactionType') }}</span> {{ $t('record.transactionSuccess') }}
            </div>
        </div>

        <div class="orderBox">
            <div>
                <span>{{ $t('record.orderNum') }}</span> {{ orderDetail.trn }}

                <img @click="copyClick(orderDetail.trn)" class="copy" src="../../assets/img/copy.png" />
            </div>
            <div>
                <span>{{ $t('my.finance.transactionHour') }}</span> {{ getTime(orderDetail.gmtCreate) }}
            </div>
        </div>
        
        <div @click="goOtherPage('/customerService')" class="orderBox flexBetween">
            <div>{{ $t('record.orderQuestion') }}</div>
            <img class="cusSer" src="../../assets/img/cusSer.png" />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import utils from "@/utils/index";
export default {
    data() {
        return {
            type: "",
            // orderDetail: {}
        }
    },
    computed: {
        orderDetail() {
            return this.$store.state.betDetail;
        }
    },
    created() {
        this.type = this.$route.params.type;
        // if (sessionStorage.getItem('orderDetail')) {
        //     this.orderDetail = JSON.parse(sessionStorage.getItem('orderDetail'));
        // }
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm:ss DD-MM-YYYY');
            }
            return "";
        }
    }
}
</script>
  
<style lang="scss" scoped>
.recordTransacitonDetail {
    font-size: 0.28rem;
    .title_ellipsis {
        color: #eb457e;
    }

    .orderBox {
        background-color: #fff;
        color: #333;
        font-size: 0.27rem;
        padding: 0.2rem;
        width: calc(100% - 0.56rem);
        margin: 0.1rem auto 0.2rem;
        border-radius: 0.1rem;
        text-align: left;
        position: relative;

        &.center {
            text-align: center;
        }

        .orderTypeIcon {
            margin-bottom: 0.2rem;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
        }

        .orderNum {
            margin-bottom: 0.1rem;
            font-size: 0.34rem;
        }

        p:last-child, span {
            color: #969696;
        }

        span {
            display: inline-block;
            margin-bottom: 0.2rem;
            margin-right: 0.2rem;
        }

        .copy {
            position: absolute;
            top: 0.28rem;
            right: 0.2rem;
        }

        .cusSer {
            width: 0.44rem;
            height: 0.44rem;
        }
    }
}
</style>
