<template>
    <div class="next bindPhone">
        <div class="title">
            <span> {{ $t('my.bindPhone') }} </span>
            <div class="fixed" @click="goback">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>
        <div class="userInfo_next_cont userInfo_next_cont_phone">
            <div class="itemTitle">{{ $t('my.proxy.phoneNumber') }}</div>

            <div class="flexCenter">
                <van-field autocomplete="off" class="am-list-item borderBottom" v-model.trim="number" clearable
                    @keyup="number = number.replace(/[^\d]/g, '')" :placeholder="$t('register.entryPhoneTip')" />
            </div>


            <div class="itemTitle">{{ $t('my.proxy.phoneCode') }}</div>
            <van-field @keyup="code = code.replace(/[^\d]/g, '')" v-model.trim="code" :maxlength="4" autocomplete="off"
                :placeholder="$t('register.entryCodeTips')">
                <template #button>
                    <van-button @click="goGetCode" plain color="#eb457e" v-show="isGetCode && number" round size="small"
                        type="primary">
                        {{ $t('common.getCode') }}</van-button>
                    <van-button v-show="!isGetCode || !number" color="#eb457e" plain disabled size="small" round
                        type="primary">{{ getCodeVal }}
                    </van-button>
                </template>
            </van-field>
        </div>

        <div v-show="!canContinue" class="confirmBtn">{{ $t('common.confirm') }}</div>
        <div v-show="canContinue" @click="confirm" class="confirmBtn hasValue">
            {{ $t('common.confirm') }}</div>

        <div style="display: none;" id="captcha"></div>
    </div>
</template>
  
<script>
import md5 from 'js-md5';
import utils from "@/utils/index";
import { sendVcode, modifyInfo, phoneIsRegiste, bindUserMobile } from "@/api/index";
import captcha from '@/pages/mixin/captcha';
import { mapState } from 'vuex';
export default {
    mixins: [captcha],
    data() {
        return {
            number: "",
            code: "",
            showTip: false,
            tips: "",
            isGetCode: true,
            getCodeVal: this.$t('common.getCode'),
            timer: null,
            isNumEntery: true
        }
    },
    computed: {
        ...mapState(['member']),
        canContinue() {
            if (this.number && this.code) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        this.userCaptcha('getCode');
    },
    methods: {
        goback() {
            this.$router.push("/mine/proxy");
        },
        confirm() {
            let params = {
                mobile: this.number,
                vcode: this.code
            }
            bindUserMobile(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('common.submitSuccess'));
                    this.$router.push("/mine/proxy");
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            })
        },
        // 点击获取验证码按钮
        goGetCode() {
            let phonerReg = /^0\d{9}$/;
            if (phonerReg.test(this.number)) {
                this.isRegiste();
            } else {
                this.$toast(this.$t("register.checkPhoneError"));
            }
        },
        // 手机号/ 邮箱 是否注册
        isRegiste() {
            // type 1 注册 2忘记密码
            let uid = localStorage.getItem('uid') || utils.guid(), fun = null;
            let param = {
                mobile: this.number,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid(),
            }

            phoneIsRegiste(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data == 1) {
                        let msg = this.$t('register.hasPhoneRegister');
                        this.$toast(msg);
                    } else {
                        this.confirmGetCode('getCode');
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => { })
        },
        // 修改个人资料
        modifyInfo(params) {
            modifyInfo(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('my.addCard.bindSuccessTips'));
                    this.$store.dispatch('refreshUserInfo');
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log("catch");
            })
        },
        countDown() {
            let time = 179;
            let that = this;
            this.isGetCode = false;
            that.getCodeVal = time + 's';
            this.timer = setInterval(() => {
                if (time > 0) {
                    time--;
                    that.getCodeVal = time + 's';
                } else {
                    clearInterval(that.timer);
                    that.getCodeVal = that.$t('common.getCode');
                    that.isGetCode = true;
                }
            }, 1000);
        },
        // 获取验证码
        getCode() {
            let uid = localStorage.getItem('uid') || utils.guid(),
            params = {
                mobile: this.number,
                type: 2, // 7加入代理
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid()
            }

            sendVcode(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('common.getCodeSuccess'));
                    this.countDown();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
  
<style lang="scss" scoped>
.bindPhone {
    background-color: #f8f8f8;
    padding-top: 0.88rem;

    .userInfo_next_cont {
        background: #fff;
        text-align: left;

        .firstNode {
            width: 0.9rem;
            line-height: 0.5rem;
            display: inline-block;
            border-bottom: 1px solid rgba(238, 238, 238, 0.5);
            padding-bottom: 0.15rem;
        }

        &.userInfo_next_cont_name {
            padding: 0.45rem 0.28rem;
        }

        &.userInfo_next_cont_phone {
            padding: 0.25rem 0.28rem 0.28rem;
            margin-bottom: 0.28rem;
        }

        .itemTitle {
            margin-top: 0.2rem;
            margin-bottom: 0.12rem;
            font-size: .28rem;
            color: #414655;

            &.tips {
                color: #db6372;
                height: 0.35rem;
                margin-bottom: 0.15rem;
            }
        }

        .van-cell {
            padding: 0;
        }
    }

    .confirmBtn {
        margin: 0.15rem auto 0;
    }
}
</style>
<style lang="scss">
.bindPhone .userInfo_next_cont .van-cell {
    &.borderBottom {
        border-bottom: 1px solid #ebeff1;
    }

    .van-field__control {
        font-size: 0.3rem;
        color: #333;

        &:disabled {
            color: #333;
            -webkit-text-fill-color: #333;

            &::-webkit-input-placeholder {
                /*Webkit browsers*/
                color: #C4C4C4;
                -webkit-text-fill-color: #C4C4C4;
                font-size: 0.28rem;
            }

            &:-moz-placeholder {
                /*Mozilla Firefox 4 to 8*/
                color: #C4C4C4;
                -webkit-text-fill-color: #C4C4C4;
                font-size: 0.28rem;
            }

            &::moz-placeholder {
                /*Mozilla Firefox 19+*/
                color: #C4C4C4;
                -webkit-text-fill-color: #C4C4C4;
                font-size: 0.28rem;
            }

            &:-ms-input-placeholder {
                /*Internet Explorer 10+*/
                color: #C4C4C4;
                -webkit-text-fill-color: #C4C4C4;
                font-size: 0.28rem;
            }
        }

        &::-webkit-input-placeholder {
            /*Webkit browsers*/
            color: #C4C4C4;
            font-size: 0.28rem;
        }

        &:-moz-placeholder {
            /*Mozilla Firefox 4 to 8*/
            color: #C4C4C4;
            font-size: 0.28rem;
        }

        &::moz-placeholder {
            /*Mozilla Firefox 19+*/
            color: #C4C4C4;
            font-size: 0.28rem;
        }

        &:-ms-input-placeholder {
            /*Internet Explorer 10+*/
            color: #C4C4C4;
            font-size: 0.28rem;
        }

    }

    &.am-list-item {
        // margin-bottom: 0.2rem;

        .van-field__body {
            border-bottom: 1px solid rgba(238, 238, 238, .5);
            padding-bottom: 0.2rem;
        }
    }
}</style>