<template>
  <!-- 用户升级弹框 -->
  <van-popup v-model:show="isShowAudienceUpgrade" round>
        <div v-if="audienceUpgrade && audienceUpgrade.levelType" class="upgradeWrapper" @click.stop>
            <div v-if="audienceUpgrade.levelType == 2" class="upgradeBox">
                <img src="../../assets/img/live/audience.png"/>
                <p>{{ $t('liveRoom.userUpgradeTips') }} <span>LV{{ audienceUpgrade.level }}</span></p>
                <div @click.stop="close" class="btn">{{ $t('common.confirm') }}</div>
            </div>
        </div>
    </van-popup>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            isShowAudienceUpgrade: false, //主播升级弹框是否展示
        }
    },
    computed: {
        ...mapState(['audienceUpgrade'])
    },
    watch: {
        audienceUpgrade(val) {
            if(val.uid) {
                this.isShowAudienceUpgrade = true;
            }
        },
    },
    methods: {
        close() {
            this.$store.commit("setState", { audienceUpgrade: {} });
            this.isShowAudienceUpgrade = false;
        }
    }
}
</script>

<style lang="scss">
.upgradeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .upgradeBox {
        background-color: #fff;
        width: 5rem;
        min-height: 6rem;
        border-radius: 0.2rem;
        overflow: hidden;
        color: #dc8915;
        font-size: 0.32rem;
        padding-bottom: 0.4rem;

        span {
            font-size: 0.4rem;
        }

        img {
            width: 5rem;
            margin-bottom: 0.3rem;
        }

        .btn {
            font-size: 0.28rem;
            background-color: #dc8915;
            color: #fff;
            border-radius: 0.4rem;
            display: inline-block;
            padding: 0.05rem 0.4rem;
            margin-top: 0.3rem;
            line-height: 0.5rem;
        }

        p {
            padding: 0 0.2rem;
        }
    }
}
</style>