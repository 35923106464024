<template>
    <div class="next addCards">
        <div class="title">
            <span> {{ $t('my.addCard.title') }} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="virtualCoin_cont">
            <van-field :label="`${$t('my.addCard.realName')}`" input-align="right" :disabled="trueName?true:false" v-model="bankParams.trueName" autocomplete="off" clearable :placeholder="`${$t('my.addCard.realNameTips')}`"/>
            <p class="nameTips">{{ $t('my.addCard.realNameTips2') }}</p>
            <van-field :label="`${$t('my.finance.bankNo')}`" input-align="right" v-model="bankParams.cardNo" autocomplete="off" clearable :placeholder="`${$t('my.finance.bankNoTips')}`" />
            <!-- <van-field :label="`${$t('my.finance.bankName')}`" input-align="right" v-model.trim="bankParams.bankName" autocomplete="off" clearable
                @click="openModal('showBankName')" disabled :placeholder="`${$t('my.finance.bankNameTips')}`" /> -->
            <div class="bankSubBox flexBetween" @click="openModal('showBankName')">
                <div>{{ $t('my.finance.bankName') }}</div>
                <div>
                    <span v-show="!bankParams.bankName" class="noVal">{{ $t('my.finance.bankNameTips') }}</span>
                    <span v-show="bankParams.bankName" class="hasVal">{{ bankParams.bankName }}</span>
                </div>
            </div>
            <van-field :label="`${$t('my.addCard.branch')}`" input-align="right" v-model.trim="bankParams.bankSub" autocomplete="off" clearable
                    :placeholder="`${$t('my.addCard.branchTips')}`" />
            <div v-if="number">
                <van-field v-model.trim="number" disabled autocomplete="off">
                    <template #button>
                    <van-button @click="confirmGetCode('getCode')"  v-show="isGetCode && number" color="linear-gradient(90deg,#f86fab,#fa8faf)" round size="small" type="primary">{{$t('common.getCode')}}</van-button>
                    <van-button v-show="!isGetCode || !number" color="linear-gradient(90deg,#f86fab,#fa8faf)" disabled round size="small" type="primary">{{getCodeVal}}</van-button>
                    </template>
                </van-field>
                <van-field :label="`${$t('my.addCard.code')}`" input-align="right" v-model.trim="code" :maxlength="4" autocomplete="off" :placeholder="$t('register.entryCodeTips')" />
            </div>

            <div v-else>
                <van-field :label="`${$t('my.proxy.phoneNumber')}`" input-align="right" v-model.trim="bankParams.phone" autocomplete="off" :placeholder="`${$t('my.proxy.phoneNumber')}`"/>
                <van-field v-model.trim="code" autocomplete="off" :placeholder="$t('register.entryCodeTips')">
                    <template #button>
                    <van-button color="#f86fab" @click="confirmGetCode('getCode')"  v-show="isGetCode && bankParams.phone" round size="small" type="primary">{{$t('common.getCode')}}</van-button>
                    <van-button v-show="!isGetCode || !bankParams.phone" disabled color="#f86fab" round size="small" type="primary">{{getCodeVal}}</van-button>
                    </template>
                </van-field>
            </div>

            <van-field :label="`${$t('my.addCard.withdrawPassword')}`" input-align="right" v-model.trim="password" :maxlength="6" autocomplete="off" clearable
                :placeholder="`${$t('my.addCard.withdrawPasswordTips')}`" />
            <div class="addCardsTip">
                <div v-show="!canNext" class="confirmBtn">{{ $t('my.addCard.title') }}</div>
                <div v-show="canNext" @click="bindCardOrBank" class="confirmBtn hasValue">{{ $t('my.addCard.title') }}</div>
            </div>
        </div>

        <div style="display: none;" id="captcha"></div>

        <!-- 开户银行 -->
        <van-action-sheet v-model:show="showBankName" :title="`${$t('my.addCard.chooseBank')}`" class="currencyModel">
            <div>
                <!-- <van-search v-model.trim="bankName" autocomplete="off" shape="round" :placeholder="`${$t('my.finance.bankNameTips')}`" /> -->
                <div class="bankListCont">
                    <div v-for="(item, index) in bankList" :key="`${index}--bankList`" @click="bankItem(item, index)"
                        class="bankListCont_item flexBetween">
                        <div class="bankListCont_item_left flexCenter">
                            <img v-real-img="item.img" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                            <div>
                                <p>{{ item.bankCode }}</p>
                                <p>{{ item.bankName }}</p>
                            </div>
                        </div>
                        <div v-show="bankActive==index">
                            <van-icon name="success" color="#eb457e" size="0.5rem" />
                        </div>
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import { bindBank, sendCashCode, bankInfo } from "@/api/index";
import { userBankList } from "@/api/config";
import utils from "@/utils/index";
import captcha from '@/pages/mixin/captcha';
export default {
    mixins: [captcha],
    data() {
        return {
            type: "addBankCards",
            password: "",
            showBankName: false,
            bankName: "",
            bankParams: {
                trueName: "",
                cardNo: "",
                bankName: "",
                bankSub: "",
                phone: ""
            },
            bankList: [],
            bankActive: -1,

            isGetCode: true,
            getCodeVal: this.$t('common.getCode'),
            timer: null,

            isGetEmailCode: true,
            getEmailCodeVal: this.$t('common.getCode'),
            timerEmail: null,

            number: "",
            email: "",
            code: "",
            checkType: 1, //1手机 2 邮箱
            captchaObj: {},
            codeCaptch: {},
            loading: false,
            captchaIns: null,
            trueName: "",
            fromPath: ""
        }
    },
    computed: {
        canNext() {
            if (
                this.bankParams.trueName &&
                this.bankParams.bankName &&
                this.bankParams.cardNo &&
                this.code &&
                this.password
            ) {
                return true;
            }

            return false;
        },
        member() {
            return this.$store.state.member;
        },
        suEntry() {
            return this.$store.state.suEntry;
        }
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
        e.fromPath = from.path;
        });
    },
    created() {
        this.type = this.$route.params.type ? this.$route.params.type : this.type;

        if (this.member && this.member.phone) {
            this.number = utils.phoneHide(this.member.phone);
        }
        this.getBankInfo();
        this.$store.commit("setState", { isLoadForLoad: false });
    },
    mounted() {
        this.userCaptcha('getCode');
    },
    methods: {
        // 获取银行卡列表
        getBankInfo() {
            let params = {
                uid: this.member.uid
            }
            bankInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data.length > 0) {
                        this.trueName = this.bankParams.trueName = res.data[0].trueName;
                        
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 返回逻辑
        goBack() {
            let arr = ['/mine/finance/withdraw/next', '/mine/myProxy/withdraw', '/mine/invite/withdraw'],
            index = arr.findIndex(item => {
                return this.fromPath.indexOf(item)
            })
            if (index != -1) {
                this.goOtherPage(this.fromPath);
            }
        },
        // 获取银行列表
        getBankList(val) {
            let params = {
                uid: this.member.uid
            }
            userBankList(params).then(res => {
                if (res.code == 0) {
                    this[val] = true;
                    this.bankList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        nextStep() {
            this.checkType = 1;
            this.step = 'getCode';

            this.isGetCode = true;
            this.getCodeVal = this.$t('common.getCode');

            if (this.timer) {
                clearInterval(this.timer)
            }
        },
        openModal(val) {
            this.getBankList();
            this[val] = true;
        },
        bankItem(item, index) {
            this.bankActive = index;
            this.showBankName = false;
            this.bankParams.bankName = item.bankName;
        },
        goOtherPage(path) {
            // 未登录 跳转登录或注册界面
            this.$router.push(path);
        },
        // 手机验证码
        getCode() {
            if (!this.isGetCode) {
                return;
            }
            let params = {
                mobile: this.number ? this.member.phone : this.bankParams.phone,
                type: 6,
                areaCode: this.member.areaCode ? this.member.areaCode : '84',
                captchaValidate: this.captchData.validate,	//网易行为验证返回编码
                type: 5,
                captchaType: 1, //字段逻辑待添加
                verificationNo: this.captchaObj.verificationNo
            }
            this.isGetCode = false;
            sendCashCode(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('common.getCodeSuccess'));
                    let time = 179;
                    let that = this;
                    this.getCodeVal = time + 's';
                    this.timer = setInterval(() => {
                        if (time > 0) {
                            time--;
                            that.getCodeVal = time + 's';
                        } else {
                            that.getCodeVal = this.$t('common.getCode');
                            that.isGetCode = true;
                            clearInterval(this.timer);
                        }
                    }, 1000);
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                this.isGetCode = true;
                console.log('catch');
            });
        },
        bindBankS(params) {
            this.$store.commit("setState", { isLoadForLoad: true });
            bindBank(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.goBack();
                    this.$toast(this.$t('my.addCard.bindSuccessTips'));
                    if (!this.number) {
                        this.$store.dispatch('refreshUserInfo');
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(err => {
                console.log(err);
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        },
        // 绑定银行卡
        bindCardOrBank() {
            let params = {};
            if (this.type == 'addBankCards') {
                params = {
                    mobile: this.number ? this.member.phone : this.bankParams.phone,
                    bankCity: "unknown",  //开户市
                    bankCode: this.bankList[this.bankActive].bankCode,  //银行编码
                    bankName: this.bankParams.bankName,  //开户银行名
                    bankProvince: "unknown",  //开户省
                    bankSub: this.bankParams.bankSub ? this.bankParams.bankSub : 'unknown',  //支行名称 => 开户地址
                    cardNo: this.bankParams.cardNo,  //银行卡号
                    phoneCode: this.code,  //手机验证码
                    trueName: this.bankParams.trueName,  //真实姓名
                    cashPassword: this.password,
                    bindType: 1, //1手机绑定 2 邮箱绑定
                    uid: this.member.uid
                }
            }

            if (this.member) {
                this.bindBankS(params);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style lang="scss" scoped>
.addCards {
    background-color: #fff;

    &.next .title {
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
    }

    .virtualCoin_cont {
        text-align: left;
        padding: 0.1rem 0.28rem 0.28rem;
        margin-bottom: 0.2rem;

        .bankSubBox {
            line-height: 1.04rem;
            border-bottom: 1px solid #ebeff1;

            .noVal {
                color: #cbced8;
            }

            .hasVal {
                color: #414655;
            }
        }

        .itemBody_name {
            font-size: .32rem;
            color: #414655;
        }

        .itemBody_tip {
            position: relative;
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            span {
                font-size: .28rem;
                color: #cbced8;
                vertical-align: middle;

                &.written {
                    font-size: 0.36rem;
                    color: #414655;
                }
            }
        }

        .nameTips {
            width: 100%;
            text-align: right;
            color: rgb(248, 111, 171);
            padding: 0.05rem 0;
        }

        .wrongTip {
            color: #C41A5C;
            padding: 0.2rem 0 0.25rem;
        }

        .itemTitle {
            margin-bottom: 0.12rem;
            font-size: .28rem;
            color: #414655;
            margin-top: 0.3rem;

            span {
                color: #C41F1A;
                margin-right: 0.1rem;
            }
        }

        .fontTip {
            color: #788597;
            font-size: 0.24rem;
        }

        .addCardsTip {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 0.2rem 0 0.3rem;
            // margin: 0.4rem 0;
            color: #a5a9b3;
            font-size: .24rem;
            line-height: .34rem;

            &.borderBot {
                border-bottom: 1px solid rgba(238, 238, 238, 0.5);
                margin-bottom: 0.2rem;
            }

            .btn {
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-align-items: center;
                align-items: center;
                cursor: pointer;
                width: 1.58rem;
                height: 0.7rem;
                border-radius: 0.1rem;
                border: 1px solid #eee;
                text-align: center;
                line-height: .7rem;
                color: #414655;
                font-size: .28rem;
                margin-right: 0.2rem;
                position: relative;
                font-weight: 500;
                font-family: DinProMedium;
                -webkit-transition: all .3s;
                transition: all .3s;

                &.activeBtn {
                    color: #6aaaf5;
                    border: 1px solid #6aaaf5;
                }

                .select {
                    width: 0.32rem;
                    height: auto;
                    position: absolute;
                    bottom: -0.02rem;
                    right: -0.02rem;
                    z-index: 1;
                }

                .icon {
                    display: block;
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-right: 0.08rem;
                }
            }
        }

        .getCodeBox {
            line-height: 1.04rem;
            border-bottom: 1px solid #ebeff1;

            div {
                height: 0.56rem;
                line-height: 0.56rem;
                padding: 0 0.4rem;
                background-color: #eb457e;
                color: #fff;
                border-radius: 0.4rem;
            }

            &.disable div {
                opacity: 0.5;
            }
        }
    }

    .confirmBtn {
        margin: 0.8rem auto 0;
        width: 100%;
    }

    .userInfo_next_cont {
        background: #fff;
        text-align: left;

        &.userInfo_next_cont_name {
            padding: 0.45rem 0.28rem;
        }

        &.userInfo_next_cont_phone {
            padding: 0.25rem 0.28rem 0.28rem;
            margin-bottom: 0.28rem;
        }

        .itemTitle {

            &.tips {
                color: #db6372;
                height: 0.35rem;
                margin-bottom: 0.15rem;
            }
        }

    }

    .currencyListCont {
        margin-bottom: 0.3rem;

        .bankListCont_item {
            padding: 0.35rem 0;
            margin: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            .bankListCont_item_left {
                text-align: left;
                color: #333;
            }
        }
    }

    .bankListCont {
        margin-bottom: 0.3rem;

        .bankListCont_item {
            padding: 0.35rem 0;
            margin: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            .bankListCont_item_left {
                text-align: left;
                color: #333;

                img {
                    width: 0.64rem;
                    height: 0.64rem;
                    margin-right: 0.1rem;
                }

                div {

                    p:first-child {
                        font-size: 0.32rem;
                        font-weight: bold;
                    }

                    p:last-child {
                        font-size: 0.25rem;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.addCards .virtualCoin_cont .van-cell {
    border-bottom: 1px solid #ebeff1;
    padding: 0.28rem 0;

    &:after {
        border-color: #fff;
    }

    .van-field__control {
        font-size: 0.28rem;
        color: #333;

        &:disabled {
            color: #333;
            -webkit-text-fill-color: #333;

            &::-webkit-input-placeholder {
                /*Webkit browsers*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }

            &:-moz-placeholder {
                /*Mozilla Firefox 4 to 8*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }

            &::moz-placeholder {
                /*Mozilla Firefox 19+*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }

            &:-ms-input-placeholder {
                /*Internet Explorer 10+*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }
        }

        &::-webkit-input-placeholder{/*Webkit browsers*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }
        &:-moz-placeholder{/*Mozilla Firefox 4 to 8*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }
        &::moz-placeholder{/*Mozilla Firefox 19+*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }
        &:-ms-input-placeholder{/*Internet Explorer 10+*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }

    }

    
}

.addCards {
    .van-field--disabled .van-field__label {
        color: #333;
    }
}

.addCards .currencyModel {
    .van-action-sheet__header {
        font-size: 0.34rem;
        background-color: #F9F9F9;
        font-weight: bold;
    }

    .van-action-sheet__close {
        font-size: 0.34rem;
    }
}
</style>
