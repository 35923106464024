<template>
  <div class="otherWeb">
    <van-popup v-model:show="isShowOtherWeb" position="bottom"
        :style="{ width: '100%', height: '100%' }" @close="close">
        <div class="iframeDialog">
            <div class="iframeHead">
                <div class="fixed" @click="close">
                    <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        p-id="12456" width="0.35rem" height="0.35rem">
                        <path
                            d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                            fill="#666666" p-id="12457"></path>
                    </svg>
                </div>
            </div>

            <div class="iframeBox" v-if="isShowOtherWeb">
                <iframe class="iframeDom" frameborder="0" :src="otherWebUrl"></iframe>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "otherWeb",
    data() {
        return {
            showOtherWeb: false
        }
    },
    computed: {
        ...mapState(['isShowOtherWeb', 'otherWebUrl']),
    },
    watch: {
        isShowOtherWeb(val) {
            this.showOtherWeb = val;
        }
    },
    methods: {
        close() {
            this.$store.commit("setState", { isShowOtherWeb: false });
        }
    }
}
</script>

<style lang="scss">
.otherWeb {
    .iframeDialog {
        background-color: #F9F9F9;
        height: 100vh;
        box-sizing: border-box;
        padding-top: 1rem;
        font-size: 0.28rem;
        color: #333;
        

        .iframeHead {
            position: fixed;
            top: 0px;
            left: 0;
            z-index: 10;
            background-color: #fff;
            font-size: 0.32rem;
            text-align: center;
            height: 0.88rem;
            line-height: 0.88rem;
            background-color: #fff;
            color: #414655;
            width: 100%;
            box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.05);
        }

        .fixed {
            height: 0.88rem;
            line-height: 0.88rem;
            position: fixed;
            top: 0;
            left: 0;
            width: 0.88rem;
            text-align: center;
        }
    }

    .iframeBox {
        width: 100vw;
        height: calc(100vh - 1rem);
        overflow: hidden;
        margin: 0 auto;

        /* 隐藏滚动条 */
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */

        &::-webkit-scrollbar {
            width: 0;      /* Safari,Chrome 隐藏滚动条 */
            height: 0;     /* Safari,Chrome 隐藏滚动条 */
            display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }

        .iframeDom {
            width: calc(100vw + 0.2rem);
            height: calc(100vh - 1rem);
        }
    }

}
</style>