<template>
    <div class="next platformFee">
        <div class="title">
            <span>{{$t('my.proxy.accountAdjustment')}}</span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.accountAdjustment')}}：</div>
                <div>{{brokerageRoportDetail.accountAdjustment}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.instructionManual')}}：</div>
            </div>
            <div v-if="brokerageRoportDetail.remark" class="tableItem flexBetween">
                <div v-html="brokerageRoportDetail.remark"></div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    computed: {
        brokerageRoportDetail() {
            return this.$store.state.brokerageRoportDetail;
        },
    },
    created() {
    },
    methods: {
        gaBack() {
            this.$router.push(`/mine/myProxy/brokerageRoport`);
        }
    }
}
</script>

<style lang="scss" scoped>
.platformFee {
    padding-top: 1rem;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
    }

    .title {
        height: auto;
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            div:nth-child(2) {
                color: #333;

                &.blue {
                    color: #eb457e;
                }
            }

            img {
                width: 0.28rem;
            }
        }
    }
}
</style>