<template>
    <div class="next myMemberInfo">
        <div class="title">
            <span> {{$t('my.proxy.memberInfo')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.memberID')}}：</div>
                <div>{{detial.uid}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.nickName')}}：</div>
                <div>{{detial.nickname}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.grade')}}：</div>
                <div>LV{{detial.userLevel}}</div>
            </div>
            <!-- <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.clevel')}}：</div>
                <div>{{detial.userHierarchyName}}</div>
            </div> -->
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.numberDeposits')}}：</div>
                <div @click="goDepositRecord" class="flexCenter">
                    {{ numFormat(detial.rechargeNum) }}
                    <img src="../../../assets/img/my/right.png" />
                </div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.totalDepositAmount')}}：</div>
                <div @click="goDepositRecord" class="flexCenter">
                    {{ numFormat(detial.rechargeAmount)}}
                    <img src="../../../assets/img/my/right.png" />
                </div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.numberWithdrawals')}}：</div>
                <div @click="goWithdrawRecord" class="flexCenter">
                    {{ numFormat(detial.withdrawNum)}}
                    <img src="../../../assets/img/my/right.png" />
                </div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.withdrawAmount')}}：</div>
                <div @click="goWithdrawRecord" class="flexCenter">
                    {{ numFormat(detial.withdrawAmount)}}
                    <img src="../../../assets/img/my/right.png" />
                </div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.invite.registrationTime')}}：</div>
                <div>{{getTime(detial.gmtCreate)}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.lastLoginTime')}}：</div>
                <div>{{getTime(detial.lastLoginTime)}}</div>
            </div>
            <div class="tableItem flexBetween">
                <div>{{$t('my.proxy.proxyUrl')}}：</div>
                <div>{{detial.proxyUrl}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryUserInfo } from "@/api/proxy";
import moment from "moment";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            detial: {}
        }
    },
    computed: {
        ...mapState(['member', 'betPramas']),
    },
    created() {
        let betPramas = JSON.parse(JSON.stringify(this.betPramas));
        if (this.$route.query.id) {
            this.getInfo(this.$route.query.id);
            betPramas.id = this.$route.query.id;
        }

        if (this.$route.query.type) {
            betPramas.type = this.$route.query.type;
        }

        this.$store.commit("setState", { betPramas });
    },
    methods: {
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm:ss DD-MM-YYYY');
            }
            return "";
        },
        gaBack() {
            if (this.betPramas && this.betPramas.type) {
                this.$router.push(`/mine/myProxy/${this.betPramas.type}`);
            } 
        },
        goDepositRecord() {
            this.$router.push(`/mine/myProxy/myMember/record/deposit?id=${this.$route.query.id}`);
        },
        goWithdrawRecord() {
            this.$router.push(`/mine/myProxy/myMember/record/withdraw?id=${this.$route.query.id}`);
        },
        getInfo(id) {
            let params = {
                uid: this.member.uid,
                belowUid: id
            }
            queryUserInfo(params).then(res => {
                if (res.code == 0) {
                    this.detial = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.myMemberInfo {
    padding-top: 1rem;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
    }

    .title {
        height: auto;
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            div:nth-child(2) {
                color: #333;
            }

            img {
                width: 0.28rem;
            }
        }
    }
}
</style>