<template>
    <div class="next">
      <div class="title">
          <span>{{ $t("my.systemSet.deviceManagement") }}</span>
          <div @click="goBack" class="fixed">
              <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
          </div>
      </div>
  
      <div class="content">
        <div class="tips">{{ $t("my.systemSet.deviceManagementTips") }}</div>

        <div class="conTitle"> {{ $t("my.systemSet.isLoginDevice") }} </div>

        <div v-for="(item, index) in localDevice" :key="`${index}-deviceManagement-localDevice`" class="conItem flexBetween topBor">
            <div class="conItemL">
                <div>{{ item.model }}</div>
                <div class="time">{{ getDate(item.loginTime) }}</div>
            </div>
            <div class="conItemR">{{ $t("my.systemSet.localMachine") }}</div>
        </div>

        <div class="conTitle"> {{ $t("my.systemSet.isLoginOutDeviceManagement") }} </div>

        <div v-for="(item, index) in otherDevice" :key="`${index}-deviceManagement-otherDevice`" class="conItem flexBetween topBor">
            <div class="conItemL">
                <div>{{ item.model }}</div>
                <div class="time">{{ getDate(item.loginTime) }}</div>
            </div>
            <div class="conItemR">{{ item.ip }}</div>
        </div>
      </div>
    </div>
</template>

<script>
import { getUserDevices } from "@/api/index";
import utils from "@/utils/index";
import md5 from 'js-md5';
import moment from "moment";
export default {
    name: 'deviceManagement',
    data() {
        return {
            cont: this.$t("about.cont"),
            fromPath: "",
            allDevice: []
        }
    },
    created() {
        this.getUserDevicesData();
    },
    computed: {
        localDevice() {
            let arr = this.allDevice.filter(item => {
                return item.flag;
            });

            if (arr.length == 0) {
                let obj = {};
                obj.model = `${utils.getMobileOperatingSystem()}/H5: ${localStorage.getItem('uid')}`
                obj.loginTime = new Date().getTime();
                arr.push(obj);
            }

            return arr;
        },
        otherDevice() {
            return this.allDevice.filter(item => {
                return !item.flag;
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
            e.fromPath = from.path;
        });
    },
    methods: {
        getDate(val) {
            if (val) {
                return moment(val).format('HH:mm:ss DD-MM-YYYY');
            } else {
                return "";
            }
        },
        goBack() {
            if (this.fromPath == '/mine/set') {
                this.$router.push('/mine/set');
            } else {
                this.$router.push('/mine');
            }
        },
        getUserDevicesData() {
            let uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            param = {
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid,
                domain: ""
            }
            getUserDevices(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.allDevice = res.data;
                } else {
                    if(res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        }
    }
}
</script>
  
<style lang="scss" scoped>
  .next {
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 0.2rem;
    font-size: 0.28rem;
    .content {
        text-align: left;
        font-size: 0.27rem;

        .tips {
            color: #969696;
            font-size: 0.24rem;
            padding: 0 0.28rem;
            margin-top: 0.1rem;
            text-align: justify;
        }

        .conTitle {
            margin: 0.4rem 0 0.16rem;
            padding: 0 0.28rem;
            color: #333;
            font-weight: bold;
        }

        .conItem {
            padding: 0.28rem;
            background-color: #fff;
            border-bottom: 1px solid #f2f2f2;

            &.topBor {
                border-top: 1px solid #f2f2f2;
            }

            .time {
                font-size: 0.24rem;
                color: #969696;
                margin-top: 0.2rem;
            }

            .conItemR {
                width: 2.2rem;
                text-align: right;
            }

            .conItemL {
                width: calc(100vw - 2.2rem);
                word-break: break-all; 
            }
        }
        
    }
  }
  </style>