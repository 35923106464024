<template>
    <div class="next lottery">
        <div class="title">
            <span>{{name}}</span>
            <div class="fixed" @click="goOtherPage('/game')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>
        </div>

        <div class="lotteryCont">
            <div class="games">
                <div v-for="(item, index) in games" :key="`${index}--lotteryGames`" @click="gamesClick(item)" class="gamesItem">
                    <img v-real-img="item.icon" />
                    <p class="gamesName">{{item.name}}</p>
                </div>
            </div>
        </div>

        <otherWeb></otherWeb>
    </div>
</template>

<script>
import { gameLabelGameLists, startGame, getBalance } from "@/api/index";
import { cpList } from "@/api/config";
import md5 from 'js-md5';
import utils from '@/utils/index';
import otherWeb from "@/pages/components/otherWeb.vue";
export default {
    name: "lotteryIndex",
    components: {
        otherWeb
    },
    data() {
        return {
            games: [],
            money: "0",
            name: "",
            type: 0,
            remark: ""
        }
    },
    computed: {
        isLogin() {
            return this.$store.getters.isLogin
        },
        member() {
            return this.$store.state.member;
        },
        lotteryRouter() {
            return this.$store.state.lotteryRouter;
        },
    },
    created() {
        this.name = this.$route.params.name;
        this.type = this.$route.params.type;
        this.remark = this.$route.params.remark;
        this.getData();

        if (this.type == 1 && this.isLogin) {
            this.getBalance();
        }

        if (this.type == 2 && this.isLogin) {
            this.money = this.member.goldCoin;
        }
    },
    methods: {
        // 三方余额钱包查询
        getBalance() {
            let params = {
                gamePlatform: this.remark,
                uid: this.member.uid
            }
            getBalance(params).then(res => {
                if (res.code == 0) {
                    this.money = res.data[0].balance;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        gamesClick(item) {
            if (this.type == 2 && item.link) {
                this.$router.push(item.link);
            } else {
                this.startGame(item);
            }
        },
        startGame(item) {
            let uid = localStorage.getItem('uid') || utils.guid(),
            params = {
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid(),
                gameId: item.gameId,
                gamePlatform: item.name,
                uid: this.member.uid,
                id: item.id
            }
            startGame(params).then(res => {
                if (res.code == 0) {
                    // window.location.href = res.data.forwardUrl;
                    this.$store.commit("setState", { otherWebUrl: res.data.forwardUrl, isShowOtherWeb: true });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        getData(){
            if (this.type == 2) {
                this.getCpList();
            } else {
                this.getSuList(this.name);
            }
        },
        getSuList(gamePlatform) {
            gameLabelGameLists(gamePlatform).then(res => {
                if (res.code == 0) {
                    this.games = res.data.list;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        getCpList() {
            cpList().then(res => {
                if (res.code == 0) {
                    let arr = JSON.parse(JSON.stringify(res.data));
                    arr.forEach(item => {
                        let index = this.lotteryRouter.findIndex(xitem => {
                            return xitem == item.name
                        });

                        if (index != -1) {
                        item.link = `/lottery/${item.name}`;
                        }
                    });

                    this.games = arr;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.lottery {
    text-align: left;
    box-shadow: 0 0 0 0 #fff;
    overflow-y: scroll;

    .lotteryCont {
        .games {
            padding: 0 0.36rem;
            vertical-align: top;
            padding-bottom: 0.4rem;

            .gamesItem {
                height: 2.88rem;
                width: calc(33.3% - 0.3rem);
                display: inline-block;
                vertical-align: top;
                position: relative;
                border-radius: 0.1rem;
                box-shadow: 1px 1px 3px 1px #D2D2D2;
                margin-right: 0.44rem;
                margin-top: 0.44rem;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: 2rem;
                }

                .gamesName {
                    color: #626872;
                    font-size: 0.24rem;
                    position: absolute;
                    top: 2rem;
                    left: 0;
                    text-align: center;
                    width: 100%;
                    margin: 0.16rem 0 0.1rem;
                    padding: 0 0.05rem;
                }
            } 
        }
    }
}
</style>