<template>
  <div>
    <div v-if="isShowDownApp" class="downApp flexBetween">
      <div class="downAppLeft flexCenter">
        <img src="./assets/img/downLoad/appLogo.png" />
        <div>
          <div class="logo">QQLive</div>
          <div>{{ $t('home.downLoadNow') }}</div>
        </div>
      </div>

      <div class="downAppRight flexCenter">
        <div @click="goDownLoad">{{ $t('home.downLoad') }}</div>
        <van-icon @click="closeDown" color="#969696" size="0.28rem" name="clear" />
      </div>
    </div>
    <div class="main">
      <router-view v-slot="{ Component }">
        <keep-alive :include="keepAliveNameList">
          <component :is="Component" :key="$route.fullPath" />
        </keep-alive>
      </router-view>

      <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view> -->
    </div>

    <div v-show="showFooter && !isMaintain" class="footer">
      <ul>
        <li v-for="(item, index) in footer" :key="`${index}--footer`" @click="changeLink(item, index)"
          :class="active == index ? 'active' : ''">
          <i></i>
          <img v-show="active == index" v-real-img="item.iconUrlSelected" />
          <img v-show="active != index" v-real-img="item.iconUrl" />
          <span>{{ item.tagNameShow }}</span>
        </li>
      </ul>
    </div>

    <van-dialog :className="`confirmDialog`" v-model:show="showDialog" confirm-button-Color="#fa7c87"
      :confirmButtonText="$t('common.confirm')"
      @confirm="confirmAccount">
      <div v-if="loginOutInfo.msg" class="loginOut flexCenterBL">
        <!-- {{ $t('login.loginOutTips') }} -->
        {{ loginOutInfo && loginOutInfo.msg }}
        <!-- {{ $t('login.loginOutTips1') }} -->
      </div>
      <div v-else class="loginOut flexCenterBL">
        {{ $t('login.loginOut') }}
      </div>
    </van-dialog>

    <audio id="lotteryWinningSound">
      <source src="./assets//video/notice2.mp3" type="audio/mpeg" />
    </audio>

    <LoadCom></LoadCom>
    <upgrade></upgrade>
    <downloadApp></downloadApp>
    <downloadAppF></downloadAppF>
  </div>
</template>
<script>
import maintainData from "@/assets/maintain";
import { systemConfig, getChannel } from "@/api/config";
import { mapState } from 'vuex';
import { GetRequest } from "@/utils/index";
import LoadCom from "@/components/load.vue";
import upgrade from "@/pages/components/upgrade.vue";
import downloadApp from "./pages/components/downloadApp.vue";
import downloadAppF from "./pages/components/downloadAppIndex.vue";
export default {
  name: 'App',
  components: {
    LoadCom,
    upgrade,
    downloadApp,
    downloadAppF
  },
  data() {
    return {
      footerShow: [
        {
          font: this.$t('footer.index'),
          link: "/home",
          code: 'home'
        },
        {
          font: this.$t('footer.game'),
          link: "/game",
          code: "game"
        },
        {
          font: this.$t('footer.live'),
          link: "/live",
          code: "live"
        },
        {
          font: this.$t('footer.discount'),
          link: "/discount",
          code: "activity"
        },
        {
          font: this.$t('footer.my'),
          link: "/mine",
          code: "my"
        }
      ],
      footer: [],
      active: 0,
      showFooter: true,
      isMaintain: false,
      // 'homeBanner', 'discount'
      keepAliveNameList: ['home', 'live', 'game'],
      mainObj: {},
      isMaintainShow: true,
      isInitSuccess: false
    };
  },
  computed: {
    ...mapState(['member', 'isLoginTm', 'isLoginOutIm', 'liveInfo', 'showDownApp', 'baseInfo', 'loginOutInfo']),
    showDialog() {
      return this.isLoginOutIm;
    },
    isShowDownApp() {
      const validPaths = ['/live', '/home', '/game', '/mine'];
      return validPaths.includes(this.$route.path) && this.showDownApp;
    },
  },
  watch: {
    $route(to) {
      if (to.path != '/liveRoom' && to.path != '/entryLiveBefore' && this.liveInfo && this.liveInfo.liveId) {
        this.$store.commit("setState", { liveInfo: {} });
      }
      this.init(to.path);
    }
  },
  created() {
    this.$store.commit("setState", { isLoadForLoad: false, showDownApp: true });
    if (maintainData.mainObj.isShow === '0') {
      this.$store.dispatch("initData");
      if (sessionStorage.getItem('token')) {
        this.$store.dispatch("timDestroy");
      }

      setTimeout(() => {
        this.$store.dispatch("initTm");
      }, 100);

      this.$nextTick(() => {
        this.init(this.$route.path);
      });
    } else {
      this.isMaintain = true;
    }
    this.$store.commit("setState", { systemConfig: []});
    this.systemConfig();

    let that = this, param = "";
    if (GetRequest('puid')) {
      sessionStorage.setItem('puid', GetRequest('puid'));
      param = GetRequest('puid');
      ShareTrace.init({
          appkey: "9a7b79a5969d5c2b", // 此值必填
          param: param,
          success: function() {
            that.isInitSuccess = true;
          }
      });
    } else {
      let domin = window.location.hostname;
      this.$store.commit("setState", { isLoadForLoad: true });
      getChannel(domin).then(res => {
        if (res.code === 0 || res.code === '0') {
          if (res.data) {
            ShareTrace.init({
              appkey: "9a7b79a5969d5c2b", // 此值必填
              param: param,
              success: function() {
                that.isInitSuccess = true;
              }
            });
          }
        }
        this.$store.commit("setState", { isLoadForLoad: false });
      }).catch(err => {
        this.$store.commit("setState", { isLoadForLoad: false });
      })
    }

    let appId = "", idfa = "", adjustAdid2 = "", adjustAdgroup2 = "", appsFlyerId2 = "", isVest = false;
    if (GetRequest('appId')) {
      appId = GetRequest('appId');
    }
    if (GetRequest('idfa')) {
      idfa = GetRequest('idfa');
    }
    if (GetRequest('adjust_adid')) {
      adjustAdid2 = GetRequest('adjust_adid');
    }
    if (GetRequest('adjust_adgroup')) {
      adjustAdgroup2 = GetRequest('adjust_adgroup');
    }
    if (GetRequest('apps_flyer_id')) {
      appsFlyerId2 = GetRequest('apps_flyer_id');
    }

    if (window.location.href.indexOf('adjust_adid') != -1 && window.location.href.indexOf('adjust_adgroup') != -1) {
      isVest = true;
    }

    this.$store.commit("setState", { appId, idfa, adjustAdid2, adjustAdgroup2, appsFlyerId2, isVest });
  },
  methods: {
    // 关闭头部下载app提示
    closeDown() {
      this.$store.commit("setState", { showDownApp: false });
    },
    // 下载点击
    goDownLoad() {
      if (this.baseInfo && this.baseInfo.floorUrlWeb) {
        let url = this.baseInfo.floorUrlWeb
        if (sessionStorage.getItem('puid')) {
          url = url + '?puid=' + sessionStorage.getItem('puid')
        }
        window.location.href = url;
      }
    },
    // 登出
    // showCont() {
    //   let that = this;
    //   this.$dialog({
    //     message: that.$t('login.loginOut'),
    //     className: "confirmDialog",
    //     confirmButtonText: that.$t('common.confirm'),
    //     confirmButtonColor: "#eb457e"
    //   }).then(() => {
    //     that.$store.commit("setState", { isLoginOut: false });
    //   })
    // },
    confirmAccount() {
      this.$store.commit("setState", { isLoginOutIm: false, loginOutInfo: {} });
    },
    // 获取界面信息
    systemConfig() {
      systemConfig().then(res => {
        if (res.code === 0 || res.code === '0') {
          let arr = JSON.parse(JSON.stringify(res.data)), newArr = [];
          arr.forEach(item => {
            this.footerShow.forEach(xitem => {
              if (xitem.code == item.code) {
                let obj = { ...xitem, ...item };
                if (obj.systemConfigTagList && obj.systemConfigTagList.length > 0) {
                  obj.systemConfigTagList = obj.systemConfigTagList.sort(function (a, b) {
                    return (a.tagSort - b.tagSort);
                  });
                }
                newArr.push(obj);
              }
            });
          });

          newArr = newArr.sort(function (a, b) {
            return (a.tagSort - b.tagSort);
          });

          this.footer = JSON.parse(JSON.stringify(newArr));
          this.init(this.$route.path);
          this.$store.commit("setState", { systemConfig: this.footer});
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => { })
    },
    // 维护下一步
    nextSet() {
      let obj = {
        url: this.mainObj.service_link_vn
      }
      this.$store.commit("setState", { cserver: obj });
      this.isMaintainShow = false;
    },
    // 维护上一步
    prveSet() {
      this.isMaintainShow = true;
    },
    changeLink(item, index) {
      this.active = index;
      this.$router.push(item.link);
    },
    init(path) {
      let index = this.footer.findIndex(item => {
        return item.link == path;
      });

      this.active = index;

      this.showFooter = index == -1 ? false : true;
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main {
  width: 100%;
}

.footer {
  position: fixed;
  width: 100%;
  height: 1.06rem;
  left: 0;
  bottom: -2px;
  z-index: 100;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);

  ul {
    display: flex;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.1);
    background: #fff;

    li {
      -webkit-flex: 1;
      flex: 1;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      position: relative;
      padding-top: 0.04rem;

      &.active {
        i {
          animation: itemBG .5s ease-in-out;
        }

        span,
        img {
          color: #f770ab;
        }
      }

      i {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        margin: auto;
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        // -webkit-transform: translate(-50%,-50%);
        // transform: translate(-50%,-50%);
        background: #f770ab;
        opacity: 0;
      }

      span {
        margin-top: 0.06rem;
        font-size: .2rem;
        color: #9f9f9f;
        margin-bottom: 0.1rem;
        white-space: nowrap;
      }

      img {
        width: 0.52rem;
        height: 0.52rem;
      }
    }
  }
}

@keyframes itemBG {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
    opacity: 0.1;
  }

  50% {
    transform: scale(4);
    -webkit-transform: scale(4);
    opacity: 0.15;
  }

  75% {
    transform: scale(5.5);
    -webkit-transform: scale(5.5);
    opacity: 0.05;
  }

  100% {
    transform: scale(5.5);
    -webkit-transform: scale(5.5);
    opacity: 0;
  }
}

.loginOut {
  min-height: 2rem;
  padding: 0.2rem;
}

.downApp {
  background-color: #e5e5e5;
  font-size: 0.2rem;
  padding: 0.2rem 0.28rem;
  color: #999;

  .downAppLeft {
    text-align: left;
    img {
      margin-right: 0.16rem;
    }

    .logo {
      color: #333;
      font-size: 0.28rem;
      font-weight: bold;
    }
  }

  .downAppRight {
    div {
      background: -webkit-linear-gradient(left,#f86fab,#fa8faf);
      background: linear-gradient(90deg,#f86fab,#fa8faf);
      border-radius: 0.8rem;
      padding: 0 0.4rem;
      color: #fff;
      line-height: 0.56rem;
      margin-right: 0.2rem;
    }
  }
}
</style>
