import { mapState } from 'vuex';
import { getVoiceBase, getVoiceUser, userRefuse } from "@/api/live";
import md5 from 'js-md5';
import utils from "@/utils/index";
import SVGA from "svgaplayerweb";
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
const voice = {
    data() {
        return {
          vplayer1: null, // 麦位讲话特效特效播放器
          vparser1: null,

          vplayer2: null, // 麦位讲话特效特效播放器
          vparser2: null,


          vplayer3: null, // 麦位讲话特效特效播放器
          vparser3: null,

          isStartSvgAnimation1: false,
          isStartSvgAnimation2: false,
          isStartSvgAnimation3: false,
          voiceTCPlayerObj: {},
          lmTimer: null,
          lmTime: ""
        }
    },
    computed: {
        ...mapState([
          'member', 
          'liveInfo',
          'voiceUserList',
          'anchorInvit',
          'anchorInvitObj',
          'isLoadForLoad'
        ]),
        showVoiceUser() {
          let voiceUserList = this.voiceUserList ? JSON.parse(JSON.stringify(this.voiceUserList)) : [], len = this.liveInfo.voiceBase.num || 3;
          const idsToCheck = Array.from({ length: len }, (_, index) => index + 1);

          idsToCheck.forEach(sortNum => {
            if (!voiceUserList.some(obj => obj.sortNum === sortNum)) {
              let obj = {
                nickname: this.$t('liveRoom.voice.apply'),
                voiceRecordId: -1,
                banStatus: 0,
                sortNum: sortNum
              }
              voiceUserList.push(obj);
            }
          });
          voiceUserList.sort((a, b) => a.sortNum - b.sortNum);

          return voiceUserList;
        },
        isVoice() {
          let flag = 0;
          if (this.liveInfo && this.liveInfo.voiceBase ) {
            flag = this.liveInfo.voiceBase.isVoice;
          }
          return flag;
        },
        isAnchorInvit() {
          return this.anchorInvit ? this.anchorInvit : false;
        }
    },
    watch: {
      voiceUserList(val) {
        if (this.$route.path == '/liveRoom') {
          this.disposeDom();
          if (val.length > 0) {
            this.creatDom();
          }
        }
          // val.forEach((item, index) => {
          //   if (item.volume && !this[`isStartSvgAnimation${index+1}`]) {
          //     this[`vplayer${index+1}`] && this[`vplayer${index+1}`].startAnimation(), this[`isStartSvgAnimation${index+1}`] = true;
          //   }

          //   if (!item.volume && this[`isStartSvgAnimation${index+1}`]) {
          //     this[`vplayer${index+1}`] && this[`vplayer${index+1}`].pauseAnimation(), this[`isStartSvgAnimation${index+1}`] = false;
          //   }
        // });
      },
      'anchorInvitObj.voiceRecordId'(val)  {
        if (val) {
          this.goDownLmtimer(30);
        }
      }
    },
    methods: {
      // 根据数据创建 video  
      creatDom() {
        const parentElement = document.getElementById('voiceBox');
        // 遍历子元素并获取它们的ID
        const childIds = [];
        parentElement.childNodes.forEach(child => {
          if (child.nodeType === 1) {
            childIds.push(child.id);
          }
        });

        let voiceUserList = this.voiceUserList ? JSON.parse(JSON.stringify(this.voiceUserList)) : [];

        // 获取麦上用户没拉流
        let isNotGetPlay = [];

        if (voiceUserList.length > 0) {
          isNotGetPlay = voiceUserList.filter(vItem => !childIds.some(cItem => {
            cItem.voiceRecordId != -1 && cItem.voiceRecordId === `liveLm${vItem.voiceRecordId}`
          }));
        }

        if (isNotGetPlay.length == 0) return;
        isNotGetPlay.forEach(item => {
          if (item.voiceRecordId) {
            this.getItemPlay(item);
          }
        });
      },
      // 单个拉流
      getItemPlay(item) {
        if (!item.pullSource) return;
        let liveIdBox = document.getElementById(`liveLm${item.voiceRecordId}`);
        if (!liveIdBox) {
          const newVideo = document.createElement('video');
          newVideo.id = `liveLm${item.voiceRecordId}`;
          newVideo.autoplay = true;
          newVideo.preload = 'auto';
          newVideo.setAttribute('playsinline', true);
          newVideo.setAttribute('webkit-playsinline', true);

          // 将新的 video 元素添加到 voiceBox 中
          const voiceBox = document.getElementById('voiceBox');
          voiceBox.appendChild(newVideo);
        }

        let url = item.pullSource.replace(/rtmp/, "webrtc");
        // 如果之前已经初始化过则销毁
        let tcplayer = TCPlayer(`liveLm${item.voiceRecordId}`, {
            width: 2,
            height: 1,
            controls: false,
            posterImage: true,
            autoplay: true,
            language: "YN"
        });
        tcplayer.src(url);

        tcplayer.on('error', function(error) {
          console.log(error);
        });

        this.voiceTCPlayerObj[`liveLm${item.voiceRecordId}`] = tcplayer;
      },
      // 销毁容器，停止拉流
      disposeDom() {
        const parentElement = document.getElementById('voiceBox');
        // 遍历子元素并获取它们的ID
        const childIds = [];
        
        if (parentElement) {
          parentElement.childNodes.forEach(child => {
            if (child.nodeType === 1) {
              childIds.push(child.id);
            }
          });
        }
        
        this.disposePlaerExecution().then(() => {
          let voiceUserList = this.voiceUserList ? JSON.parse(JSON.stringify(this.voiceUserList)) : [];
          const noHasData = childIds.filter(cItem => !voiceUserList.some(vItem => cItem.voiceRecordId === `liveLm${vItem.voiceRecordId}`));

          if (noHasData.length == 0) return;

          noHasData.forEach(item => {
            let dom = document.getElementById(item);
            if (dom) {
              dom.remove();
            }
          })
        })
      },
      disposePlaerExecution() {
        return new Promise((resolve) => {
          let voiceUserList = this.voiceUserList ? JSON.parse(JSON.stringify(this.voiceUserList)) : [];
          let that = this;
          // 将对象重置为空对象，并且执行方法
          for (let key in this.voiceTCPlayerObj) {
            let index = voiceUserList.findIndex(item => {
              return item.voiceRecordId != -1 && [`liveLm${item.voiceRecordId}`] == key;
            });

            if (index == -1 && that.voiceTCPlayerObj[key]) {
              that.voiceTCPlayerObj[key].dispose && that.voiceTCPlayerObj[key].dispose();
              that.voiceTCPlayerObj[key] = null;
              delete that.voiceTCPlayerObj[key];
            }
          }
    
          resolve();
        });
      },
      // 停掉所有的拉流
      disposeAllPlaer() {
          let that = this;
          // 将对象重置为空对象，并且执行方法
          for (let key in this.voiceTCPlayerObj) {
            if (that.voiceTCPlayerObj[key]) {
              that.voiceTCPlayerObj[key].dispose && that.voiceTCPlayerObj[key].dispose();
              that.voiceTCPlayerObj[key] = null;
            }
          }
      },
      // 麦位点击
      voiceItemClick(item) {
        if (item.roomHide) return;
        if (item.voiceRecordId == -1) {
          this.$store.commit("setState", { isShowDownLoad: true, isInLive: true });
        } else {
          this.goUserCard(item);
        }
      },
      // 接受 同意
      agreeOrReject(flag) {
        if (this.isLoadForLoad) return;
        if (this.lmTimer) clearInterval(this.lmTimer);
        let uid = localStorage.getItem('uid') || utils.guid(),
        time = new Date().getTime(),
        params = {
          os: 0,
          domain: "",
          sign: md5(`${uid}jgyh,kasd${time}`),
          timestamp: time,
          udid: uid,
          voiceRecordId: this.anchorInvitObj.voiceRecordId
        }
        this.$store.commit("setState", { isLoadForLoad: true });
        userRefuse(params).then(res => {
          if (res.code === 0 || res.code === '0') {
            if (flag) {
              this.$store.commit("setState", { isShowDownLoad: true, isInLive: true });
            } 
            this.$store.commit("setState", { anchorInvit: false, anchorInvitObj: {} });
          } else {
            if(res.msg) this.$toast(res.msg)
          }
          this.$store.commit("setState", { isLoadForLoad: false });
        }).catch(() => {
          this.$store.commit("setState", { isLoadForLoad: false });
        })
      },
      // 麦位特效
      initVoiceSVGA(index){
        this[`vplayer${index}`] = new SVGA.Player('#voiceItem0');
        this[`vparser${index}`] = new SVGA.Parser('#voiceItem0');
        this[`vplayer${index}`].loops = 0;
        let that = this;
        // 手动上传
        let svgaAdress = {
          1: 'https://oss-bw18.qtmlive.com/game/game_1711117422000.svga',
          2: 'https://oss-bw18.qtmlive.com/game/game_1711117439000.svga',
          3: 'https://oss-bw18.qtmlive.com/game/game_1711117452000.svga'
        }
        // 必须是服务器地址或者是线上的地址本地是不可以的 会报错
        this[`vparser${index}`].load(svgaAdress[index], (videoItem) => {
            that[`vplayer${index}`].setVideoItem(videoItem);
            that[`vplayer${index}`].clearsAfterStop = true;
            that[`isStartSvgAnimation${index}`] = false;
            // that.vplayer1.startAnimation();
            // that.vplayer1.onFinished(function(){
                
            // });
        })
      },
      // 查询连麦配置
      getVoiceBaseData() {
        let uid = localStorage.getItem('uid') || utils.guid(),
        timestamp = new Date().getTime(),
        sign = md5(`${uid}jgyh,kasd${timestamp}`),
        params = {
          domain: "", //待确认是哪个域名
          liveId: this.liveInfo.liveId,
          os: 0,
          sign: sign,
          timestamp: timestamp,
          udid: uid
        }
        getVoiceBase(params).then(res => {
          if (res.code === 0 || res.code === '0') {
            let liveInfo = JSON.parse(JSON.stringify(this.liveInfo));
            liveInfo.voiceBase = res.data;
            this.$store.commit("setState", { liveInfo });

            if (res.data.isVoice) {
              this.getVoiceUserData();
              this.enterRoom();
            }
          } else {
            if (res.msg) this.$toast(res.msg);
          }
        }).catch(() => {});
      },
      // 查询麦上用户详细信息
      getVoiceUserData() {
        let uid = localStorage.getItem('uid') || utils.guid(), 
        time = new Date().getTime(),
        params = {
          domain: "", 
          liveId: this.liveInfo.liveId,
          os: 0,
          sign: md5(`${uid}jgyh,kasd${time}`),
          timestamp: time,
          udid: uid
        }
        getVoiceUser(params).then(res => {
          if (res.code === 0 || res.code === '0') {
            if (res.data.length > 0) {
              this.$nextTick(() => {
                this.creatDom();
              });
            }

            this.$store.commit("setState", { voiceUserList: res.data });
          } else {
            if (res.msg) this.$toast(res.msg);
          }
        }).catch(() => {});
      },
      // 倒计时
      goDownLmtimer(time) {
        clearInterval(this.lmTimer);
        this.lmTime = time;
        this.lmTimer = setInterval(() => {
          console.log(this);
          if (time > 0) {
            time --;
          } else {
            this.agreeOrReject(0);
          }
          this.lmTime = time;
        }, 1000);
    },
    }
}
export default voice;