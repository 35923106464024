<template>
    <div class="next brokerageRule">
        <div class="title">
            <span> {{$t('my.proxy.commissionRules')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="cont">
            <div class="head">{{$t('my.proxy.descriptionCommissionRatio')}}</div>
            <div class="fontBox">
                <p class="marBt">{{$t('my.proxy.descriptionCommissionRatioCont')}}</p>
                <p>{{$t('my.proxy.activeNumber')}}：{{$t('my.proxy.monthlyDeposit')}}≥ {{ numFormat(detail.activeRecharge) }}</p>
            </div>
            <div class="exchangeData">
                <div class="tableItem tableHead flexBetween">
                    <div>{{$t('my.proxy.level')}}</div>
                    <div>{{$t('my.proxy.companyProfit')}}（{{ $t('my.finance.currency') }}）</div>
                    <div>{{$t('my.proxy.activeNumber')}}</div>
                    <div>{{$t('my.proxy.commissionRate')}}</div>
                </div>
                <div v-for="(item, index) in tableList" :key="`${index}--tableList`" >
                    <div class="tableItem flexBetween">
                        <div>{{item.commissionLevel}}</div>
                        <div>
                            <p>≥{{ numFormat(item.minProfit) }}</p>
                        </div>
                        <div>
                            <span>≥{{item.activeNum}}</span>
                        </div>
                        <div>
                            <p>{{item.commissionPercent}}%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tips">{{$t('my.proxy.noteCont')}}</div>
        </div>

        <div class="cont">
            <div class="head">{{$t('my.proxy.commissionCalculationMethod')}}</div>
            <div class="fontBox">
                <p class="marBt">{{$t('my.proxy.commissionCalculationMethodDetail1')}} <span class="red">{{$t('my.proxy.commissionCalculationMethodDetail11')}}</span></p>
                <p class="marBt">{{$t('my.proxy.commissionCalculationMethodDetail2')}}</p>
                <p class="marBt">{{$t('my.proxy.commissionCalculationMethodDetail31')}}{{ detail.baseFeePercent }}%+{{$t('my.proxy.commissionCalculationMethodDetail32')}}{{ detail.userBonusPercent }}%+{{$t('my.proxy.commissionCalculationMethodDetail33')}}{{ detail.serviceFeePercent }}%，{{$t('my.proxy.commissionCalculationMethodDetail34')}}</p>
                <p class="marBt">{{$t('my.proxy.commissionCalculationMethodDetail4')}}</p>
                <p class="marBt">{{$t('my.proxy.commissionCalculationMethodDetail5')}}</p>
                <p class="marBt">{{$t('my.proxy.commissionCalculationMethodDetail6')}}</p>
            </div>
        </div>

        <div class="cont">
            <div class="head">{{$t('my.proxy.SubAgentCommission')}}</div>
            <div class="fontBox">
                <p class="marBt">{{$t('my.proxy.SubAgentCommissionDetail1')}}</p>
                <p class="marBt">{{$t('my.proxy.SubAgentCommissionDetail2')}} <span class="red">{{detail.parentFeePercent}}%</span> {{$t('my.proxy.SubAgentCommissionDetail3')}} <span class="red">{{detail.grandFeePercent}}%</span> {{$t('my.proxy.SubAgentCommissionDetail4')}}</p>
            </div>
            <div class="fontBox">
                <p class="suHead">{{$t('my.proxy.SubAgentCommissionSuHead')}}</p>
                <p>{{$t('my.proxy.SubAgentCommissionSuDetail1')}}{{ numFormat(oneProxyeCommission) }}</p>
                <p>{{$t('my.proxy.SubAgentCommissionSuDetail2')}}{{detail.parentFeePercent}}%*{{ numFormat(oneProxyeCommission) }}={{ numFormat(oneCommission) }}</p>
                <p>{{$t('my.proxy.SubAgentCommissionSuDetail3')}}{{ numFormat(twoProxyeCommission) }}</p>
                <p>{{$t('my.proxy.SubAgentCommissionSuDetail4')}} {{detail.grandFeePercent}}%*{{ numFormat(twoProxyeCommission) }}={{ numFormat(twoCommission) }}</p>
                <!-- 1,000+100=1,100 -->
                <p class="marBt">{{$t('my.proxy.SubAgentCommissionSuDetail5')}} {{ numFormat(oneCommission) }} + {{ numFormat(twoCommission) }} = {{ numFormat(totalCommission) }}</p>
            </div>
        </div>

        <div class="cont">
            <div class="head">{{$t('my.proxy.commissionPayment')}}</div>
            <div class="fontBox">
                <div class="marBt">
                    <p class="suHead">{{$t('my.proxy.paymentTime')}}：</p>
                    <p>{{$t('my.proxy.paymentTimeExplain')}}</p>
                </div>
                <div class="marBt">
                    <p class="suHead">{{$t('my.proxy.paymentMethod')}}：</p>
                    <p>{{$t('my.proxy.paymentMethodExplain')}}</p>
                </div>
                <div class="marBt">
                    <p class="suHead">{{$t('my.proxy.ruleNetloss')}}：</p>
                    <p>{{$t('my.proxy.ruleNetlossExplain')}}</p>
                </div>
            </div>
            <div class="tips">{{$t('my.proxy.commissionPaymentWarn')}}</div>
        </div>
    </div>
</template>

<script>
import { queryProxyCommissionConfig } from "@/api/proxy";
// import utils from "@/utils/index";
export default {
    data() {
        return {
            tableList: [],
            money: "",
            detail: {},
            oneProxyeCommission: 10000,
            twoProxyeCommission: 10000,
        }
    },
    computed: {
        oneCommission() {
            let num = 0;
            if (this.detail.parentFeePercent) {
               num = this.numFormat(this.detail.parentFeePercent / 100 * this.oneProxyeCommission)
            }
            return num;
        },
        twoCommission() {
            let num = 0;
            if (this.detail.grandFeePercent) {
               num = this.numFormat(this.detail.grandFeePercent / 100 * this.twoProxyeCommission)
            }
            return num;
        },
        totalCommission() {
            let oneCommission = this.oneCommission.toString().replace(/[`,`]/g,'');
            let twoCommission = this.twoCommission.toString().replace(/[`,`]/g,'');
            return this.numFormat(parseFloat(oneCommission) + parseFloat(twoCommission))
        }
    },
    created() {
        this.getList();
    },
    methods: {
        gaBack() {
            this.$router.push(`/mine/myProxy`);
        },
        // 获取月份
        getList() {
            this.tableList = [];
            queryProxyCommissionConfig().then(res => {
                if (res.code == 0) {
                    this.tableList = res.data.commissionPercentList;
                    this.money = res.data.activeRecharge;
                    this.detail = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.brokerageRule {
    padding-top: 1rem;
    text-align: left;

    .title {
        height: auto;
    }

    .cont {
        background-color: #fff;
        margin-bottom: 0.2rem;
        .head {
            line-height: 0.88rem;
            color: #333;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
        }

        .fontBox {
            padding: 0.2rem 0.28rem;
            color: #788597;

            .marBt {
                margin-bottom: 0.4rem;
            }

            .red {
                color: #C41F1A;
            }

            .suHead {
                font-size: 0.28rem;
                color: #333;
            }

            p {
                margin-bottom: 0.1rem;
            }
        }

        .tips {
            padding: 0.2rem 0.28rem 0.4rem;
            font-size: 0.24rem;
            color: #C41F1A;
        }
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            &.tableHead {
                color: #333;
                font-size: 0.2rem;
                background-color: #C8CBD7;
            }

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            &:first-child {
                border-bottom: none;
            }

            div {
                flex: 1;
                text-align: left;
                font-size: 0.24rem;
                padding-right: 0.1em;

                &:nth-child(2) {
                    flex: 2;
                }

                i {
                    color: #788597;
                    margin-left: 0.1rem;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .blue {
            color: #eb457e;
        }
    }
}
</style>