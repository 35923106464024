<template>
    <div class="next recordTransacitonDetail">
      <div class="title">
        <span> {{$t('lottery.detail.betDetail')}}</span>
        <div class="fixed" @click="goBack">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
      </div>
  
      <div class="orderDetail">
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.gameName')}}</div>
            <div class="right">{{orderDetail.nickName}}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.issue')}}</div>
            <div class="right flexCenter">
                {{orderDetail.expect}} 
                <img @click="copyClick(orderDetail.expect)" class="copy" src="../../assets/img/copy.png" />
            </div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.betAmount')}}</div>
            <div class="right">{{ numFormat(orderDetail.betAmount) }}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.betMultiple')}}</div>
            <div class="right">{{orderDetail.times}}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.betTime')}}</div>
            <div class="right">{{getTime(orderDetail.createTime)}}</div>
        </div>

        <div class="orderItem flexBetween" :class="{'smallItem': orderDetail.playNumReq.type_text && orderDetail.playNumReq.type_text.length > 0 && !isNaN(orderDetail.playNumReq.type_text[0]), 'seItem': orderDetail.lotteryName == 'sd'&& !isNaN(orderDetail.playNumReq.type_text[0])}">
            <div class="left">{{$t('lottery.detail.betCont')}}</div> 
            
            <!-- 一分快三 鱼虾蟹 -->
            <div v-if="orderDetail.lotteryName == 'yuxx' || orderDetail.lotteryName == 'jsks' || orderDetail.lotteryName == 'sd'">
                <div class="right" v-if="orderDetail.playNumReq.type_text && orderDetail.playNumReq.type_text.length > 0 && orderDetail.playNumReq.type_text.length <= 4">
                    <span v-for="(xitem, index) in orderDetail.playNumReq.type_text" :key="`${index}--orderDetail.playNumReq.type_text`">
                        <span v-if="isNaN(xitem)">{{xitem}}</span>
                        <img v-else class="gamesIcon" v-real-img="require(`../../assets/img/lottery/${orderDetail.lotteryName}/${xitem}.png`)" />
                    </span>
                </div>

                <div class="right" v-if="orderDetail.playNumReq.type_text && orderDetail.playNumReq.type_text.length > 4 && orderDetail.lotteryName == 'sd'">
                    <div>
                        <span v-for="(xitem, index) in orderDetail.playNumReq.type_text" :key="`${index}--orderDetail.playNumReq.type_text`">
                            <img v-show="index<=3" class="gamesIcon" v-real-img="require(`../../assets/img/lottery/${orderDetail.lotteryName}/${xitem}.png`)" />
                        </span>
                    </div>
                    <div>
                        <span v-for="(xitem, index) in orderDetail.playNumReq.type_text" :key="`${index}--orderDetail.playNumReq.type_text`">
                            <img v-show="index>3" class="gamesIcon" v-real-img="require(`../../assets/img/lottery/${orderDetail.lotteryName}/${xitem}.png`)" />
                        </span>
                    </div>
                </div>
            </div>
            
            <!-- 其他 -->
            <div v-else>
                <div class="right" v-if="orderDetail.playNumReq.type_text && orderDetail.playNumReq.type_text.length > 0 && orderDetail.playNumReq.type_text.length <= 4">
                    <span v-for="(xitem, index) in orderDetail.playNumReq.type_text" :key="`${index}--orderDetail.playNumReq.type_text`">
                        <span>{{xitem}}</span>
                    </span>
                </div>
            </div>
        </div>

        <!-- 开奖结果 -->
        <div v-if="orderDetail.lotteryName == 'sd' || orderDetail.lotteryName == 'yuxx' || orderDetail.lotteryName == 'jsks'">
            <div class="orderItem flexBetween smallItem" :class="{'seItem': orderDetail.lotteryName == 'sd'&& !isNaN(orderDetail.playNumReq.type_text[0])}">
                <div class="left">{{$t('lottery.detail.lotteryResults')}}</div>
                <div class="right" v-if="orderDetail.resultList && orderDetail.resultList.length > 0">
                    <img class="gamesIcon" v-for="(xitem, index) in orderDetail.resultList" :key="`${index}--orderDetail.resultList`" v-real-img="require(`../../assets/img/lottery/${orderDetail.lotteryName}/${xitem}.png`)" />
                </div>
            </div>
        </div>

        <div v-else-if="orderDetail.lotteryName == 'pk10' || orderDetail.lotteryName == 'txssc'">
            <div class="orderItem flexBetween smallItem">
                <div class="left">{{$t('lottery.detail.lotteryResults')}}</div>
                <div class="right" v-if="orderDetail.resultList && orderDetail.resultList.length > 0">
                    <span class="gameItem" v-for="(xitem, index) in orderDetail.resultList" :key="`${index}--orderDetail.resultList`">{{xitem}}</span>
                </div>
            </div>
        </div>

        <div v-else-if="orderDetail.lotteryName == 'yflhc'">
            <div class="orderItem flexBetween smallItem">
                <div class="left">{{$t('lottery.detail.lotteryResults')}}</div>
                <div class="right" v-if="orderDetail.resultList && orderDetail.resultList.length > 0">
                    <span class="gameItem" v-for="(xitem, xindex) in orderDetail.resultList" :key="`${xindex}--orderDetail.resultList-yflhc`" v-show="xindex < 6"> {{xitem}}</span>
                    <van-icon name="plus" />
                    <span class="gameItem" v-show="orderDetail.resultList[7] == 1" style="background-color:#f90007;"> {{orderDetail.resultList[6]}}</span> 
                    <span class="gameItem" v-show="orderDetail.resultList[7] == 2" style="background-color:#04f906;"> {{orderDetail.resultList[6]}}</span>
                    <span class="gameItem" v-show="orderDetail.resultList[7] == 3" style="background-color:#2a00f2;"> {{orderDetail.resultList[6]}}</span>
                </div>
            </div>
        </div>
        
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.status')}}</div>
            <div class="right">
                <span v-show="!orderDetail.awardStatus" style="color:#788597;">{{$t('lottery.status.wait')}}</span>
                <span v-show="orderDetail.awardStatus == 1" style="color:#C41F1A;">{{$t('lottery.status.lose')}}</span>
                <span v-show="orderDetail.awardStatus == 2" style="color:#00B82C;">{{$t('lottery.status.win')}}</span>
                <span v-show="orderDetail.awardStatus == 3" style="color:#2E8CF0;">{{$t('lottery.status.tie')}}</span>
            </div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.bonusAmount')}}</div>
            <div class="right" :style="{color: orderDetail.realProfitAmount ? '#00B82C' : '#788597'}">{{orderDetail.realProfitAmount ? numFormat(orderDetail.realProfitAmount) : "0"}}</div>
        </div>
        <div class="orderItem flexBetween">
            <div class="left">{{$t('lottery.detail.bonusTime')}}</div>
            <div class="right">{{getTime(orderDetail.updateTime)}}</div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment';
import utils from "@/utils/index";
export default {
    name: "lotteryBetDetail",
    data() {
        return {
            type: ""
        }
    },
    created() {
        this.type = this.$route.params.type;
        let obj = JSON.parse(sessionStorage.getItem('orderDetail'));
        obj.playNumReq.type_text = obj.playNumReq.num.split(',');
        this.orderDetail = obj;
    },
    methods: {
        goBack() {
            sessionStorage.setItem('showRecord', true);
            this.$router.push(`/lottery/${this.type}`);
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm:ss DD-MM-YYYY');
            }
            return "";
        }
    },
    beforeUnmount() {
        // 离开清空数据 没起效
        this.$store.commit("setState", { betDetail: {} });
    }
}
</script>
  
<style lang="scss" scoped>
  .recordTransacitonDetail {
    box-shadow: 0 0 0 0 #fff;
    padding-top: 0.88rem;
    .title_ellipsis {
        color: #eb457e;
    }
    .orderDetail {
        background-color: #fff;
        color: #333;
        font-size: 0.27rem;

        .orderItem {
            padding: 0.2rem 0.28rem;
            border-bottom: 1px solid #E5E5E5;

            &.smallItem {
                padding: 0.1rem 0.28rem;
            }

            &.seItem {
                img.gamesIcon {
                    width: 0.5rem;
                }
            }

            .gameItem {
                display: inline-block;
                width: 0.3rem;
                height: 0.35rem;
                background-color: #eb457e;
                line-height: 0.37rem;
                color: #fff;
                margin-right: 0.05rem;
                font-size: 0.24rem;
            }

            &.noBorder {
                border-color: #fff;
            }
            .left {
                color: #626872;
            }

            img {
                width: 0.31rem;
                margin-left: 0.15rem;

                &.gamesIcon {
                    width: 0.6rem;
                    margin-left: 0.2rem;
                }
            }

            .right {
                .isAudit {
                    color: #6aaaf5;
                }
                .isSuccess {
                    color: #00B887;
                }
                .isFail {
                    color: #C41F1A;
                }
            }
        }
    }
  }
</style>
