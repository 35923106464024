<template>
  <div class="customer">
    <div class="head">
        <div class="leftIcon"  @click="goback">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
        {{ cserver && cserver.nickname ? cserver.nickname : "在线客服" }}
    </div>

    <div class="content">
        <div v-if="cserver && cserver.url">
            <iframe style="border: medium none;" :src="cserver.url"></iframe>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "customerSeviceList",
    computed: {
        cserver() {
            return this.$store.state.cserver
        },
    },
    methods: {
        goback() {
            this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>
.customer {
    .head {
        font-size: .32rem;
        text-align: center;
        height: 0.88rem;
        line-height: .88rem;
        background-color: #fff;
        color: #414655;
        position: relative;

        .leftIcon {
            height: 0.88rem;
            line-height: 0.88rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 0.88rem;
        }
    }

    iframe {
        width: 100vw;
        height: calc(100vh - 0.88rem);
    }

    .jiange {
        height: 10px;
        background-color: #fafafa;
    }
}
</style>