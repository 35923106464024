<template>
    <div class="mall next">
        <div class="title">
            <span> {{ $t('my.mall.title') }} </span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <ul class="listView">
            <li v-for="(item, index) in list" :key="`${index}--list-mall`" @click="itemClick(item)" v-show="!item.flag">
                <div class="left">
                    <img @click.stop="initMachineSVGA(item)" v-real-img="item.cover" />
                    <img @click.stop="initMachineSVGA(item)" class="playIcon" src="../../assets/img/my/play.png" />
                    <span>{{ item.gname }}</span>
                </div>
                <div class="right">
                    {{ $t('my.menuList.mall') }}
                </div>
            </li>
        </ul>

        <van-popup v-model:show="showBuyInfo" position="bottom">
            <div class="buyInfoBox">
                <div class="buyInfoHead">{{ $t('my.menuList.mall') }} <span>{{ activeObj.gname }}</span></div>
                <div class="buyInfo">
                    <div v-for="(item, index) in buyInfo" :key="`${index}-buyInfo-mall`"
                        :class="{ 'active': activeIndex == index }" @click="activeIndex = index">
                        <p>{{ item.day }} {{ $t('my.mall.day') }}</p>
                        <p v-if="activeObj.goldCoin">{{ Math.round(item.day * activeObj.goldCoin*item.rate) }} {{ $t("my.finance.currency") }}</p>
                    </div>
                </div>
                <div @click="buy" class="confirmBtn hasValue">{{ $t('my.mall.toPay') }}</div>
            </div>
        </van-popup>

        <div v-show="showAnimation" id="demoCanvas">
            <van-icon @click="stopAnimation" name="close" />
        </div>
    </div>
</template>
  
<script>
import utils from "@/utils/index";
import { configProp } from "@/api/config";
import { buyCar } from "@/api/index";
import SVGA from "svgaplayerweb";
export default {
    data() {
        return {
            languageActive: 0,
            currencyArr: [], //货币列表
            currencyActive: 0,
            list: [],
            showBuyInfo: false,
            activeObj: {},
            // buyInfo: [7, 30, 90, 180],
            buyInfo: [
                {
                    day: 7,
                    rate: 1
                },
                {
                    day: 30,
                    rate: 0.9
                },
                {
                    day: 90,
                    rate: 0.8
                },
                {
                    day: 180,
                    rate: 0.7
                },
            ],
            activeIndex: 0,
            player: null,
            showAnimation: false
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    created() {
        this.configProp();
    },
    methods: {
        initMachineSVGA(item){
            let that = this;
            this.showAnimation = true;
            this.player = new SVGA.Player('#demoCanvas');
            this.parser = new SVGA.Parser('#demoCanvas');
            this.player.loops ++;
            // 必须是服务器地址或者是线上的地址本地是不可以的 会报错
            this.parser.load(`${item.resourceUrl}`, (videoItem) => {
                that.player.setVideoItem(videoItem);
                that.player.startAnimation();
                that.player.onFinished(function(){
                    that.showAnimation = false;
                });
            }, err => {
                console.log(err);
                this.showAnimation = false;
            })
        },
        stopAnimation(){
            this.player.stopAnimation();
            this.showAnimation = false;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        itemClick(item) {
            this.activeObj = JSON.parse(JSON.stringify(item));
            this.showBuyInfo = true;
        },
        // 座驾列表
        configProp() {
            configProp(this.member.uid).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let arr = res.data.filter((item) => {
                        return item.type == 1 && item.isShow
                    });

                    this.list = arr.sort(function (a, b) {
                        return (a.sort - b.sort);
                    });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 购买
        buy() {
            let seif = this;
            this.$dialog({
                message: `${ this.$t('my.mall.confirmBuy')} ${this.buyInfo[this.activeIndex].day} ${ this.$t('my.mall.day')} ${this.activeObj.gname}`,
                className: "confirmDialog",
                confirmButtonText: this.$t('common.confirm'),
                showCancelButton: true,
                cancelButtonText: this.$t('common.cancel'),
                confirmButtonColor: "#eb457e"
            }).then(() => {
                seif.buyCar();
            }).catch(() => {
                console.log("取消");
            });
        },
        buyCar() {
            let param = {
                uid: this.member.uid,
                gid: this.activeObj.gid,
                days: this.buyInfo[this.activeIndex].day
            }
            buyCar(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('my.mall.buySuccess'));
                    this.showBuyInfo = false;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>
  
<style lang="scss" scoped>
.mall {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    background-color: #f9f9f9;

    .listView {
        li {
            text-align: center;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding-right: 0.28rem;
            background-color: #fff;
            height: 1.48rem;
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0.28rem;
                bottom: 0;
                border-bottom: 1px solid #eee;
                -webkit-transform: scaleY(.5);
                transform: scaleY(.5);
            }

            &:last-child::after {
                border-bottom: 1px solid #fff;
            }

            .left {
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                margin-left: 0.28rem;
                position: relative;

                .playIcon {
                    width: 0.48rem;
                    height: 0.48rem;
                    position: absolute;
                    left: 0.4rem;
                }

                img {
                    width: 1.28rem;
                    height: 1.28rem;
                }

                span {
                    font-size: .28rem;
                    color: #333;
                    margin-left: 0.2rem;
                }
            }

            .right {
                padding: 0.1rem 0.44rem;
                background: #fff;
                // background: -webkit-linear-gradient(left, #f86fab, #fa8faf);
                // background: linear-gradient(90deg, #f86fab, #fa8faf);
                border-radius: 0.4rem;
                color: #f86fab;
                border: 1px solid #f86fab;
            }
        }
    }

    .currencyLanguage {
        background-color: #fff;

        .cardsTab {
            padding: 0.28rem;
            background: #fff;

            ul {
                -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
                background: #EBEFF1;
                border-radius: 0.16rem;
                padding: 0.02rem;

                li {
                    flex: 1 1;
                    text-align: center;
                    line-height: 0.58rem;
                    height: 0.58rem;
                    color: #bbb;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.27rem;
                    margin: 0.02rem;

                    &.active {
                        background: #fff;
                        border-radius: 0.16rem;
                        color: #333333;
                    }
                }
            }
        }

        .bankListCont {
            margin-bottom: 0.3rem;

            .bankListCont_item {
                padding: 0.35rem 0;
                margin: 0 0.28rem;
                border-bottom: 1px solid #EBEFF1;

                .bankListCont_item_left {
                    text-align: left;
                    color: #333;
                    font-size: 0.28rem;
                }

                img {
                    width: 0.4rem;
                }
            }
        }
    }

    .buyInfoBox {
        padding: 0.28rem;

        .buyInfoHead {
            margin-bottom: 0.4rem;
            font-size: 0.34rem;
            color: #333;

            span {
                color: #eb457e;
            }
        }

        .buyInfo {
            margin-bottom: 0.2rem;

            div {
                width: calc(50% - 0.2rem);
                display: inline-block;
                padding: 0.16rem 0;
                border: 1px solid #969696;
                margin-bottom: 0.2rem;
                margin-right: 0.2rem;
                border-radius: 0.2rem;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &.active {
                    color: #eb457e;
                    border-color: #eb457e;
                }
            }
        }

        .confirmBtn {
            width: 5rem;
            margin: 0 auto;
        }
    }

    #demoCanvas {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 10;

        i {
            position: absolute;
            top: 0.88rem;
            right: 0.28rem;
            font-size: 0.5rem;
            color: #fff;
            z-index: 66;
        }
    }
}
</style>