<template>
    <div class="next mailDetail">
      <div class="title">
        <span>{{ mailDetailTitle }}</span>
        <div class="fixed" @click="goOtherPage('/mine/mail')">
          <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
      </div>


      <div v-for="(item, index) in detailDataList" :key="`${index} -- detailDataList`" class="cont">
        <div class="time">{{ getTime(item.createTime ? item.createTime : item.timestamp) }}</div>
        <div class="messageBox">
          <img v-show="!item.type" src="../../assets/img/my/mail/backstage.png" />
          <img v-show="item.type" src="../../assets/img/my/mail/automatic.png">

          <div class="messageBody">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import { mapState } from 'vuex';
  export default {
      data() {
        return {
          detailDataList: [],
          type: 1
        }
      },
      computed: {
        ...mapState(['systemLetterLists']),
        mailDetailTitle() {
          let str = "";
          if (this.detailDataList.length > 0) {
            if (!this.detailDataList[0].type) {
              str = `QQlive` + this.$t('my.mail.title');
            }

            if (this.detailDataList[0].type) {
              str = this.$t('my.mail.systemNotice');
            }
          }

          return str;
        }
      },
      created() {
        if (sessionStorage.getItem('mailDetail')) {
          this.detailDataList = JSON.parse(sessionStorage.getItem('mailDetail'));
          let systemLetterLists = this.systemLetterLists.length > 0 ? JSON.parse(JSON.stringify(this.systemLetterLists)) : [];

          if (this.detailDataList.length > 0 && this.detailDataList[0].type) {
            systemLetterLists.forEach(item => {
              if (!item.isUnreadMsg) {
                item.isUnreadMsg = 1
              }
            });
            this.$store.commit("setState", { systemLetterLists  });
          }
        }
      },
      methods: {
        goOtherPage() {
          this.$router.push('/mine/mail');
        },
        getTime(val) {
          if (val) {
            return moment(val).format('HH:mm DD-MM-YYYY');
          }

          return "";
        },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .mailDetail {
    background-color: #f9f9f9;
    font-size: 0.28rem;
    color: #333;
    height: auto;
    padding-bottom: 0.2rem;

    .cont {
      padding: 0 0.28rem;


      .time {
        display: inline-block;
        margin: 0.4rem auto 0.12rem;
        background-color: #c4c4c4;
        color: #fff;
        font-size: 0.2rem;
        padding: 0.01rem 0.1rem;
        border-radius: 0.05rem;
      }

      .messageBox {
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
      
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -moz-box-pack: flex-start;
        -webkit-moz-box-pack: flex-start;
        box-pack: flex-start;

        img {
          width: 0.98rem;
          height: 0.98rem;
          object-fit: cover;
        }
        .messageBody {
          position: relative;
          display: table;
          max-width: calc(100vw - 1.9rem);
          padding:3px 6px;
          line-height: 0.6rem;
          border-radius: 4px;
          background-color: #fff;
          overflow-wrap: anywhere;
          font-size: 8px;
          border: 1px solid #e5e5e5;
          margin-left: 18px;
          min-height: 1.08rem;
          text-align: left;

          &::before, &::after {
            content: "";
            width: 0;
            height: 0;
            font-size: 0;
            overflow: hidden;
            display: block;
            border-width: 6px 10px;
            border-color: transparent #e5e5e5 transparent transparent;
            border-style: dashed solid dashed dashed;
            position: absolute;
            top: 0.2rem;
            left: -20px;
          }

          &::after {
            border-color: transparent #fff transparent transparent;
            border-style: dashed solid dashed dashed;
            position: absolute;
            top: 0.2rem;
            left: -19px;
          }
        }
      }
    }
  }
  </style>