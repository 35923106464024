import request from "../utils/requestConfig";
// 界面基础信息
export const systemConfig = () => {
    return request.get(`/config-client/base/system/configNew`);
};
// export const systemConfig = () => {
//     return request.get(`/config-client/base/system/config`);
// };

// 基础信息
export const getBaseInfo = () => {
    return request.get(`/config-client/base/baseInfo/encrypt?`);
};

// 首页轮播图
export const advert = () => {
    return request.get(`/config-client/config/advert`);
};

// 首页的热门彩票接口 
export const hotList = (params = {}) => {
    return request.get(`/config-client/config/cp/hot/list`, {
        ...params
    });
};

// // 首页左侧菜单栏
// export const gamePlatforms = (uid , label=5 ) => {
//     return request.get(`/center-client/center/game/gamePlatforms?label=${label}&uid=${uid}`);
// };

//客服 
export const cserver = () => {
    return request.get(`/config-client/config/cserver`);
};

//  优惠活动列表
export const baseUserActivity = (type) => {
    return request.get(`/config-client/base/userActivity?type=${type}`);
};

// 系统公告 
export const systemNotice = () => {
    return request.get(`/config-client/config/system/notice`);
};


//道贵族列表 
export const configVip = () => {
    return request.get(`/config-client/config/vip`);
};

// 道具列表
export const configProp = () => {
    return request.get(`/config-client/config/prop`);
};

// 主播标签
export const configTag = (uid) => {
    return request.get(`/config-client/config/tag?uid=${uid}`);
};

// 银行列表 
export const userBankList = (params = {}) => {
    return request.post(`/config-client/base/userBankList`, {
        ...params
    });
};

// 获取彩票列表
export const cpList = (params = {}) => {
    return request.get(`/config-client/config/cp/listNew`, {
        ...params
    });
};

// 获取徽章列表
export const configBadge = () => {
    return request.get(`/config-client/config/badge`);
};

// APP弹窗公告
export const noticeNew = (uid) => {
    return request.get(`/config-client/config/noticeNew?uid=${uid}`);
};

// 排行榜配置
export const configRank = () => {
    return request.get(`/config-client/config/rank`);
};

// 等级特效列表
export const levelProp = () => {
    return request.get(`/config-client/config/level/prop`);
};

// 获取默认参数
export const getChannel = (domain) => {
    return request.get(`/config-client/base/getChannel?domain=${domain}`);
};