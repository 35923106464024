<template>
  <div class="next betRecord">
    <div class="title">
      <span> {{$t('my.listView.gameReport')}} </span>
      <div class="fixed" @click="gaBack">
        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
      </div>
      <div class="title_ellipsis betSearchBox">
        <!-- <van-dropdown-menu class="betSearch">
         <van-dropdown-item active-color="#6996FF" :title="typeList[searchTypeActive].chinese" ref="searchItemType">
            <div class="searchItem">
              <div class="searchItem_item">
                <span v-for="(item, index) in typeList" :key="`${index}--typeList`" :class="searchTypeActive==index ? 'searchItem_item_active' : ''" @click="onConfirmType(index)">
                  {{ item.chinese }}
                </span>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu> -->
        <!-- <van-dropdown-menu class="betSearch">
         <van-dropdown-item active-color="#6996FF" :title="status[searchStatusActive].name" ref="searchItemStstus">
            <div class="searchItem">
              <div class="searchItem_item">
                <span v-for="(item, index) in status" :key="`${index}--status`" :class="searchStatusActive==index ? 'searchItem_item_active' : ''" @click="onConfirmStatus(index)">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu> -->
        <van-dropdown-menu active-color="#fff" class="betSearch">
          <van-dropdown-item ref="searchItemDay">
            <template v-slot:title>
              <span v-if="searchDayActive!=5">{{dayList[searchDayActive].name}}</span>
              <div v-else class="seatchTitle">
                <p>{{ getDate(currentStartDate) }}</p>
                <p>{{ getDate(currentEndDate) }}</p>
              </div>
            </template>
            <div class="searchItem">
              <div class="searchItem_item">
                <span v-for="(item, index) in dayList" :key="`${index}--dayList`" :class="searchDayActive==index ? 'searchItem_item_active' : ''" @click="onConfirm(index)">
                  {{ item.name }}
                </span>
              </div>
            </div>

            <div v-show="searchDayActive==5">
              <div class="customizeSearch borderBottom">
                <div @click="showStartDate=!showStartDate,showEndDate=false" class="customizeSearchItem flexBetween">
                  <div>{{$t('my.proxy.startDate')}}</div>
                  <div>{{ getDate(currentStartDate) }} <van-icon name="arrow" /></div>
                </div>

                <div v-show="showStartDate">
                  <van-datetime-picker
                    item-height="0.6rem"
                    v-model="currentStartDate"
                    :show-toolbar="false"
                    type="date"
                    :title="`${'my.proxy.selectDateTips'}`"
                    :min-date="minDate"
                  />
                </div>
              </div>

              <div class="customizeSearch">
                <div @click="showEndDate=!showEndDate,showStartDate=false" class="customizeSearchItem flexBetween">
                  <div>{{$t('my.proxy.endDate')}}</div>
                  <div>{{ getDate(currentEndDate) }} <van-icon name="arrow" /></div>
                </div>

                <div v-show="showEndDate">
                  <van-datetime-picker
                    item-height="0.6rem"
                    v-model="currentEndDate"
                    :show-toolbar="false"
                    type="date"
                    :title="`${'my.proxy.selectDateTips'}`"
                    :min-date="minDate"
                  />
                </div>
              </div>

              <div class="customizeBtnBox">
                <div @click="cancelSearch" class="customizeBtnCancel">{{$t('common.cancel')}}</div>
                <div @click="goSearch" class="customizeBtnConfirm">{{$t('common.sure')}}</div>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>

    <div class="recordList">
      <div v-if="betList.length>0">
        <van-pull-refresh v-model="isLoading" :disabled="isRefreshDisable" @refresh="onRefresh">
          <template #pulling>
            <van-loading />
          </template>

          <!-- 释放提示 -->
          <template #loosing>
            <van-loading />
          </template>

          <!-- 加载提示 -->
          <template #loading>
            <van-loading />
          </template>
          <van-list
              class="vanList"
              v-model="loading"
              :finished="finished"
              style="height:calc(100vh - 2.2rem);overflow:scroll;"
              :offset="10" 
              :immediate-check="check" 
              :finish-text="`${$t('lottery.noMore')}`"
              @load="onLoad" 
            >
            <div v-for="(item, index) in betList" :key="`${index}--betList`" @click="goDetail(item)" class="recordList_item">
              <div class="recordList_item_head flexBetween">
                <div class="gameName flexCenter">
                  <img v-real-img="item.lotteryIcon" />
                  <p>{{item.nickName}}</p>
                </div>
                <div class="more flexCenter">
                  {{ $t('common.more') }}
                  <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5184" width="0.24rem" height="0.24rem"><path d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z" p-id="5185" fill="#bbbbbb"></path></svg>
                </div>
              </div>
              <div class="recordList_item_cont">
                <div class="flexBetween betList_list_content">
                  <div class="content_item">
                    <div>{{ $t('lottery.detail.betNum') }}</div>
                    <div>{{ numFormat(digitalNumber(item.lotteryCount)) }}</div>
                  </div>

                  <div class="content_item">
                    <div>{{ $t('lottery.detail.betAmount') }}</div>
                    <div>{{ numFormat(digitalNumber(item.betAmountAll)) }}</div>
                  </div>

                  <div class="content_item">
                    <div>{{ $t('lottery.detail.winningAmount') }}</div>
                    <div :class="{'green': item.profitAmountAll > 0 , 'red': item.profitAmountAll < 0}">{{item.profitAmountAll ? numFormat(digitalNumber(item.profitAmountAll)) : '0' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
        
      </div>
      <div v-else class="noData">
        <img src="../../assets/img/record/noData.png"/>
        <p>{{$t('common.noData')}}</p>
      </div>
    </div>

    <div v-if="betList.length>0" class="betRecord_top flexBetween">
      <div class="betRecord_top_item">
        <div>{{ $t('lottery.detail.totalBetNum') }}</div>
        <div>{{ numFormat(digitalNumber(betTotal.totalBet)) }}</div>
      </div>
      <div class="betRecord_top_item">
        <div>{{ $t('lottery.detail.totalWinningAmount') }}</div>
        <div>{{ numFormat(digitalNumber(betTotal.totalProfit)) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLotteryDetailsHistory, getLotteryTypeHistory } from "@/api/index";
import moment from "moment";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      searchDayActive: 0, //时间选中
      // searchTypeActive: 0, //彩票名称选中
      // searchStatusActive: 0, //状态选中
      dayList: [ // 时间条件列表
        {
          type: 0,
          name: this.$t('record.dayList.today')
        },
        {
          type: 1,
          name: this.$t('record.dayList.yesterday')
        },
        {
          type: 2,
          name: this.$t('record.dayList.last3Days')
        },
        {
          type: 3,
          name: this.$t('record.dayList.last7Days')
        },
        {
          type: 4,
          name: this.$t('record.dayList.last30Days')
        },
      ],
      // typeList: [], // 彩票名称条件列表
      status: [ // 状态条件列表  查询类型：0 ：全部 1:未开奖 2:未中奖 3：已中奖
        {
          name: this.$t('lottery.status.all'),
          type: 0
        },
        {
          name: this.$t('lottery.status.wait'),
          type: 1
        },
        {
          name: this.$t('lottery.status.lose'),
          type: 2
        },
        {
          name: this.$t('lottery.status.win'),
          type: 3
        },
        {
          name: this.$t('lottery.status.tie'),
          type: 4
        }
      ],
      isLoading: false,
      betList: [], //列表
      betTotal: {
        totalBet: "0",
        totalProfit: "0"
      },
      minDate: new Date(),
      maxDate: new Date(),
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      showStartDate: false,
      showEndDate: false,
      finished: true, //是否已加载完
      page: 0, //页码
      loading: false, //下拉加载更多加载中动画
      check: false, //是否开始就检查
      // 滚动与下拉冲突
      scrollTop: 0,
      isRefreshDisable: false
    };
  },
  watch: {
    scrollTop(newval) {
      if (newval > 0) {
        this.isRefreshDisable = true
      } else {
        this.isRefreshDisable = false
      }
    }
  },
  computed: {
    ...mapState(['member', 'betPramas', 'baseInfo']),
  },
  created() {
    if (this.betPramas && JSON.stringify(this.betPramas) != '{}' && (this.betPramas.searchDayActive || (!this.betPramas.searchDayActive && String(this.betPramas.searchDayActive)) === '0')) {
      this.searchDayActive = this.betPramas.searchDayActive;
      this.$store.commit("setState", { betPramas: {} });
    }
    this.getLotteryDetailsHistory();
  },
  mounted() {
    // 解决滚动条与下拉的冲突
    // this.$nextTick(() => {
    //   const vantList = document.querySelector('.van-list')
    //   this.resolveConflict(vantList)
    // })
  },
  methods: {
    /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
     resolveConflict(val) {
      const vantList = val
      vantList.addEventListener('touchmove', () => {
        this.scrollTop = vantList.scrollTop
      })
      vantList.addEventListener('touchend', () => {
        this.scrollTop = vantList.scrollTop
      })
    },
    formatDate(val) {
      if (val) {
        return moment(val).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return "";
      }
    },
    // 去往详情界面 
    goDetail(item) {
      let betPramas = {
        searchDayActive: this.searchDayActive,
        lotteryName: item.lotteryName
      }
      this.$store.commit("setState", { betPramas });
      // 下一层跳转的地址
      this.$router.push('/record/gameReport/next');
    },
    // 加载更多
    onLoad() {
      if (!this.finished) {
        this.loading = true;
        this.page ++;
        this.getLotteryDetailsHistory();
      }
    },
    // 获取列表
    getLotteryDetailsHistory(refresh=false) {
      let params = {
        uid: this.member.uid,
        type: this.dayList[this.searchDayActive].type,
      }

      this.finished = true;
      this.loading = true;
      this.isLoading = true;

      getLotteryTypeHistory(params).then(res => {
        if (res.code == 0) {
          if (!params.page) {
            this.betList = res.data.resultList;
          } else {
            if (res.data.resultList.length > 0) {
              let arr = JSON.parse(JSON.stringify(this.betList));
              arr = [...res.data.resultList, ...arr];
              this.betList = arr;
            }
          }
          
          this.betTotal = {
            totalBet: res.data.totalBet,
            totalProfit: res.data.totalProfit
          }

          if (res.data.resultList.length == 10) {
            this.finished = false;
          }

          if (refresh) {
            this.$toast(this.$t('common.refreshSuccess'));
          }

          // 解决滚动条与下拉的冲突
          this.$nextTick(() => {
            const vantList = document.querySelector('.van-list')
            if (vantList) {
              this.resolveConflict(vantList)
            }
          })
        } else {
          this.betList = {};
          this.betTotal = {
            totalBet: "0",
            totalProfit: "0"
          }
          if (res.msg) this.$toast(res.msg);
        }

        this.loading = false;
        this.isLoading = false;
      }).catch(() => {
        this.betList = {};
        this.betTotal = {
          totalBet: "0",
          totalProfit: "0"
        }
        this.loading = false;
        this.isLoading = false;
        console.log("catch");
      })
    },
    gaBack() {
      this.$router.push('/mine');
    },
    getDate(val) {
      return moment(val).format('YYYY-MM-DD');
    },
    getTime(val) {
        if (val) {
            return moment(val).format('HH:mm:ss DD-MM-YYYY');
        }
        return "";
    },
    cancelSearch() {
      this.$refs.searchItemDay.toggle();
    },
    goSearch() {
      this.$refs.searchItemDay.toggle();
      this.page = 0;
      this.getLotteryDetailsHistory();
    },
    onConfirm(index) {
      if (index != 5) {
        this.$refs.searchItemDay.toggle();
      }
      this.searchDayActive = index;
      this.page = 0;
      this.getLotteryDetailsHistory();
    },
    onRefresh() {
      setTimeout(() => {
        this.page = 0;
        this.getLotteryDetailsHistory(true);
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
  .betRecord {
    text-align: left;
    background-color: #f8f8f8;

    .title {
      height: auto;
    }

    .betSearchBox {
      text-align: left;
      background-color: #fff;
      padding: 0.1rem 0 0.1rem 0.3rem;
      display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
      display: -moz-box; /* Firefox 17- */
      display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
      display: -moz-flex; /* Firefox 18+ */
      display: -ms-flexbox; /* IE 10 */
      display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

      .betSearch {
        height: 0.68rem;
        min-width: 1rem;
        display: inline-block;
      }
    }

    .seatchTitle {
      font-size: 0.24rem;
      line-height: 0.28rem;
    }

    .searchItem {
      .searchTip {
        color: #a5a9b3;
        font-size: .24rem;
        height: 0.34rem;
        line-height: .34rem;
        padding: 0.3rem 0.1rem 0 0.28rem;
        background: #fff;
        box-sizing: initial;
      }

      .searchItem_item {
        padding: 0.3rem 0.08rem 0.1rem 0.28rem;

        span {
          display: inline-block;
          height: 0.7rem;
          min-width: 30%;
          padding: 0 0.1rem;
          line-height: .7rem;
          border-radius: 0.16rem;
          text-align: center;
          margin-right: 0.2rem;
          color: #a5a9b3;
          font-size: .28rem;
          box-sizing: border-box;
          margin-bottom: 0.2rem;
          -webkit-flex-grow: 0;
          flex-grow: 0;
          -webkit-flex-shrink: 0;
          flex-shrink: 0;
          -webkit-flex-basis: calc(25% - 0.2rem);
          flex-basis: calc(25% - 0.2rem);
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform-origin: left top;
            transform-origin: left top;
            border: 1px solid #cbced8;
            border-radius: 0.32rem;
            pointer-events: none;
            width: 200%;
            height: 200%;
            -webkit-transform: scale(.5);
            transform: scale(.5);
          }

          &.searchItem_item_active {
            background-color: #eb457e;
            color: #fff;
            border: none;

            &::after {
              border-color: transparent;
            }
          }
        }
      }
    }

    .customizeSearch {
      font-size: 0.28rem;
      position: relative;
      z-index: 2;

      &.borderBottom {
        border-bottom: 1px solid #ebeff1;
      }

      .customizeSearchItem {
        padding: 0 0.28rem;

        i {
          color: #a5a9b3;
        }
      }
    }

    .customizeBtnBox {
      display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
      display: -moz-box; /* Firefox 17- */
      display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
      display: -moz-flex; /* Firefox 18+ */
      display: -ms-flexbox; /* IE 10 */
      display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

      div {
        width: 50%;
        height: 0.9rem;
        line-height: .9rem;
        text-align: center;
        font-size: .32rem;
      }

      .customizeBtnCancel {
        color: #F74995;
        background-color: #fff;
      }

      .customizeBtnConfirm {
        background-color: #F74995;
        color: #fff;
      }
    }

    .recordList {

      .recordList_item {
        background: #fff;
        border-radius: 0.1rem;
        margin-bottom: 0.2rem;
        color: #414655;
        text-align: center;

        .recordList_item_head {
          line-height: .4rem;
          font-size: .28rem;
          text-indent: 0.3rem;
          padding: 0.14rem 0.16rem 0.16rem 0;
          border-bottom: 1px solid #eee;
          color: #333;

          .gameName {
            font-weight: 600;
            padding-left: 0.2rem;
          }

          .more {
            color: #bbb;
            font-size: 0.24rem;
          }

          img {
            width: 0.44rem;
            height: 0.44rem;
          }
        }

        .recordList_item_cont {
          padding-top: 0.3rem;

          p:first-child {
            font-weight: 700;
            font-size: .28rem;
            margin-bottom: 0.2rem;
            height: 0.4rem;
            line-height: .4rem;
          }

          .betList_list_content {
            padding: 0 0.2rem 0.2rem;

            .content_item {
              text-align: center;

              &:first-child {
                text-align: left;
              }

              div:first-child {
                color: #bbb;
                font-size: 0.24rem;
              }

              div:last-child {
                color: #333;
                margin-top: 0.12rem;
              }

              &:last-child div:last-child{
                text-align: right;
                padding-right: 0.2rem;
              }

              div.green {
                color: #00B887;            
              }
              div.red {
                color: #C41F1A;            
              }
            }
          }

          .content_footer {
            color: #a5a9b3;
            font-size: .24rem;
            padding: 0.2rem;
            position: relative;
            border-top: 1px solid #eee;

            span {
              font-size: 0.28rem;
            }
          }
        }
      }
    }

    .betRecord_top {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;

      .betRecord_top_item {
        text-align: center;
        line-height: 0.3rem;
        padding-top: 0.2rem;
        flex: 1;
        background-color: #fff;

        &:last-child {
          border-left: 1px solid #DCE4F8;
        }


        div:first-child {
          font-size: 0.21rem;
        }

        div:last-child {
          font-size: 0.32rem;
          margin: 0.12rem 0 0.2rem;
        }
      }
    }
  }
</style>
<style lang="scss">
  .betRecord {
    .van-dropdown-menu__bar {
      box-shadow: 0 0 0 rgba(100, 101, 102, 0);
      height: 0.68rem;
      border-radius: 0.5rem;
      text-align: center;
      background: #F9F9F9;
      margin-right: 0.2rem;
      color: #414655;
      line-height: 0.68rem;
      padding: 0 0.4rem 0 0.2rem;
      min-width: 1rem;

      .van-dropdown-menu__title {
        font-size: 0.24rem;
      }
    }

    .van-dropdown-menu__bar--opened {
      background-color: #eb457e;
      color: #fff;
    }

    .van-dropdown-menu__title:after {
      border-color: transparent transparent #666 #666;
    }

    .van-dropdown-menu__title--active:after {
      border-color: transparent transparent #fff #fff;
    }

    .van-dropdown-item {
      top: 0.88rem !important;
    }

    .van-dropdown-item__content {
      max-height: 100%;
    }
  }
</style>
