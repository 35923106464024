import request from "../utils/request";

// 手机号是否注册
export const userIndex = (params = {}) => {
    return request.post(`/promotion-client/user/index`, {
        ...params
    });
};

// 分享收益提现
export const userWithdraw = (params = {}) => {
    return request.post(`/promotion-client/user/withdraw`, {
        ...params
    });
};


// 查看提现记录
export const userWithdrawLog = (params = {}) => {
    return request.post(`/promotion-client/user/withdraw/log`, {
        ...params
    });
};

// 查看邀请人记录
export const shareLog = (params = {}) => {
    return request.post(`/promotion-client/user/share/log`, {
        ...params
    });
};

