import request from "../utils/request";

//查询代理基本信息
export const queryProxyBase = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryProxyBase`, {
        ...params
    });
};

// 查询代理状态
export const queryProxyStatusForWeb = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryProxyStatusForWeb`, {
        ...params
    });
};

// 根据代理查询下级用户(我的会员接口)
export const queryUserList = (params = {}) => {
    return request.post(`/proxy-client/proxyUser/queryUserList`, {
        ...params
    });
};

// 查询佣金报表
export const queryCommissionReport = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryCommissionReport`, {
        ...params
    });
};

// 查询佣金报表月份
export const queryCommissionReportMonth = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryCommissionReportMonth`, {
        ...params
    });
};

// 查询代理佣金规则
export const queryProxyCommissionConfig = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryProxyCommissionConfig
    `, {
        ...params
    });
};

// 查询会员报表月份
export const queryUserReportMonth = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryUserReportMonth
    `, {
        ...params
    });
};

// 会员报表
export const queryUserReport = (params = {}) => {
    return request.post(`/proxy-client/proxyUser/queryUserReport
    `, {
        ...params
    });
};

// 查询兑换或提款记录
export const queryWithdrawRecord = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryWithdrawRecord
    `, {
        ...params
    });
};

// 兑换或提现
export const proxyWithdraw = (params = {}) => {
    return request.post(`/proxy-client/proxy/withdraw
    `, {
        ...params
    });
};

// 查询代理推广链接
export const queryProxyUrl = (params = {}) => {
    return request.post(`/proxy-client/proxy/queryProxyUrl
    `, {
        ...params
    });
};

// 查询下级用户详情
export const queryUserInfo = (params = {}) => {
    return request.post(`/proxy-client/proxyUser/queryUserInfo
    `, {
        ...params
    });
};

// 代理查询下级用户交易记录
export const forProxy = (params = {}) => {
    return request.post(`/center-client/sys/user/asset/record/forProxy
    `, {
        ...params
    });
};


// 查询下级代理报表月份
export const queryProxyBelowReportMonth = (params = {}) => {
    return request.post(`/proxy-client/proxyBelow/queryProxyBelowReportMonth`, {
        ...params
    });
};

// 查询下级代理报表
export const queryProxyBelowReport = (params = {}) => {
    return request.post(`/proxy-client/proxyBelow/queryProxyBelowReport`, {
        ...params
    });
};

// 查询下级代理
export const queryProxyBelowList = (params = {}) => {
    return request.post(`/proxy-client/proxyBelow/queryProxyBelowList`, {
        ...params
    });
};
