<template>
  <div>
    <van-action-sheet class="weightModel" 
        v-model:show="isShowRecordLottery" :title="`${suBetPramas.nickName}`"
        @close="close" :closeable="false">
        <van-list
            class="vanList"
            v-model="loading"
            :finished="finished"
            style="height:calc(70vh - 1rem);overflow:scroll;"
            :offset="10" 
            :immediate-check="check" 
            :finish-text="`${$t('lottery.noMore')}`"
            @load="onLoad" 
            >
            <div class="liveRoomRecordLottery">
                <div v-for="(item, index) in recordList" :key="`${index}--jsksHistory`" class="lotteryItem">
                    <p>{{item.expect}}</p>

                    <div class="resultItemRight" v-if="suBetPramas.lotteryName == 'sd' || suBetPramas.lotteryName == 'yuxx' || suBetPramas.lotteryName == 'jsks'">
                        <img v-for="(xitem, xindex) in item.lotteryResult" :key="`${xindex}-${index}-liveRoom-lotteryResult-item`" v-real-img="require(`../../assets/img/lottery/${suBetPramas.lotteryName}/${xitem}.png`)" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                    </div>

                    <div class="resultItemRight" v-else-if="suBetPramas.lotteryName == 'pk10' || suBetPramas.lotteryName == 'txssc'">
                        <span v-for="(xitem, xindex) in item.lotteryResult" :key="`${xindex}-${index}-liveRoom-lotteryResult-item`">{{xitem}}</span>
                    </div>

                    <div class="resultItemRight" v-else-if="suBetPramas.lotteryName == 'yflhc' && item.lotteryResult.length == 8">
                        <span class="gameItem" v-for="(xitem, xindex) in item.lotteryResult" :key="`${xindex}--item.lotteryResult-yflhc`" v-show="xindex < 6"> {{xitem}}</span>
                        <van-icon name="plus" />
                        <span class="gameItem" v-show="item.lotteryResult[7] == 1" style="background-color:#f90007;"> {{item.lotteryResult[6]}}</span> 
                        <span class="gameItem" v-show="item.lotteryResult[7] == 2" style="background-color:#04f906;"> {{item.lotteryResult[6]}}</span>
                        <span class="gameItem" v-show="item.lotteryResult[7] == 3" style="background-color:#2a00f2;"> {{item.lotteryResult[6]}}</span>
                    </div>
                </div>
            </div>
        </van-list>
    </van-action-sheet>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLotteryResultHistoryByName } from "@/api/index";
export default {
    name: "liveRooomRecordLottery",
    data() {
        return {
            isShowRecordLottery: false,
            finished: true, //是否已加载完
            loading: false, //下拉加载更多加载中动画
            check: false, //是否开始就检查
            statusActive: 0, 
            recordList: [],
            page: 0,
            timer: null
        }
    },
    computed: {
        ...mapState(['suBetPramas', 'member', 'issue'])
    },
    watch: {
        issue() {
            let that = this;
            setTimeout(() => {
                that.getLotteryResultHistory();
            }, 5000) 
        }
    },
    created() {
        let that = this;
        this.timer = setTimeout(()=> {
            that.isShowRecordLottery = true;
        }, 10)

        this.getLotteryResultHistory();
    },
    methods: {
        close() {
            this.isShowRecordLottery = false;
            let that = this;
            clearTimeout(this.timer);
            setTimeout(()=> {
                that.$emit('colsePopup', 'showRecordLottery')
            }, 500);
        },
        onLoad() {
            this.getLotteryResultHistory();
        },
        // 获取投注记录
        getLotteryResultHistory() {
            let params = {
                issue: "",
                lotteryName: this.suBetPramas.lotteryName,
                startTime: new Date().getTime(),
                page: 0,
                status: ""
            }

            this.finished = true;
            getLotteryResultHistoryByName(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.recordList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.recordList = [];
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                this.recordList = [];
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.liveRoomRecordLottery {
    font-size: 0.24rem;
    color: #626872;
    line-height: 0.2rem;
    text-align: left;
    overflow-y: scroll;
    height: calc(70vh - 1rem);
    -webkit-overflow-scrolling: touch;
    
    div {
        div:nth-child(1) {
            width: 2.68rem;
        } 
    }

    .lotteryItem {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.2rem 0.28rem;
        text-align: left;
        color: #333;
        font-size: 0.28rem;

        P {
            margin-bottom: 0.1rem;
        }

        i {
            font-size: 0.36rem;
            color:#6666;
        }

        img {
            width: 0.44rem;
            margin-right: 0.1rem;
        }

        span {
            display: inline-block;
            width: 0.3rem;
            height: 0.35rem;
            background-color: #eb457e;
            line-height: 0.37rem;
            color: #fff;
            margin-right: 0.05rem;
            font-size: 0.24rem;
            text-align: center;
        }
    }
}
</style>