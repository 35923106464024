import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './language/index';
import vant from 'vant';
import utils from '@/utils/index';
import 'vant/lib/index.css';
import './assets/css/reset.css';
import './assets/css/common.scss';

let webApp = createApp(App);

const digitalNumber = (num) => (num && !isNaN(num) ? utils.keepTwoDecimalFull(num) : '0');

const numFormat = (num) => {
  if (num) {
    num = num.toString().split('.');
    let numStr = num[0].toString().replace(/[^\d]/g, '');
    if (num[0].indexOf('-') == 0) {
      numStr = num[0].substr(1);
    }
    const arr = numStr.split('').reverse();
    const res = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      if (i % 3 === 0 && i !== 0) {
        res.push(',');
      }
      res.push(arr[i]);
    }
    res.reverse();
    // return num[1] ? res.join('').concat(`.${num[1]}`) : res.join('');
    return num[0].indexOf('-') == 0 ? '-'+ res.join('') : res.join('');
  } else {
    return 0;
  }
};


// import eruda from 'eruda'
// eruda.init()

let lastImgURL = null; // 记录上一次加载的图片 URL

async function loadImage(el, binding) {
  const defaultURL = require('@/assets/img/errorImg/3.png');

  const imgURL = binding.value;
  if (!imgURL) {
    el.setAttribute('src', defaultURL);
    return;
  }

  // 判断图片是否存在
  try {
    const exist = await imageIsExist(imgURL);
    el.setAttribute('src', exist ? imgURL : defaultURL);
  } catch (error) {
    console.error('Error checking image existence:', error);
    el.setAttribute('src', defaultURL);
  }
}

webApp.directive('real-img', {
  beforeMount: loadImage,
  beforeUpdate: loadImage
});
  

/**
* 检测图片是否存在
* @param url
*/
let imageIsExist = function(url) {
    return new Promise((resolve) => {
        var img = new Image();
        img.onload = function () {
            if (this.complete == true){
                resolve(true);
                img = null;
            }
        }
        img.onerror = function () {
            resolve(false);
            img = null;
        }
        img.src = url;
    })
}
webApp.config.globalProperties.numFormat = numFormat;
webApp.config.globalProperties.digitalNumber = digitalNumber;
webApp.use(store).use(router).use(i18n).use(vant).mount('#app');


