<template>
    <div class="next myMember">
        <div class="title">
            <span> {{$t('my.proxy.myMember')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
            <div class="title_ellipsis">
                <img @click="topsearchBox=!topsearchBox" src="../../../assets/img/topSearch.png" />
            </div>

            <div v-show="topsearchBox" class="searchBox">
                <van-cell-group inset>
                    <van-field v-model.trim="value" @keyup="value=value.replace(/[^\d]/g,'')" :placeholder="`${$t('my.proxy.memberIDTips')}`" autocomplete="off" />
                </van-cell-group>


                <van-dropdown-menu class="betSearch">
                    <van-dropdown-item active-color="#6996FF" ref="searchItemDay">
                        <template v-slot:title>
                            <van-cell-group inset>
                                <van-field v-model="dateShow" autocomplete="off" :right-icon="require('../../../assets/img/select.png')"  @click="showDateBox=true"
                                :placeholder="`${$t('my.proxy.selectTime')}`" />
                            </van-cell-group>
                        </template>

                        <div class="customizeSearchBox">
                            <div class="customizeSearch borderBottom">
                                <div @click="showStartDate=!showStartDate,showEndDate=false"
                                    class="customizeSearchItem flexBetween">
                                    <div> {{$t('my.proxy.startDate')}}</div>
                                    <div>{{ getDate(currentStartDate) }}
                                        <van-icon name="arrow" />
                                    </div>
                                </div>

                                <div v-show="showStartDate">
                                    <van-datetime-picker item-height="0.6rem" v-model="currentStartDate"
                                        :show-toolbar="false" type="date" :title="`${'my.proxy.selectDateTips'}`"
                                        :max-date="maxDate" />
                                </div>
                            </div>

                            <div class="customizeSearch">
                                <div @click="showEndDate=!showEndDate,showStartDate=false"
                                    class="customizeSearchItem flexBetween">
                                    <div>{{$t('my.proxy.endDate')}}</div>
                                    <div>{{ getDate(currentEndDate) }}
                                        <van-icon name="arrow" />
                                    </div>
                                </div>

                                <div v-show="showEndDate">
                                    <van-datetime-picker item-height="0.6rem" v-model="currentEndDate"
                                        :show-toolbar="false" type="date" :title="`${'my.proxy.selectDateTips'}`"
                                        :max-date="maxDate" />
                                </div>
                            </div>

                            <div class="customizeBtnBox">
                                <div @click="cancelSearch" class="customizeBtnCancel">{{$t('common.cancel')}}</div>
                                <div @click="goSearch" class="customizeBtnConfirm">{{$t('common.sure')}}</div>
                            </div>
                        </div>
                    </van-dropdown-item>
                </van-dropdown-menu>

                <div @click="comfigSearch" class="confirmBtn hasValue">{{$t('my.proxy.filter')}}</div>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableItem tableHead flexBetween">
                <div>{{$t('my.proxy.memberID')}}</div>
                <div>{{$t('my.nickName')}}</div>
                <div>{{$t('my.proxy.userLevel')}}</div>
                <div>{{$t('my.invite.registrationTime')}}</div>
            </div>
            <div v-if="tableList.length>0">
                <div v-for="(item, index) in tableList" :key="`${index}--tableList`">
                    <div class="tableItem flexBetween">
                        <div class="blue" @click="toDetail(item)">{{item.uid}}</div>
                        <div>
                            <p>{{item.nickname}}</p>
                        </div>
                        <div>
                            <span>LV{{item.userLevel}}</span>
                        </div>
                        <div>
                            <p>{{getDate(item.gmtCreate)}}</p>
                            <p>{{getTime(item.gmtCreate)}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="noData">
                <img src="../../../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { queryUserList } from "@/api/proxy";
import moment from "moment";
import utils from "@/utils/index";
export default {
    data() {
        return {
            value: "",
            tableList: [],
            maxDate: new Date(),
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            showStartDate: false,
            showEndDate: false,
            showDateBox: false, // 显示时间选择
            dateShow: "", //用来展示时间的字段
            topsearchBox: false, //搜索条件是否展示
            isNeedTime: false, // 参数是否传参 时间
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    created() {
        let params = {
            page: this.page,
            uid: this.member.uid
        }
        this.getList(params);
    },
    methods: {
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm:ss');
            }

            return "--";
        },
        getDate(val) {
            if (val) {
                return moment(val).format('YYYY-MM-DD');
            }

            return "--";
        },
        // 时间确定
        goSearch() {
            this.isNeedTime = true;
            this.$refs.searchItemDay.toggle();
            this.page = 0;
            this.dateShow = this.getDate(this.currentStartDate) + '/' + this.getDate(this.currentEndDate);
        },
        //筛选 
        comfigSearch() {
            let params = {
                page: this.page,
                uid: this.member.uid,
                belowUid: this.value
            }

            if (this.isNeedTime) {
                params.startRegisterTime = utils.timeforamt(this.currentStartDate);
                params.endRegisterTime = utils.timeforamt(this.currentEndDate, "end");
            }
            this.getList(params);
        },
        cancelSearch() {
            this.$refs.searchItemDay.toggle();
        },
        gaBack() {
            this.$router.push(`/mine/myProxy`);
        },
        // 点击会员id 跳转详情
        toDetail(item) {
            this.$router.push(`/mine/myProxy/myMember/info?id=${item.uid}&type=myMember`);
        },
        getList(params) {
            queryUserList(params).then(res => {
                if (res.code == 0) {
                    this.tableList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.topsearchBox = false;
            }).catch(err => {
                console.log(err);
                this.topsearchBox = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.myMember {
    padding-top: 1rem;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);

        .title_ellipsis {
            img {
                width: 0.4rem;
            }
        }
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            &.tableHead {
                color: #333;
            }

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            &:first-child {
                border-bottom: none;
            }

            div {
                flex: 1;
                text-align: left;
                font-size: 0.24rem;

                &:nth-child(1) {
                    font-size: 0.28rem;
                }

                &:nth-child(4) {
                    text-align: right;
                }

                i {
                    color: #788597;
                    margin-left: 0.1rem;
                }
            }
        }

        .blue {
            color: #eb457e;
        }
    }

    .customizeBtnBox {
      display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
      display: -moz-box; /* Firefox 17- */
      display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
      display: -moz-flex; /* Firefox 18+ */
      display: -ms-flexbox; /* IE 10 */
      display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

      div {
        width: 50%;
        height: 0.9rem;
        line-height: .9rem;
        text-align: center;
        font-size: .32rem;
      }

      .customizeBtnCancel {
        color: #F74995;
        background-color: #fff;
      }

      .customizeBtnConfirm {
        background-color: #F74995;
        color: #fff;
      }
    }
}
</style>

<style lang="scss">
.myMember {
    .searchBox {
        background-color: #fff;
        padding-bottom: 0.2rem;
        box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.03);

        .confirmBtn {
            margin: 0 auto;
        }

        .van-cell-group {
            border: 1px solid #f2f2f2;
            margin-bottom: 0.2rem;
        }
    }

    .betSearch {
        .van-dropdown-menu__bar {
            box-shadow: 0 0 0 0 rgb(0, 0, 0, 0) !important;
        }
        
        .van-dropdown-menu__title {
            width: 100%;
            padding: 0 !important;

            &:after {
                border-width: 0 !important;
            }
        }

        .customizeSearch {
            font-size: 0.28rem;
            position: relative;
            z-index: 2;

            &.borderBottom {
                border-bottom: 1px solid #ebeff1;
            }

            .customizeSearchItem {
                padding: 0 0.28rem;

                i {
                color: #a5a9b3;
                }
            }
        }

        .van-dropdown-item {
            top: 2.92rem !important;
        }
    }
}
</style>