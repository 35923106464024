
<template>
    <div>
        <van-popup v-model:show="isShowGameList" round class="gameListDialog" :safe-area-inset-bottom="true"
            position="bottom" @close="close">
            <div class="gameListDialogCont">
                <div v-for="(item, index) in gameList" :key="`gameListDialog-${index}`" @click="itemGame(item)"
                    class="gameListDialogContItem">
                    <img v-real-img="item.icon" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
                    <div>{{ item.chinese }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
  
<script>
import { cpList } from "@/api/config";
import { mapState } from 'vuex';
export default {
    name: "liveRoomGameList",
    data() {
        return {
            isShowGameList: false,
            gameList: [], //游戏列表
        }
    },
    computed: {
        ...mapState(['lotteryRouter', 'countTime']),
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isShowGameList = true;
        }, 10)
        this.getCpList();
    },
    methods: {
        close() {
            this.isShowGameList = false;
            let that = this;
            setTimeout(() => {
                that.$emit('colsePopup', 'showGameList')
            }, 1000)
        },
        // 游戏列表
        getCpList() {
            cpList().then(res => {
                if (res.code == 0) {
                    let arr = JSON.parse(JSON.stringify(res.data));
                    arr.forEach(item => {
                        let index = this.lotteryRouter.findIndex(xitem => {
                            return xitem == item.name
                        });

                        if (index != -1) {
                            item.link = `/lottery/${item.name}`;
                        }
                    });

                    arr = arr.filter(item => {
                        return item.link;
                    });
                    this.gameList = arr;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 游戏列表点击
        itemGame(item) {
            this.$store.commit("setState", { betPramas: JSON.parse(JSON.stringify(item)) });
            this.$emit('openSingleLottery');
        }
    }
}
</script>
  
<style lang="scss" scoped>
.gameListDialogCont {
    padding: 0.8rem 0rem 0.4rem;
    text-align: left;

    .gameListDialogContItem {
        width: 25%;
        text-align: center;
        display: inline-block;
        margin-bottom: 0.4rem;
        padding: 0 0.1rem;

        img {
            width: 0.96rem;
            height: 0.96rem;
        }
    }
}
</style>

