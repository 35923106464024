import request from "../utils/request";

// 进房
export const interRoom = params => {
    return request.post(`/live-client/live/inter/room/220`, {
        ...params
    });
};

// 进房-预览接口
export const roomPreview = params => {
    return request.post(`/live-client/live/inter/roomPreview`, {
        ...params
    });
};

// 进房-预览接口-H5观看
export const roomPreviewH5 = params => {
    return request.post(`/live-client/live/inter/roomPreviewWeb`, {
        ...params
    });
};


// 房间头像列表(只显示前20)
export const roomInfo = params => {
    return request.post(`/live-client/live/room/info`, {
        ...params
    });
};

// 主播基本信息
export const anchorBase = params => {
    return request.post(`/live-client/live/room/anchor/base`, {
        ...params
    });
};

// 获取用户名片信息
export const userCardInfo = params => {
    return request.post(`/center-client/sys/user/get/card/info`, {
        ...params
    });
};

// 房间人员列表(显示全部)
export const userList = params => {
    return request.post(`/live-client/live/room/user/list`, {
        ...params
    });
};

// 房间贵族列表(显示全部)
export const userViplist = params => {
    return request.post(`/live-client/live/room/user/viplist`, {
        ...params
    });
};

// 主播个人榜单
export const anchorList = params => {
    return request.post(`/fuse-client/rank/anchor/list`, {
        ...params
    });
};

// 获取所有彩票最近一期开奖结果
export const getAllLotteryLatestResultNew = params => {
    return request.post(`/lottery-client/lottery/getAllLotteryLatestResultNew`, {
        ...params
    });
};

// 直播间送礼
export const sendGift = params => {
    return request.post(`/live-client/live/send/gift/220`, {
        ...params
    });
};

// 直播间发言
export const liveChat = params => {
    return request.post(`/live-client/live/chat`, {
        ...params
    });
};

// 直播间禁言
export const blackChat = params => {
    return request.post(`/live-client/live/black/chat`, {
        ...params
    });
};

// 直播间踢用户
export const banUser = params => {
    return request.post(`/live-client/live/ban/user`, {
        ...params
    });
};

// 计时房间付费
export const chargeRoom = params => {
    return request.post(`/live-client/live/charge/room`, {
        ...params
    });
};

// 用户进房间
export const redJoinRoom = uid => {
    return request.post(`/center-client/red/joinRoom?uid=${uid}`);
};

// 抢红包接口
export const grabRedPacket = params => {
    return request.post(`/center-client/red/grabRedPacket?uid=${params.uid}&redPacketId=${params.redPacketId}`);
};

// pk时长和惩罚配置
export const pkConfig = uid => {
    return request.get(`/live-recreation/pk/config?uid=${uid}`);
};

// 获取PK中的相关数据
export const pkStatus = params => {
    return request.post(`/live-recreation/pk/new/status`, {
        ...params
    });
};

// 获取PK中的相关数据
export const pkNewConfig = params => {
    return request.post(`/live-recreation/pk/new/config`, {
        ...params
    });
};

// 封号、封终端
export const blackUser = params => {
    return request.post(`/risk-client/risk/blackUser`, {
        ...params
    });
};

// 获取已经加入的群
export const getJoinGroupList = uid => {
    return request.get(`/live-client/live/getJoinGroupList?uid=${uid}`);
};

// 查询连麦配置
export const getVoiceBase = params => {
    return request.post(`/live-recreation/voice/getVoiceBase`, {
        ...params
    });
};

// 查询麦上用户详细信息
export const getVoiceUser = params => {
    return request.post(`/live-recreation/voice/getVoiceUser`, {
        ...params
    });
};

// 用户拒绝主播邀请
export const userRefuse = params => {
    return request.post(`/live-recreation/voice/userRefuse`, {
        ...params
    });
};

// 获取PK用户排行榜
export const getPkRank = params => {
    return request.post(`/live-recreation/pk/new/getPkRank`, {
        ...params
    });
};

// 退房 
export const outerRoom = params => {
    return request.post(`/live-client/live/outer/room`, {
        ...params
    });
};
