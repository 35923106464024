<template>
  <div>
    <!-- 玩法说明 -->
    <van-popup
        v-model:show="isShowRule"
        round
        class="ruleDialog"
        :safe-area-inset-bottom="true"
        :close-on-click-overlay="false"
        @close="closeLotteryPopup"
        >
        <div class="ruleBox">
            <div class="ruleTitle">
                <div>{{ betPramas.chinese }}</div>
            </div>
            <div class="ruleList ruleFont">
                <div class="ruleCont" v-html="playMethod"></div>
            </div>

            <div @click="closeLotteryPopup" class="ruleColse"><van-icon class="closeIcon" color="#fff" name="close" /></div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "liveRoomLotteryRule",
    data() {
        return {
            isShowRule: false, //玩法说明弹框是否展示
        }
    },
    computed: {
        ...mapState(['betPramas']),
        playMethod() {
            return this.betPramas.playMethod.replace(/\n/g,'<br/>').replace(/font-size: 24pt;/g,'font-size: 0.4rem;').replace(/font-size: 36pt;/g,'font-size: 0.5rem;');
        }
    },
    created() {
        let that = this;
        setTimeout(()=> {
            that.isShowRule = true;
        }, 10)
    },
    methods: {
        closeLotteryPopup() {
            this.isShowRule = false;
            // this.$store.commit("setState", { betPramas: {} });
            let that = this;
            setTimeout(()=> {
                that.$emit('closeLotteryPopup', 'showRule')
            }, 500)
        },
    }
}
</script>

<style lang="scss">
.ruleDialog {
    background-color: rgba(0, 0, 0, 0) !important;

    .ruleBox {
        position: relative;

        .ruleTitle {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 225px;
            height: 40px; 
            background-image: url('../../../assets/img/live/lottery/ruleTitleBg.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            div {
                margin-top: 0.24rem;
                color: #fff;
                font-size: 0.3rem;
            }
        }

        .ruleList {
            background-color: rgba(0, 0, 0, 0) !important;
            background-image: url('../../../assets/img/live/lottery/ruleBg.png');
            background-repeat: no-repeat;
            width: 320px;
            height: 350px;
            padding: 60px 25px 10px;
            background-size: 100% calc(100% - 12px);
            background-position-y: 12px;

            .ruleCont {
                height: 265px;
                text-align: left;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }
        }

        .ruleColse {
            margin-top: 0.4rem;

            i {
                font-size: 0.4rem;
            }
        }
    }
}
</style>