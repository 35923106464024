<template>
    <div class="next memberReport">
        <div class="title">
            <span> {{$t('my.proxy.memberReport')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>

            <div class="title_ellipsis">
                <img @click="topsearchBox=!topsearchBox" src="../../../assets/img/topSearch.png" />
            </div>

            <div v-show="topsearchBox" class="searchBox">
                <van-cell-group inset>
                    <!-- label-width="50px" :label="`${$t('my.proxy.memberID')}:`" -->
                    <van-field v-model.trim="memberId" @keyup="memberId=memberId.replace(/[^\d]/g,'')" autocomplete="off" :placeholder="`${$t('my.proxy.memberIDTips')}`" />
                </van-cell-group>

                <van-cell-group inset>
                    <!-- label-width="45px"  :label="`${$t('my.invite.time')}:`" -->
                    <van-field v-model.trim="dateShow" :right-icon="require('../../../assets/img/select.png')" readonly @click="showMonth=true"
                    :placeholder="`${$t('my.proxy.selectTime')}`"  />
                </van-cell-group>
                <div @click="comfigSearch" class="confirmBtn hasValue"> {{$t('my.proxy.filter')}} </div>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableItem tableHead flexBetween">
                <div>{{$t('my.proxy.memberID')}}</div>
                <div>{{$t('my.proxy.totalDeposit')}}</div>
                <div>{{$t('my.proxy.totalWithdrawal')}}</div>
                <div>{{$t('my.proxy.balance')}}</div>
                <div>{{$t('my.proxy.companyWinsLoses')}}</div>
            </div>
            <div v-if="tableList.length>0">
                <div v-for="(item, index) in tableList" :key="`${index}--tableList`" class="tableItem flexBetween">
                    <div class="blue" @click="toDetail(item)">{{item.uid}}</div>
                    <div>
                        <p>{{digitalNumber(item.totalRecharge)}}</p>
                    </div>
                    <div>
                        <span>{{digitalNumber(item.totalWithdraw)}}</span>
                    </div>
                    <div>
                        <p>{{digitalNumber(item.balance)}}</p>
                    </div>
                    <div>
                        <p>{{digitalNumber(item.companyProfit)}}</p>
                    </div>
                </div>
            </div>
            
            <div v-else class="noData">
                <img src="../../../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>

        <van-action-sheet class="bankModel" v-model:show="showMonth" :title="`${$t('my.proxy.selectMonth')}`">
            <div class="bankListCont">
                <div v-for="(item, index) in arrMonth" :key="`${index}--arrMonth`" @click="changeMonth(item,index)" class="bankListCont_item flexBetween">
                    <div class="bankListCont_item_left">
                        <p>{{ item }}</p>
                    </div>
                    <div v-show="actvieMonth==index">
                        <van-icon name="success" color="#eb457e" size="0.5rem"/>
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import { queryUserReportMonth, queryUserReport } from "@/api/proxy";
import moment from "moment";
export default {
    data() {
        return {
            tableList: [],
            showSearchBox: false, //是否展示刷选条件
            arrMonth: [], //月份
            dateShow: "", //用来展示时间的字段
            topsearchBox: false, //搜索条件是否展示
            value: '',
            columnsType: ['year', 'month'],
            showMonth: false,
            actvieMonth: 0,
            memberId: "", //会员id
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    created() {
        this.queryUserReportMonth();
    },
    methods: {
        changeMonth(item, index) {
            this.actvieMonth = index;
            this.dateShow = item;
            this.showMonth = false;
        },
        cancelSearch() {
            this.$refs.searchItemDay.toggle();
        },
        selectMonth(item){
            this.dateShow = item;
            this.$refs.searchItemDay.toggle();
        },
        getDate(val) {
            if (val) {
                return moment(val).format('YYYY-MM');
            }

            return "--";
        },
        //筛选 
        comfigSearch() {
            this.page = 0;
            this.getList(this.dateShow);
        },
        gaBack() {
            this.$router.push(`/mine/myProxy`);
        },
        // 点击会员id 跳转详情
        toDetail(item) {
            this.$router.push(`/mine/myProxy/myMember/info?id=${item.uid}&type=memberReprot`);
        },
        // 获取月份
        queryUserReportMonth() {
            let params = {
                uid: this.member.uid
                // uid: 15055914
            }
            queryUserReportMonth(params).then(res => {
                if (res.code == 0) {
                    this.arrMonth = res.data;
                    if (res.data.length > 0) {
                        this.getList(res.data[0]);
                        this.value = res.data[0];
                    } else {
                        this.arrMonth.push(this.getDate(new Date())) 
                    }
                    this.dateShow = this.arrMonth[0];
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        getList(month) {
            let params = {
                uid: this.member.uid,
                // uid: 15055914,
                month,
                page: this.page
            }

            if (this.memberId) {
                params.belowUid = this.memberId;
            }
            queryUserReport(params).then(res => {
                if (res.code == 0) {
                    this.tableList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.topsearchBox = false;
            }).catch(err => {
                console.log(err);
                this.topsearchBox = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.memberReport {
    padding-top: 1rem;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);

        .title_ellipsis {
            img {
                width: 0.4rem;
            }
        }
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;
            font-size: 0.2rem;

            &.tableHead {
                color: #333;
            }

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            &:first-child {
                border-bottom: none;
            }

            div {
                flex: 1;
                text-align: center;

                i {
                    color: #788597;
                    margin-left: 0.1rem;
                }
            }
        }

        .blue {
            color: #eb457e;
        }
    }

    .customizeBtnBox {
      display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
      display: -moz-box; /* Firefox 17- */
      display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
      display: -moz-flex; /* Firefox 18+ */
      display: -ms-flexbox; /* IE 10 */
      display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

      div {
        width: 50%;
        height: 0.9rem;
        line-height: .9rem;
        text-align: center;
        font-size: .32rem;
      }

      .customizeBtnCancel {
        color: #F74995;
        background-color: #fff;
      }

      .customizeBtnConfirm {
        background-color: #F74995;
        color: #fff;
      }
    }

    .bankListCont {
        margin-bottom: 0.3rem;
        min-height: 6rem;

        .bankListCont_item {
            padding: 0.36rem 0;
            margin: 0 0.28rem;
            border-bottom: 1px solid #EBEFF1;

            &:last-child {
                border-color: transparent;
            }

            .bankListCont_item_left {
                text-align: left;
                color: #626872;
                font-size: 0.2rem;

                img {
                    width: 0.64rem;
                    height: 0.64rem;
                    margin-right: 0.1rem;
                }

                p:first-child {
                    font-size: 0.28rem;
                    color: #333;
                    font-weight: bold;
                    margin-bottom: 0.05rem;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.memberReport {
    .searchBox {
        background-color: #fff;
        padding-bottom: 0.2rem;
        box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.03);

        .confirmBtn {
            margin: 0 auto;
        }

        .van-cell-group {
            border: 1px solid #f2f2f2;
            margin-bottom: 0.2rem;
        }
    }

    .betSearch {
        .van-dropdown-menu__bar {
            box-shadow: 0 0 0 0 rgb(0, 0, 0, 0) !important;
        }
        
        .van-dropdown-menu__title {
            width: 100%;
            padding: 0 !important;

            &:after {
                border-width: 0 !important;
            }
        }
    }
}
</style>