<template>
  <div>
    <!-- 奖杯icon 弹框 开奖结果/ 投注记录 弹框 -->
    <van-popup
        v-model:show="isShowLotteryResult"
        round
        class="gameListDialog"
        :safe-area-inset-bottom="true"
        position="bottom"
        @close="colsePopup"
        >
        <div class="flexBetween lotteryBet">
            <div @click="$emit('openPopup', 'showLotteryResult')">
                <img src="../../assets/img/live/recordIcon.png" />
                <div>{{ $t('liveRoom.lotteryRecord') }}</div>
            </div>
            
            <div @click="$emit('openPopup', 'showLotteryRecord')">
                <img src="../../assets/img/live/betIcon.png" />
                <div>{{ $t('liveRoom.lotteryRecord') }}</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
export default {
    name: "liveRoomLotteryResult",
    data() {
        return {
            isShowLotteryResult: false,
            isShowResult: false, // 开奖结果弹框是否展示
            isShowRecord: false,
        }
    },
    created() {
        let that = this;
        setTimeout(()=> {
            that.isShowLotteryResult = true;
        }, 10)
    },
    methods: {
        colsePopup() {
            this.isShowLotteryResult = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('colsePopup', 'showLotteryBet')
            }, 1000)
        },
    }
}
</script>

<style lang="scss" scoped>
.lotteryBet {
    padding: 0.56rem 0;

    div {
        flex: 1;
        text-align: center;

        img {
            margin-bottom: 0.2rem;
            width: 0.88rem;
            height: 0.88rem;
        }
    }
}


.resultDailog {
    min-height: 50vh;
    .resultItem {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.2rem 0.28rem;
        text-align: left;
        color: #333;

        img {
            width: 0.44rem;
            margin-top: 0.2rem;
            margin-right: 0.16rem;
        }
    }
}
</style>