<template>
    <div class="next bannerDetail">
      <div class="title">
          <span> {{$t('home.activityDetails')}} </span>
          <div class="fixed" @click="goOtherPage('/home')">
              <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
          </div>
          <div class="cont">
            <img v-real-img="bannerDetail.jumpUrl" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'homeBanner',
    data() {
      return {
        fromPath: ""
      }
    },
    computed: {
      bannerDetail() {
        return this.$store.state.bannerDetail;
      },
      entry() {
        return this.$store.state.entry;
      },
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
          e.fromPath = from.fullPath;
        });
    },
    created() {
    },
    methods: {
      goOtherPage() {
        this.$router.push(this.fromPath);
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .bannerDetail {
    background-color: #f8f8f8;
    font-size: 0.28rem;
    color: #333;

    .cont {
        height: calc(100vh - 0.88rem);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        img {
            width: 100%;
        }
    }
  }
  </style>