<template>
    <div class="next withdrawRecord">
        <div class="title">
            <span> {{$t('my.invite.withdrawalRecords')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>

            <div class="searchItem">
                <div class="searchItem_item">
                    <span v-for="(item, index) in dayList" :key="`${index}--dayList`"
                        :class="searchDayActive == index ? 'searchItem_item_active' : ''" @click="changeStatus(index)">
                        {{ item }}
                    </span>
                </div>
            </div>
        </div>

        <!--成功 green   驳回 red  审核中 blue-->
        <div v-if="tableList.length > 0" class="withdrawRecordList">
            <div v-for="(item, index) in tableList" :key="`${index}`" class="withdrawRecordItem flexBetween">
                <div>
                    <p>{{ getCardNo(item.cardNo) }}</p>
                    <p>{{ getTime(item.gmtCreate) }}</p>
                </div>
                <div>
                    <p>{{ numFormat(item.cash) }} {{ baseInfo.currencySymbol }}</p>
                    <!-- item.status === 1 ? 'green' ? item.status === 2 : 'red' : 'blue'  -->
                    <p :class="{ 'green': item.status === 1, 'red': item.status === 2, 'blue': item.status === 0 }">{{ dayList[item.status + 1] }}</p>
                </div>
            </div>
        </div>

        <div v-else class="noData">
            <img src="../../assets/img/record/noData.png" />
            <p>{{ $t('common.noData') }}</p>
        </div>
    </div>
</template>

<script>
import { withdrawList } from "@/api/index";
import moment from 'moment';
import utils from "@/utils/index";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            dayList: [this.$t('my.invite.status.all'), this.$t('my.invite.status.underReview'), this.$t('my.invite.status.success'), this.$t('my.invite.status.turnDown')],
            searchDayActive: 0,
            tableList: []
        }
    },
    created() {
        this.getList();
    },
    computed: {
        ...mapState(['member', 'baseInfo']),
    },
    methods: {
        // 银行卡处理
        getCardNo(cardNo) {
            return utils.bankCardHide(cardNo);
        },
        gaBack() {
            this.$router.push("/mine/finance/withdraw");
        },
        changeStatus(index) {
            this.searchDayActive = index;
            this.getList();
        },
        // 列表
        getList() {
            let params = {
                withdrawType: 1,
                uid: this.member.uid,
                page: 0
            }
            if (this.searchDayActive > 0) {
                params.status = this.searchDayActive - 1;
            }

            this.tableList = [];
            withdrawList(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.tableList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 时间格式
        getTime(time) {
            if (time) {
                return moment(time).format('HH:mm DD-MM-YYYY');
            } 
            return "--";
        }
    }
}
</script>

<style lang="scss" scoped>
.withdrawRecord {
    padding-top: 2.2rem;
    background-color: #fff;

    .title {
        height: auto;
    }

    .searchItem {
        .searchItem_item {
            padding: 0.2rem 0.08rem 0.2rem 0.28rem;
            text-align: left;
            white-space: nowrap;
            overflow-x: scroll;
            /* 隐藏滚动条 */
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */

            &::-webkit-scrollbar {
                width: 0;      /* Safari,Chrome 隐藏滚动条 */
                height: 0;     /* Safari,Chrome 隐藏滚动条 */
                display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
            }

            span {
                display: inline-block;
                height: 0.56rem;
                min-width: 1.58rem;
                line-height: .56rem;
                border-radius: 0.4rem;
                text-align: center;
                margin-right: 0.2rem;
                color: #666;
                font-size: .28rem;
                box-sizing: border-box;
                -webkit-flex-grow: 0;
                flex-grow: 0;
                -webkit-flex-shrink: 0;
                flex-shrink: 0;
                -webkit-flex-basis: calc(25% - 0.2rem);
                flex-basis: calc(25% - 0.2rem);
                position: relative;
                background-color: #e5e5e5;
                padding: 0 0.2rem;

                &.searchItem_item_active {
                    background: -webkit-linear-gradient(left, #f86fab, #fa8faf);
                    background: linear-gradient(90deg, #f86fab, #fa8faf);
                    color: #fff;
                    border: none;

                    &::after {
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }

    .withdrawRecordList {
        padding: 0 0.28rem;

        .withdrawRecordItem {
            padding: 0.16rem 0;
            border-bottom: 1px solid #f5f5f5;

            div:nth-child(1) {
                text-align: left;
                color: #626872;
                font-size: 0.2rem;

                p:nth-child(1) {
                    color: #333;
                    font-size: 0.28rem;
                    margin-bottom: 0.12rem;
                }
            }

            div:last-child {
                text-align: right;
                font-size: 0.28rem;
                color: #eb457e;

                .green {
                    color: #00b82c;
                }

                .red {
                    color: #c41f1a;
                }

                .blue {
                    color: #2e8cf0;
                }

                p:nth-child(1) {
                    margin-bottom: 0.12rem;
                }
            }
        }
    }
}</style>