<template>
    <div>
        <van-popup
            v-model:show="isShowPrivateLetter"
            position="bottom"
            :style="{ width: '100%', height: '100%' }"
            >
            <div class="next privateLetter">
                <div class="title">
                    <span>{{ betPramas.nickname }}</span>
                    <div class="fixed" @click="closePrivateLetter">
                    <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
                    </div>
                </div>


                <div v-for="(item, index) in destLetterList" :key="`${index} -- destLetterList`" class="cont">
                    <div class="time">{{ getTime(item.createTime ? item.createTime : item.timestamp) }}</div>
                    <!-- 发送方  接收方 -->
                    <div v-show="item.sendUid != member.uid" class="messageBox otherSide">
                        <img v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>

                        <div class="messageBody">
                            {{ item.content }}
                        </div>
                    </div>

                    <div v-show="item.sendUid == member.uid" class="messageBox isSelf">
                        <div class="messageBody">
                            {{ item.content }}
                        </div>

                        <img  v-real-img="member.avatar" />
                    </div>
                </div>

                <div class="sendMsgBox flexBetween">
                    <div>
                        <van-form @submit="liveLetter">
                            <van-field v-model.trim="content" autocomplete="off" :placeholder="`${$t('my.mail.privateLetterTips')}`"/>
                        </van-form>
                    </div>
                    <div @click="liveLetter">{{ $t('my.mail.send') }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from 'vuex';
import { liveLetter, allLetterList } from "@/api/index";
export default {
    name: "privateLetter",
    props: ['showPrivateLetter'],
    data() {
        return {
            isShowPrivateLetter: false,
            content: "",
            // destLetterList: [], 
        }
    },
    computed: {
        ...mapState(['betPramas', 'member', 'letterList']),
        destLetterList() {
            let arr = [];

            if (this.letterList.length > 0) {
                arr = this.letterList.filter(item => {
                    return item.otherUid == this.betPramas.uid;
                });
            }
            return arr;
        },
    },
    created() {
        let that = this, old = JSON.parse(JSON.stringify(this.letterList));
        setTimeout(()=> {
            that.isShowPrivateLetter = true;
        }, 10);

        if (old.length > 0) {
            old.forEach(item => {
                if (item.otherUid == this.betPramas.otherUid || item.otherUid == this.betPramas.uid) {
                    item.isUnreadMsg = 1
                }
            });
            this.$store.commit("setState", { isUnreadMsgFlag: true, letterList: old });
        }
        
        this.getLetterList();
    },
    methods: {
        getTime(val) {
          if (val) {
            return moment(val).format('HH:mm DD-MM-YYYY');
          }
          return "";
        },
        // 关闭弹框
        closePrivateLetter() {
            this.isShowPrivateLetter = false;
            let that = this;
            this.$store.commit("setState", { isUnreadMsgFlag: false });
            setTimeout(()=> {
                that.$emit('closePrivateLetter')
            }, 1000)
        },
        // 发送私信
        liveLetter() {
            if (!this.content) return;
            let timestamp = new Date().getTime(),
             params = {
                uid: this.member.uid,
                content: this.content,
                destUid: this.betPramas.uid,
            }
            liveLetter(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let old = this.letterList.length > 0 ? JSON.parse(JSON.stringify(this.letterList)) : [],
                    obj = {
                        avatar: this.betPramas.avatar,
                        content: params.content,
                        letterId: res.data,
                        nickname: this.betPramas.uid,
                        type: 2,
                        otherUid: this.betPramas.uid,
                        sendUid: this.member.uid,
                        timestamp
                    }

                    old.push(obj);
                    this.$store.commit("setState", { letterList: old });

                    this.content = "";
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        getLetterList() {
            let date = new Date().toJSON().slice(0, 10), timestamp = new Date(`${date} 00:00:00`).getTime(), 
            params = {
                destUid: this.betPramas.uid,
                uid: this.member.uid,
                createTime: (timestamp - 7 * 24 * 3600 * 1000 - 1000) // 接口返回的数据是大于此时间戳
            }
            
            allLetterList(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let old = this.letterList.length > 0 ? JSON.parse(JSON.stringify(this.letterList)) : [];
                    old = [...old, ...res.data]
                    old = old.filter((obj, index, self) => {
                        return index === self.findIndex((o) => (
                            o.letterId === obj.letterId
                        ));
                    });
                    this.$store.commit("setState", { letterList: old });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.privateLetter {
    background-color: #f9f9f9;
    font-size: 0.28rem;
    color: #333;
    height: auto;
    height: 100vh;
    padding-bottom: 1.2rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.title {
        padding-top: 0.88rem;
    }

    .cont {
      padding: 0 0.28rem;


      .time {
        display: inline-block;
        margin: 0.4rem auto 0.2rem;
        background-color: #c4c4c4;
        color: #fff;
        font-size: 0.2rem;
        padding: 0.01rem 0.1rem;
        border-radius: 0.05rem;
      }

      .messageBox {
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
      
        img {
          width: 0.98rem;
          height: 0.98rem;
          border-radius: 50%;
          object-fit: cover;
        }
        .messageBody {
          position: relative;
          display: table;
          max-width: 100%;
          padding:3px 6px;
          line-height: 0.6rem;
          border-radius: 4px;
          background-color: #fff;
          overflow-wrap: anywhere;
          font-size: 8px;
          border: 1px solid #e5e5e5;
          min-height: 1.08rem;
          text-align: left;
          min-width: 1.5rem;

          &::before, &::after {
            content: "";
            width: 0;
            height: 0;
            font-size: 0;
            overflow: hidden;
            display: block;
            border-width: 6px 10px;
            border-style: dashed solid dashed dashed;
            position: absolute;
            top: 0.2rem;
          }

          &::after {
            border-style: dashed solid dashed dashed;
            position: absolute;
            top: 0.2rem;
          }
        }

        &.otherSide {
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            -moz-box-pack: flex-start;
            -webkit-moz-box-pack: flex-start;
            box-pack: flex-start;

            .messageBody {
                margin-left: 18px;

                &::before, &::after {
                    border-color: transparent #e5e5e5 transparent transparent;
                    left: -20px;
                }

                &::after {
                    border-color: transparent #fff transparent transparent;
                    left: -19px;
                }
            }
        }

        &.isSelf {
            -webkit-justify-content: flex-end;
            justify-content: flex-end;
            -moz-box-pack: flex-end;
            -webkit-moz-box-pack: flex-end;
            box-pack: flex-end;

            .messageBody {
                margin-right: 18px;
                background-color: #04f906;
                color: #fff;

                &::before, &::after {
                    border-color: transparent transparent transparent #e5e5e5;
                    right: -20px;
                }

                &::after {
                    border-color: transparent transparent transparent #04f906;
                    right: -19px;
                }
            }
        }
        
      }
    }

    .sendMsgBox {
        position: fixed;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        height: 0.88rem;
        box-shadow: -0.04rem 0 0.08rem 0 rgba(0, 0, 0, 0.05);
        padding: 0.1rem 0.28rem;

        .van-cell {
            background-color: #f9f9f9;
            width: calc(100vw - 1.8rem);
            height: 0.68rem;
            line-height: 0.68rem;
            padding: 0 0.2rem;
            border-radius: 0.1rem;
        }

        &>div:last-child {
            background-color: #ff54a8;
            color: #fff;
            padding: 0.1rem 0.2rem;
            border-radius: 0.1rem;
            font-size: 0.2rem;
            width: 1rem;
        }
    }
}
</style>