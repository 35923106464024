<template>
    <div class="next proxyRecord">
        <div class="title">
            <span v-show="type=='exchange'"> {{$t('my.invite.exchangeRecord')}} </span>
            <span v-show="type=='withdraw'"> {{$t('my.invite.withdrawalRecords')}} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>

            <div class="searchItem flexBetween">
                <div v-for="(item, index) in dayList" :key="`${index}--dayList`"
                    :class="searchDayActive==index ? 'searchItem_item_active' : ''" @click="changeStatus(index)">
                    {{ item }}
                </div>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableItem tableHead flexBetween">
                <div>{{type=='exchange' ? $t('my.invite.exchangeAount') : $t('my.finance.withdrawalAmount')}}</div>
                <div>{{$t('my.invite.time')}}</div>
                <div>{{ $t('my.finance.status') }}</div>
            </div>
            <div v-if="tableList.length>0">
                <div v-for="(item, index) in tableList" :key="`${index}--tableList`" >
                    <div class="tableItem flexBetween">
                        <div>{{ type=='exchange'? numFormat(item.goldCoin) : numFormat(item.cash)}}{{item.currency ? item.currency : ''}}</div>
                        <div>
                            <p>{{getDay(item.gmtCreate)}}</p>
                            <p>{{getTime(item.gmtCreate)}}</p>
                        </div>
                        <div @click="itemStatus(item, index)">
                            <span v-show="!item.status" class="blue">{{ $t('my.invite.status.underReview') }}</span>
                            <span v-show="item.status==1" class="green">{{$t('my.invite.status.pass')}}</span>
                            <span v-show="item.status==2" class="red">{{$t('my.invite.status.turnDown')}}</span>
                            <van-icon v-show="!item.flag && item.status==2" name="arrow-down" />
                            <van-icon v-show="item.flag && item.status==2" name="arrow-up" />
                        </div>
                    </div>
                    <div v-show="item.flag && item.status==2" class="reason">
                        <p>{{$t('my.invite.reasonRejection')}}</p>
                        <p>{{item.failReason}}</p>
                    </div>
                </div>
            </div>
            <div v-else class="noData">
                <img src="../../../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { queryWithdrawRecord } from "@/api/proxy";
import moment from "moment";
export default {
    data() {
        return {
            dayList: [this.$t('my.invite.status.all'), this.$t('my.invite.status.underReview'), this.$t('my.invite.status.success'), this.$t('my.invite.status.turnDown')],
            searchDayActive: 0,
            tableList: [],
            type: "",
            page: 0,
            fromPath: ""
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
            e.fromPath = from.path;
        });
    },
    created() {
        this.type = this.$route.params.type;
        this.getList();
    },
    methods: {
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm:ss');
            }

            return "";
        },
        getDay(val) {
            if (val) {
                return moment(val).format('YYYY-MM-DD');
            }

            return "";
        },
        goBack() {
            let arr = ['/mine/myProxy/withdraw', '/mine/myProxy/exchange'],
            index = arr.findIndex(item => {
                return item == this.fromPath;
            })

            if (index != -1) {
                this.$router.push(this.fromPath);
            } else {
                this.$router.push(`/mine/myProxy`);
            }
            
        },
        changeStatus(index) {
            this.searchDayActive = index;
            this.getList();
        },
        // 驳回原因
        itemStatus(item, index) {
            this.tableList[index].flag = !item.flag;
        },
        getList() {
            let params = {
                uid: this.member.uid,
                pageSize: 20,
                page: this.page
            }

        	//0待审核1通过2驳回,不传查全部
            if (this.searchDayActive >0) {
                params.status = this.searchDayActive - 1;
            }

            if (this.type == 'exchange') {
                params.withdrawType = 1;
            } else {
                params.withdrawType = 2;
            }

            this.tableList = [];
            queryWithdrawRecord(params).then(res => {
                if (res.code == 0) {
                    let arr = JSON.parse(JSON.stringify(res.data));
                    arr.forEach(item => {
                        item.flag = false;
                    });
                    this.tableList = arr;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.proxyRecord {
    padding-top: 1.8rem;
    background-color: #F9F9F9;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
    }

    .title {
        height: auto;
    }

    .searchItem {
        border-top: 1px solid #F9F9F9;
        background: #F9F9F9;
        color: #626872;
        height: 0.88rem;
        overflow-x: scroll;
        /* 隐藏滚动条 */
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */

        &::-webkit-scrollbar {
            width: 0;      /* Safari,Chrome 隐藏滚动条 */
            height: 0;     /* Safari,Chrome 隐藏滚动条 */
            display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }

        div {
            height: 0.56rem;
            line-height: 0.56rem;
            border-radius: 0.4rem;
            padding: 0 0.3rem;
            margin: 0 auto;
            font-size: 0.28rem;
            white-space: nowrap;

            &.searchItem_item_active {
                background: -webkit-linear-gradient(left,#ff54a8,#ffb3b2);
                background: linear-gradient(90deg,#ff54a8,#ffb3b2);
                color: #fff;
            }
        }
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            div {
                flex: 3;
                text-align: left;

                &:nth-child(2) {
                    font-size: 0.24rem;
                    flex: 2;
                }

                &:nth-child(3) {
                    flex: 2;
                    text-align: right;
                }

                i {
                    color: #788597;
                    margin-left: 0.1rem;
                }
            }
            
            &.tableHead {
                color: #333;

                div:nth-child(2) {
                    font-size: 0.28rem;
                }
            }

            .green {
                color: #00B82C;
            }

            .blue {
                color: #2E8CF0;
            }

            .red {
                color: #C41F1A;
            }
        }

        .reason {
            padding: 0.1rem 0.28rem;
            text-align: left;
            color: #626872;

            p:nth-child(2) {
                font-size: 0.24rem;
                margin-top: 0.16rem;
                
            }
        }
    }
}
</style>