import { refreshKey } from "@/api/index";
import { interRoom, roomPreview, anchorBase, roomPreviewH5 } from "@/api/live";
import Aes from "@/utils/aes.js";
import { mapState } from 'vuex';
const interLive = {
    computed: {
        ...mapState(['member', 'liveInfo', 'baseInfo', 'liveAE', 'tim']),
    },
    methods: {
        getAnchorBase(item, jumpFlag) {
            let params = {
                liveId: item.liveId,
                anchorId: item.anchorId
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            anchorBase(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let obj = JSON.parse(JSON.stringify(res.data)), liveInfo = JSON.parse(JSON.stringify(item));
                    liveInfo.type = obj.type;
                    liveInfo.webStatus = obj.webStatus;
                    if (obj.price) liveInfo.price = obj.price;
                    this.$store.commit("setState", { liveInfo });

                    if (!liveInfo.webStatus) {
                       if (!liveInfo.type) {
                            let interRoomParams = {
                                liveId: liveInfo.liveId,
                                uid: this.member.uid,
                                adJumpUrl: "",
                                anchorId: liveInfo.anchorId,
                                isRoomPreview: 0
                            }
                            this.interRoom(interRoomParams);
                        } else if (liveInfo.type == 1) {
                            this.roomPreview(liveInfo);
                        } else if (liveInfo.type == 2) {
                            this.roomPreview(liveInfo);
                        } else if (liveInfo.type == 3) {
                            if (this.isManage) {
                                let interRoomParams = {
                                    liveId: liveInfo.liveId,
                                    uid: this.member.uid,
                                    adJumpUrl: "",
                                    anchorId: liveInfo.anchorId,
                                    isRoomPreview: 0,
                                    type: 3
                                }
                                this.interRoom(interRoomParams);
                            } else {
                                setTimeout(() => {
                                    this.$store.commit("setState", { isLoadForLoad: false });
                                    this.$router.push('/entryLiveBefore');
                                }, 100)
                            }
                        } 
                    } else {
                        this.roomPreview(liveInfo);
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.$store.commit("setState", { isLoadForLoad: false });
                    if (jumpFlag) {
                        if (this.$parent.fromPath && this.$parent.fromPath != '/entryLiveBefore' && this.$parent.fromPath != '/liveRoom' && this.$parent.fromPath != '/offRoom') {
                            this.$router.push(this.$parent.fromPath);
                        } else {
                            this.$router.push('/home');
                        }
                    }
                    
                }
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        //进房-预览接口
        roomPreview(item) {
            let fun = null,
            params = {
                anchorId: item.anchorId,
                liveId: item.liveId,
                os: 0,
                uid: this.member.uid,
                type: item.type,
                liveStatus: '1'
            };

            if (item.webStatus) {
                fun = roomPreviewH5; // h5 预览
            } else {
                fun = roomPreview;
            }

            fun(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    params.isPreview = res.data.isPreview ? res.data.isPreview : 0;
                    this.interRoom(params);
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        interRoom(params) {
            if (this.tim && this.tim.getGroupList) {
                interRoom(params).then(res => {
                    if (res.code === 0 || res.code === '0') {
                        let pullStreamUrl = this.baseInfo.streamSwitch && this.baseInfo.streamSwitch == '1' ? Aes.decryptL(res.data.pullStreamUrl, this.liveAE.randomKey, this.liveAE.randomVector) : res.data.pullStreamUrl,
                            url = pullStreamUrl.replace(/rtmp/, "webrtc");
    
                        if (!url) {
                            this.decryptFail(params);
                        } else {
                            this.jionRoom(res.data, params);
                        }
                    } else {
                        if (res.msg) this.$toast(res.msg);
                        this.$store.commit("setState", { isLoadForLoad: false });
                    }
                }).catch(() => {
                    this.$store.commit("setState", { isLoadForLoad: false });
                })
            } else {
                this.$store.commit("setState", { isLoadForLoad: false });
                this.$toast(this.$t('liveRoom.imConnectErrTip'))
            }
            
        },
        // 解密失败处理
        decryptFail(params) {
            refreshKey({ uid: this.member.uid }).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let liveAE = {
                        randomKey: res.data.randomKey,
                        randomVector: res.data.randomVector
                    }
                    this.$store.commit("setState", { liveAE });
                    setTimeout(() => {
                        this.interRoom(params);
                    }, 500);

                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 进直播间操作
        jionRoom(resData, params) {
            this.jionRoomExecution(resData, params).then(() => {
                if (this.$route.path == '/liveRoom') {
                    this.$emit('refreshLiveRoom');
                    this.refreshLiveRoom && this.refreshLiveRoom();
                    this.close && this.close();
                } else {
                    this.$router.push('/liveRoom');
                }
            })
        },

        // 进房前步骤
        jionRoomExecution(resData, params) {
            return new Promise((resolve) => {
                let obj = JSON.parse(JSON.stringify(this.liveInfo));
                obj = { ...obj, ...resData };
                delete obj.uid;
                obj.isPreview = params.isPreview;
                this.$store.commit("setState", { liveInfo: obj, isLoadForLoad: false, interUserArr: [] });
                this.$store.dispatch("getGroupList", String(obj.liveId));
                resolve();
            });
        },
        // 进直播间操作
        // jionRoom(resData, params) {
        //     let obj = JSON.parse(JSON.stringify(this.liveInfo));
        //     obj = { ...obj, ...resData };
        //     delete obj.uid;
        //     obj.isPreview = params.isPreview;
        //     this.$store.commit("setState", { liveInfo: obj, isLoadForLoad: false });
        //     setTimeout(() => {
        //         if (this.$route.path == '/liveRoom') {
        //             this.$emit('refreshLiveRoom');
        //             this.close();
        //         } else {
        //             this.$router.push('/liveRoom');
        //         }
        //         this.$store.dispatch("getGroupList", String(obj.liveId));
        //     }, 500)
        // },
    }
}
export default interLive;