<template>
  <div class="noble next" :class="nobleInfo.length > 0 && nobleInfo[active] && nobleInfo[active].level ? `level${nobleInfo[active].level}`:''">
    <div class="title" :class="nobleInfo.length > 0 && nobleInfo[active] && nobleInfo[active].level ? `level${nobleInfo[active].level}`:''">
      <span>{{ $t('my.noble.title') }} </span>
      <div class="fixed" @click="goOtherPage('/mine')">
        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="12456" width="0.3rem" height="0.3rem">
          <path
            d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
            fill="#fff" p-id="12457"></path>
        </svg>
      </div>
    </div>

    <van-tabs :class="nobleInfo.length > 0 && nobleInfo[active] && nobleInfo[active].level ? `level${nobleInfo[active].level}`:''" animated color="#d5ae47" swipeable title-active-color="#d5ae47"
      title-inactive-color="#fff" v-model:active="active">
      <van-tab v-for="(item, index) in nobleInfo" :key="`${index}--noble`" :title="`${item.name}`">
        <div class="listTop" :class="nobleInfo.length > 0 && nobleInfo[active] && nobleInfo[active].level ? `level${nobleInfo[active].level}`:''">
          <div class="iconBox flexCenterBL">
            <!-- v-real-img="item.logoUrl" :errorimg="require(`../../../assets/img/noble/icon/${item.level}.png`)" -->
            <img class="iconBg" v-if="item.level" v-real-img="require(`../../../assets/img/noble/icon/${item.level}.png`)" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
            <img class="levelIcon" v-if="item.level" v-real-img="require(`../../../assets/img/noble/icon/${item.level}.png`)" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
          </div>
          <p>{{ item.name }}（{{ $t("my.noble.opened") }} {{ item.price }}{{ $t("my.finance.currency") }} / 30{{ $t('my.mall.day') }}）</p>
        </div>

        <div class="listBox" :class="nobleInfo.length > 0 && nobleInfo[active] && nobleInfo[active].level ? `level${nobleInfo[active].level}`:''">
          <div class="listItem has">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/wzzm.png" />
            </div>
            <p class="listItemTitle"> {{ $t("my.noble.wzzm") }}</p>
            <p class="listItemDetail">{{ $t("my.noble.wzzmDetail1") }} {{item.name}} {{ $t("my.noble.wzzmDetail2") }}</p>
          </div>

          <div class="listItem has">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/pk.png" />
            </div>
            <p class="listItemTitle">{{ $t("my.noble.pk") }}</p>
            <p class="listItemDetail"> {{ item.pkAddition }} {{ $t("my.noble.pkDetail") }} </p>
          </div>

          <div class="listItem has">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/zslw.png" />
            </div>
            <p class="listItemTitle">{{ $t("my.noble.zslw") }}</p>
            <p class="listItemDetail">{{ $t("my.noble.zslwDetail") }} {{item.name}}</p>
          </div>

          <div class="listItem" :class="{'has': [2, 3, 4, 5].indexOf(item.level) != -1 }">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/gzlh.png" />
            </div>
            <p class="listItemTitle">{{ $t("my.noble.gzlh") }}</p>
            <p v-show="!item.num" class="listItemDetail">
              {{ $t("my.noble.gzlhNo") }}
            </p>
            <p v-show="item.num" class="listItemDetail">
              {{item.num}} {{ $t("my.noble.gzlhDetail") }}
            </p>
          </div>

          <div class="listItem" :class="{'has': [3, 4, 5].indexOf(item.level) != -1 }">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/cjft.png" />
            </div>
            <p class="listItemTitle">{{ $t("my.noble.cjft") }}</p>
            <p v-show="[5].indexOf(item.level) == -1 " class="listItemDetail">{{ $t("my.noble.cjftDetail") }}</p>
            <p v-show="[5].indexOf(item.level) != -1 " class="listItemDetail">{{ $t("my.noble.cjftDetail1") }}</p>
          </div>

          <div class="listItem" :class="{'has': [3, 4, 5].indexOf(item.level) != -1 }">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/smcl.png" />
            </div>
            <p class="listItemTitle">{{ $t("my.noble.smcl") }}</p>
            <p class="listItemDetail">{{ $t("my.noble.smclDetail") }}</p>
          </div>

          <div class="listItem" :class="{'has': [4, 5].indexOf(item.level) != -1 }">
            <div class="imgBox flexCenterBL">
              <img src="../../../assets/img/noble/list/cjys.png" />
            </div>
            <p class="listItemTitle">{{ $t("my.noble.cjys") }}</p>
            <p v-if="item.level != 4" class="listItemDetail">
              {{ $t("my.noble.cjysDetail") }}
            </p>
            <p v-else class="listItemDetail">
              {{ $t("my.noble.cjysDetail1") }}
            </p>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <div class="nobleBottom" :class="nobleInfo.length > 0 && nobleInfo[active] && nobleInfo[active].level ? `level${nobleInfo[active].level}`:''">
      <div class="nobleBottomBox">
        <div class="buyBox flexBetween">
          <div>
            <div>
              {{ nobleInfo.length > 0 && nobleInfo[active] ? nobleInfo[active].name : '' }}
              <span>（{{ $t("my.noble.opened") }}{{ nobleInfo.length > 0 && nobleInfo[active] ? nobleInfo[active].price : '' }}{{ $t("my.finance.currency") }}/30{{ $t('my.mall.day') }}）</span>
            </div>
            <div>{{ $t("my.noble.award") }}：<span>{{ nobleInfo.length > 0 && nobleInfo[active] ? nobleInfo[active].rewardPrice : '' }}{{ $t("my.finance.currency") }}</span></div>
          </div>
          <div @click="buyClick" class="buyBtn">{{ $t('my.menuList.mall') }}</div>
        </div>
        <div class="red">{{ $t("my.noble.renew") }}：{{ nobleInfo.length > 0 && nobleInfo[active] ? nobleInfo[active].renewPrice : '' }} {{ $t("my.finance.currency") }}/30{{ $t('my.mall.day') }}，{{ $t("my.finance.immediateReturn") }}{{ nobleInfo.length > 0 && nobleInfo[active] ? nobleInfo[active].returnPrice : '' }}{{ $t("my.finance.currency") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { configVip } from "@/api/config";
import { buyVip } from "@/api/index";
import utils from "@/utils/index";
export default {
  data() {
    return {
      nobleInfo: [],
      active: 0
    }
  },
  computed: {
    member() {
      return this.$store.state.member;
    }
  },
  created() {
    this.configVip();
  },
  methods: {
    configVip() {
      configVip().then(res => {
        if (res.code === 0 || res.code === '0') {
          this.nobleInfo = res.data;
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    copy(value) {
      if (utils.copy(value)) {
        this.$toast(this.$t('common.copySuccess'));
      } else {
        this.$toast(this.$t('common.copyLost'));
      }
    },
    goback() {
      this.$router.push(this.entry);
    },
    goOtherPage(path) {
      this.$router.push(path);
    },
    buyClick() {
      let that = this, obj = this.nobleInfo[this.active];
      this.$dialog.confirm({
        message: `${this.$t('my.noble.buyTips')} ${obj.price} ${this.$t('my.noble.buyTips1')} ${obj.name}`,
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        className: "confirmDialog",
        confirmButtonColor: "#eb457e"
      }).then(() => {
        that.buyVip(obj);
      }).catch(() => {
        // console.log("取消");
      });
    },
    buyVip(obj) {
      console.log(obj);
      let params = {
        levelId: obj.id
      }
      buyVip(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          this.$router.push('/mine');
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(()=> {})
    }
  }
}
</script>

<style lang="scss" scoped>
.noble {
  color: #fff;
  font-size: 0.28rem;
  height: auto !important;
  padding: 1.8rem 0 2.3rem;

  &.level1 {
      background-color: #242313;
  }
  &.level2 {
      background-color: #0e1123;
  }

  &.level3 {
      background-color: #1f1432;
  }

  &.level4 {
      background-color: #23180e;
  }
  &.level5 {
      background-color: #230e15;
  }

  &.next .title {
    box-shadow: 0 0 0 0 rgba($color: #000000, $alpha: 0);
    color: #fff;

    &.level1 {
        background-color: #242313;
    }
    &.level2 {
        background-color: #0e1123;
    }

    &.level3 {
        background-color: #1f1432;
    }

    &.level4 {
        background-color: #23180e;
    }
    &.level5 {
        background-color: #230e15;
    }
  }

  .listTop {
    height: 3.48rem;
    &.level1 {
        background-image: url('../../../assets/img/noble/vip/level1/bg1.png');
    }
    &.level2 {
        background-image: url('../../../assets/img/noble/vip/level2/bg1.png');
    }

    &.level3 {
        background-image: url('../../../assets/img/noble/vip/level3/bg1.png');
    }

    &.level4 {
        background-image: url('../../../assets/img/noble/vip/level4/bg1.png');
    }
    &.level5 {
        background-image: url('../../../assets/img/noble/vip/level5/bg1.png');
    }

    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 0.3rem;

    .iconBox {
      height: 2.8rem;
      position: relative;
      width: 2.8rem;
      margin: auto;

      .iconBg {
        position: relative;
        z-index: 1;
        opacity: 0.2;
        width: 3rem;
        bottom: 0rem;
        top: 0;
        left: 0;
        right: 0;
        display: inline-block;
        margin: auto;
      }

      .levelIcon {
        position: absolute;
        bottom: 0rem;
        top: 0;
        left: 0;
        right: 0;
        display: inline-block;
        margin: auto;
        z-index: 2;
        width: 2rem;
      }

    }
    p {
      margin-top: -0.1rem;
    }
  }

  .listBox {
    text-align: left;
    padding: 0.5rem 0.28rem 0;

    &.level1 {
        background-image: url('../../../assets/img/noble/vip/level1/bg2.png');
    }
    &.level2 {
        background-image: url('../../../assets/img/noble/vip/level2/bg2.png');
    }

    &.level3 {
        background-image: url('../../../assets/img/noble/vip/level3/bg2.png');
    }

    &.level4 {
        background-image: url('../../../assets/img/noble/vip/level4/bg2.png');
    }
    &.level5 {
        background-image: url('../../../assets/img/noble/vip/level5/bg2.png');
    }

    background-repeat: no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
    margin-top: -0.2rem;

    .listItem {
      display: inline-block;
      width: 33.3%;
      text-align: center;
      margin-bottom: 0.5rem;
      padding: 0 0.2rem;
      vertical-align: top;
      opacity: 0.5;

      &.has {
        opacity: 1;
      }

      .imgBox {
        width: 100%;
        height: 0.9rem;
      }

      .listItemTitle {
        color: #FCDDA5;
        margin: 0.2rem 0;
      }

      .listItemDetail {
        font-size: 0.24rem;
        color: #fff;
      }

      img {
        width: 0.6rem;
      }
    }
  }

  .nobleBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 2.3rem;
    width: 100%;
    z-index: 3;
    text-align: left;

    &.level1 {
        background-image: url('../../../assets/img/noble/vip/level1/bg3i.png');
    }
    &.level2 {
        background-image: url('../../../assets/img/noble/vip/level2/bg3i.png');
    }

    &.level3 {
        background-image: url('../../../assets/img/noble/vip/level3/bg3i.png');
    }

    &.level4 {
        background-image: url('../../../assets/img/noble/vip/level4/bg3i.png');
    }
    &.level5 {
        background-image: url('../../../assets/img/noble/vip/level5/bg3i.png');
    }

    background-size: 100% 100%;
    line-height: 0.4rem;

    .nobleBottomBox {
      padding: 0.5rem 0.28rem 0.2rem;
      font-size: 0.24rem;

      .buyBtn {
        padding: 0.1rem 0.4rem;
        color: #8b632c;
        background: -webkit-linear-gradient(#f9e8b3,#ffd195);
        background: linear-gradient(#f9e8b3,#ffd195);
        border-radius: 0.4rem;
      }

      span {
        font-size: 0.2rem;
        color: #d6af48;
      }

      .red {
        color: #c41f1a;
      }
    }
  }
}
</style>

<style lang="scss">
.noble {
  .van-tabs__wrap {
    overflow-x: scroll;
    position: fixed;
    top: 0.86rem;
    width: 100vw;
    z-index: 2;

    /* 隐藏滚动条 */
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      width: 0;      /* Safari,Chrome 隐藏滚动条 */
      height: 0;     /* Safari,Chrome 隐藏滚动条 */
      display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
    }
  }

  .van-tab__text--ellipsis {
    white-space: nowrap;
  }

  .van-tabs {
    &.level1 .van-tabs__nav{
        background-color: #242313;
    }
    &.level2 .van-tabs__nav{
        background-color: #0e1123;
    }

    &.level3 .van-tabs__nav{
        background-color: #1f1432;
    }

    &.level4 .van-tabs__nav{
        background-color: #23180e;
    }
    &.level5 .van-tabs__nav{
        background-color: #230e15;
    }
  }
}</style>