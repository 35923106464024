<template>
    <div class="next spreadTools">
        <div class="title">
            <span> {{$t('my.proxy.promotionTool')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="head">{{$t('my.invite.myExclusiveLink')}}</div>

        <div class="contBox">
            <div class="contItem flexBetween margTb">
                <div>{{detail.proxyUrlApp}}</div>
                <img @click="copyClick(detail.proxyUrlApp)" src="../../../assets/img/proxy/copy.png" />
            </div>
            <div class="contItem flexBetween">
                <div>{{$t('my.proxy.myPromotionalQRCode')}}</div>
                <img @click="downLoadboxShow(detail.proxyUrlApp)" src="../../../assets/img/proxy/download.png" />
            </div>
        </div>

        <van-dialog :className="'downDialog'" v-model:show="show" :closeOnClickOverlay="true" :showConfirmButton="false">
        <div class="dialogCont" ref="creditQrCodeShare">
            <div class="dialogHead">
                <div class="font1">
                    {{$t('my.proxy.downloadBgFontProxy')}}
                </div>
            </div>
            <img src="../../../assets/img/proxy/downBg.png" />
            <div class="downDialogFooter">
                <qrcode-vue id="qrcodeBox" 
                    :size="qrcodeVue.size"
                    :bgColor="qrcodeVue.bgColor"
                    :fgColor="qrcodeVue.fgColor" 
                    :value="shareUrl"></qrcode-vue>
                <div @click="saveImage" class="confirmBtn hasValue">{{$t('my.invite.download')}}</div>
            </div>
        </div>
    </van-dialog>
    </div>
</template>

<script>
import { queryProxyUrl } from "@/api/proxy";
import utils from "@/utils/index";
import QrcodeVue from "qrcode.vue"
import html2canvas from "html2canvas";
export default {
    components: {
        QrcodeVue 
    },
    data() {
        return {
            detail: {},
            qrcodeVue: {
                size: 100,
                bgColor: '#fff',
                fgColor: '#000'
            },
            show: false,
            shareUrl: ""
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
    },
    created() {
        this.queryProxyUrl();
    },
    methods: {
        // 下载图标
        downLoadboxShow(url) {
            this.shareUrl = url;
            this.show = true;
        },
        //保存图片
        saveImage() {
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            html2canvas(this.$refs.creditQrCodeShare, {
                backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
                useCORS: true, //支持图片跨域
                scale: 1, //设置放大的倍数
            }).then((canvas) => {
                // 把生成的base64位图片上传到服务器,生成在线图片地址
                let url = canvas.toDataURL("image/png"); // toDataURL: 图片格式转成 base64
                this.imgUrl = url;
                //将图片下载到本地
                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || this.$t('my.finance.QRCode'); // 设置图片名称没有设置则为默认
                a.href = this.imgUrl; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            });
        },
        gaBack() {
            this.$router.push('/mine/myProxy');
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        queryProxyUrl() {
            let params = {
                uid: this.member.uid
            }
            queryProxyUrl(params).then(res => {
                if (res.code == 0) {
                    this.detail = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.spreadTools {
    padding-top: 0.88rem;
    text-align: left;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
    }

    .head {
        color: #333;
        margin-top: 0.2rem;
        padding: 0 0.28rem;
    }

    .contBox {
        margin: 0 auto;
        width: calc(100% - 0.4rem);
        box-shadow: 0 2px 4px 0 #C2D9F9;
        color: #243050;
        background-color: #fff;
        margin-top: 0.2rem;
        margin-bottom: 0.4rem;
        padding: 0.3rem 0.48rem;
        border-radius: 0.1rem;

        .margTb {
            margin-bottom: 0.4rem;
        }

        img {
            width: 0.44rem;
        }

        div div:first-child {
            max-width: calc(100% - 0.46rem);
        }
    }

    .dialogCont {
        width: 5.6rem;
        height: 10rem;
        margin: auto;
        position: relative;

        .dialogHead {
            position: absolute;
            top: 1.5rem;
            left: 0;
            width: 100%;
            text-align: center;

            div {
                width: 100%;
                text-align: center;

                &.font1 {
                    font-size: 0.28rem;
                    color: #f74995;
                    font-weight: 700;
                    text-shadow: 0 0.5px #fff, 0.5px 0 #fff, -0.5px 0 #fff, 0 -0.5px #fff;
                    padding: 0 0.2rem;
                }
            }
        }

        img {
            width: 5.76rem;
            height: 10rem;
        }

        .downDialogFooter {
            position: absolute;
            bottom: 0.4rem;
            left: 0.28rem;
            width: calc(100% - 0.56rem);
            text-align: center;

            .confirmBtn {
                width: 100%;
                margin-top: 0.2rem;
                border-radius: 0;
            }
        }
    }
}
</style>

<style lang="scss">
.downDialog {
    &.van-dialog {
        background: transparent;
    }
}
</style>