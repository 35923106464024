<template>
  <div>
    <van-popup
        v-model:show="isShowAudienceList"
        position="bottom"
        round
        @close="close"
        :safe-area-inset-bottom="true"
        class="gameListDialog"
        >
        <div class="audienceListDialogBox">
            <div class="audienceListDialogContTop">
                <span :class="{'active': audienceListActive == 0}" @click="audienceListActive=0">{{ $t('liveRoom.audience') }}</span>
                <span :class="{'active': audienceListActive == 1}" @click="audienceListActive=1">{{ $t('my.menuList.noble') }}</span>
            </div>

            <div class="audienceListDialogCont">
                <div v-for="(item, index) in showList" :key="`audienceListDialog-${index}`" @click="goUserInfo(item)" class="audienceListDialogContItem flexCenter">
                    <img v-if="!item.roomHide" class="audienceAvatar" v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/3.png`)" />
                    <img v-else class="audienceAvatar" src="../../assets/img/live/mysteryMan.png"/>
                    <div class="audienceItemCont">
                        <div class="flexCenter">
                            <div>{{ !item.roomHide ? item.nickname : $t('liveRoom.mysteryMan') }}</div>
                            <img v-if="item.sex && item.sex === 2" class="sex" src="../../assets/img/my/icon/woman.png" />
                            <img v-else class="sex" src="../../assets/img/my/icon/man.png" />

                            <div class="audienceLevel">
                                <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                                <span :class="{'small': item.userLevel < 10}">{{ item.userLevel }}</span>
                            </div>
                        </div>
                        <div class="audienceUserID">
                            ID: {{ item.vipUid ? item.vipUid : item.uid }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="showList.length == 0" class="noData">
                <img src="../../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { userList, userViplist } from "@/api/live";
import { mapState } from 'vuex';
export default {
    name: "liveRoomAudienceList",
    data() {
        return {
            isShowAudienceList: false,
            audienceListActive: 0, //观众列表/贵族列表选中
            audienceList: [], //用户列表
            vipList: [], //vip列表
        }
    },
    computed: {
        ...mapState(['liveInfo']),
        showList() {
            if (this.audienceListActive == 0) {
                return this.audienceList;
            } else {
                return this.vipList; 
            }
        }
    },
    created() {
        let that = this;
        setTimeout(()=> {
            that.isShowAudienceList = true;
        }, 10);

        this.getUserList();
        this.getVipList();
    },
    methods: {
        close() {
            this.isShowAudienceList = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('closeAudienceList')
            }, 500);
        },
        goUserInfo(item) {
            if(item.roomHide) return;
            this.$store.commit("setState", { betPramas: item });
            this.$emit('openPopup', 'showUserInfo')
            this.close();
        },
        // 用户详情界面关闭
        closeUserInfo() {
            this.showUserInfo = false;
        },
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
        // 用户列表
        getUserList() {
            let params = {
                liveId: this.liveInfo.liveId
            }
            userList(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.audienceList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        },
        // vip列表
        getVipList() {
            let params = {
                liveId: this.liveInfo.liveId
            }
            userViplist(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.vipList = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        }
    }
}
</script>

<style lang="scss" scoped>
.audienceListDialogBox {
    min-height: 6rem;
}
.audienceListDialogContTop {
    font-size: 0.34rem;
    width: 100%;
    padding-top: 0.24rem;

    span {
        display: inline-block;
        padding-bottom: 0.1rem;
        border-bottom: 2px solid transparent;

        &:nth-child(1) {
            margin-right: 0.8rem;
        }

        &.active {
            border-color: #eb457e;
        }
    }
}

.audienceListDialogCont {
    max-height: 50vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0 0.28rem;

    .audienceListDialogContItem {
        border-bottom: 1px solid #c4c4c4;
        width: 100%;
        padding: 0.2rem 0 0.22rem;

        &:last-child {
            border-bottom: none;
        }

        .audienceAvatar {
            width: 0.56rem;
            height: 0.56rem;
            border-radius: 50%;
            margin-right: 0.2rem;
            object-fit: cover;

            div:last-child {
                font-size: 0.2rem;
            }
        }

        .audienceItemCont {
            margin-right: 0.4rem;
            text-align: left;

            .sex {
                width: 0.35rem;
                height: 0.35rem;
                margin: 0 0.1rem;
            }

            .audienceLevel {
                position: relative;

                img {
                    width: 0.7rem;
                    height: 0.35rem;
                }

                span {
                    position: absolute;
                    right: 0.05rem;
                    color: #fff;
                    line-height: 0.35rem;
                    font-size: 0.2rem;

                    &.small {
                        right: 0.12rem;
                    }
                }
            }

            .audienceUserID {
                font-size: 0.2rem;
            }
        }
    }
}
</style>