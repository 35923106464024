<template>
  <div class="next setting_next">
    <!-- 修改登录密码 -->
    <div v-if="type=='password'&& step == 1">
        <div class="title">
            <span> {{$t('my.eidtLoginPassword')}} </span>
            <div class="fixed" @click="goOtherPage('/mine/userInfo/edit')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>
        </div>

        <div class="userInfo_next_cont">
            <div class="itemTitle"> {{$t('my.enterOldPwdTips')}}</div>
            <van-field class="am-list-item" v-model.trim="fund.passwordOld" autocomplete="off" :maxlength="12" v-show="isShowPsw" type="password" @click-right-icon="changePswType(false, 'isShowPsw')" :right-icon="require('../../assets/img/eyeCloseR.png')" clearable :placeholder="`${$t('my.enterOldPwdTips')}`"/>
            <van-field class="am-list-item" v-model.trim="fund.passwordOld" autocomplete="off" :maxlength="12" v-show="!isShowPsw" @click-right-icon="changePswType(true, 'isShowPsw')" :right-icon="require('../../assets/img/eyeOpen.png')" clearable :placeholder="`${$t('my.enterOldPwdTips')}`"/>

            <div class="itemTitle margiTop">{{$t('my.setNewPwd')}}</div>
            <!--  @input="checkPwdNew('passwordNew')"  @input="checkPwdNew('passwordNew')"-->
            <van-field class="am-list-item" v-model.trim="fund.passwordNew" autocomplete="off" :maxlength="12" v-show="isShowPswNew" type="password" @click-right-icon="changePswType(false, 'isShowPswNew')" :right-icon="require('../../assets/img/eyeCloseR.png')" clearable :placeholder="`${$t('my.enterNewPwdTips')}`"/>
            <van-field class="am-list-item" v-model.trim="fund.passwordNew" autocomplete="off" :maxlength="12" v-show="!isShowPswNew" @click-right-icon="changePswType(true, 'isShowPswNew')" :right-icon="require('../../assets/img/eyeOpen.png')" clearable :placeholder="`${$t('my.enterNewPwdTips')}`"/>
            <!--  @input="checkPwd('passwordNew')" @input="checkPwd('passwordNew')"-->
            <van-field class="am-list-item" v-model.trim="fund.confirmPsw" autocomplete="off" :maxlength="12" v-show="isShowPswCom" type="password" @click-right-icon="changePswType(false, 'isShowPswCom')" :right-icon="require('../../assets/img/eyeCloseR.png')" clearable :placeholder="`${$t('my.confirmNewPwdTips')}`"/>
            <van-field class="am-list-item" v-model.trim="fund.confirmPsw" autocomplete="off" :maxlength="12" v-show="!isShowPswCom" @click-right-icon="changePswType(true, 'isShowPswCom')" :right-icon="require('../../assets/img/eyeOpen.png')" clearable :placeholder="`${$t('my.confirmNewPwdTips')}`"/>
            <p class="pswTip">{{$t('my.enterLeastSix')}}</p>
        </div>

        <div v-show="!canSubmitEditLogin" class="confirmBtn">{{$t('common.confirm')}}</div>
        <div v-show="canSubmitEditLogin" @click="editLoginPsw" class="confirmBtn hasValue">{{$t('common.confirm')}}</div>
    
        <p @click="goForgerPsw" class="forgetPsw">{{ $t('login.forgetPsw') }}</p>
    </div>

    <!-- 取款密码 -->
    <div v-if="type=='fundPsw' && step == 1">
        <div class="title">
            <span> {{$t('my.setting.editFundPsw')}} </span>
            <div class="fixed" @click="goOtherPage('/mine/set')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>
        </div>

        <div class="userInfo_next_cont">
            <div v-show="member && member.phone && member.email" class="cardsTab">
                <ul class="flexCenter">
                    <li :class="{'active': verifyType == 'moblie' }" @click="verifyType='moblie'">{{$t('entry.phoneVerification')}}</li>
                    <li :class="{'active': verifyType == 'email' }" @click="verifyType='email'">{{$t('entry.emailVerification')}}</li>
                </ul>
            </div>

            <!-- <div class="itemTitle tips">{{showTip ? tips : ''}}</div> -->
            <div class="itemTitle">{{$t('my.setting.editFundPsw')}}</div>
            <van-field class="am-list-item" autocomplete="off" v-show="isShowFunPsw" type="password" @input="checkPwdNew('password')" @click-right-icon="changePswType(false, 'isShowFunPsw')" right-icon="closed-eye" minlength="6" maxlength="12" v-model.trim="fund.password" clearable :placeholder="`${$t('my.setting.editFundPswTips')}`"/>
            <van-field class="am-list-item" autocomplete="off" v-show="!isShowFunPsw" @input="checkPwdNew('password')" @click-right-icon="changePswType(true, 'isShowFunPsw')" :right-icon="require('../../assets/img/eyeOpen.png')" minlength="6" maxlength="12" v-model.trim="fund.password" clearable :placeholder="`${$t('my.setting.editFundPswTips')}`"/>

            <div class="itemTitle">{{$t('entry.confirmPad')}}</div>
            <van-field class="am-list-item" autocomplete="off" v-show="isShowFunPswCom" type="password" @click-right-icon="changePswType(false, 'isShowFunPswCom')" right-icon="closed-eye" @input="checkPwd('password')" minlength="6" maxlength="12" v-model.trim="fund.confirmPsw" clearable :placeholder="`${$t('my.setting.checkPswTips')}`"/>
            <van-field class="am-list-item" autocomplete="off" v-show="!isShowFunPswCom" @click-right-icon="changePswType(true, 'isShowFunPswCom')" :right-icon="require('../../assets/img/eyeOpen.png')" @input="checkPwd('password')" minlength="6" maxlength="12" v-model.trim="fund.confirmPsw" clearable :placeholder="`${$t('my.setting.checkPswTips')}`"/>

            <div v-if="verifyType=='moblie'">
                <div class="itemTitle">{{$t('my.cards.phoneNumCode')}}</div>
                <div class="flexCenter">
                    <div style="width: 0.9rem;" v-if="member && member.areaCode">+{{member.areaCode}}</div>
                    <van-field v-model.trim="fund.mobile" autocomplete="off" disabled>
                        <template #button>
                            <van-button @click="getCode" v-show="isGetCode" size="small" type="primary">{{$t('common.getCode')}}</van-button>
                            <van-button v-show="!isGetCode" disabled size="small" type="primary">{{getCodeVal}}</van-button>
                        </template>
                    </van-field>
                </div>

                <van-field class="MT-margin" v-model.trim="fund.vcode"  :maxlength="4" :label="`${$t('my.cards.codeNum')}`" autocomplete="off" clearable :placeholder="$t('entry.registerCodeTips')" />
            </div>

            <div v-if="verifyType=='email'">
                <div class="itemTitle">{{$t('entry.emailVerification')}}</div>
                <van-field v-model.trim="fund.email" autocomplete="off" disabled>
                    <template #button>
                        <van-button @click="getEmailCode" v-show="isGetEmailCode" size="small" type="primary">{{$t('common.getCode')}}</van-button>
                        <van-button v-show="!isGetEmailCode" disabled size="small" type="primary">{{getEmailCodeVal}}</van-button>
                    </template>
                </van-field>

                <van-field class="MT-margin" v-model.trim="fund.vcode" :label="`${$t('my.cards.codeNum')}`" autocomplete="off" clearable :placeholder="$t('entry.registerCodeTips')"/>
            </div>
        </div>

        <div v-show="!canSubmit" class="confirmBtn">{{$t('common.submit')}}</div>
        <div v-show="canSubmit" @click="setCashPassword" class="confirmBtn hasValue">{{$t('common.submit')}}</div>
    </div>

    <div class="finishBox" v-show="step == 2">
        <div class="title">
            <!-- 文案已删除 -->
            <!-- <span>{{$t('my.setting.verifyIdentidy')}}</span> -->
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>
        </div>

        <div class="finish">
            <van-icon name="checked" />
            <!-- <p>{{$t('entry.editPadSuccessTips')}}</p> -->
        </div>

        <div class="userInfo_next_footer">
            <van-button @click="goBack" type="primary" block>{{$t('common.finish')}}</van-button>
        </div>
    </div>
  </div>
</template>

<script>
import { cashPassword, sendVcode, updateCashPassword, updatePwd, sendEmailVcode } from "@/api/index";
import utils from "@/utils/index";
export default {
    data() {
        return {
            type: "",
            password: "",
            fund: {
                password: "",
                vcode: "",
                confirmPsw: "",
                mobile: "",
                email: ""
            },
            // showTip: false,
            // tips: "",
            canContinue: false,
            isGetCode: true,
            getCodeVal: this.$t('common.getCode'),
            timer: null,
            isGetEmailCode: true,
            getEmailCodeVal: this.$t('common.getCode'),
            timerEmail: null,
            verifyType: "moblie",
            isShowPsw: true, //登录原密码是否明文显示
            isShowPswNew: true, //登录新密码是否明文显示
            isShowPswCom: true, //登录确认新密码是否明文显示
            isShowFunPsw: true, //取款密码是否明文显示
            isShowFunPswCom: true, //取款确认密码是否明文显示
            step: 1
        }
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
        setPasswordEntry() {
            return this.$store.state.setPasswordEntry;
        },
        needCashPassword() {// 是否设置取款密码
            if (this.member && this.member.needCashPassword) {
                return true;
            }
            return false;
        },
        canSubmit() {
            if (this.fund.password && this.fund.confirmPsw && this.fund.password == this.fund.confirmPsw && this.fund.vcode) {
                return true;
            }
            return false;
        },
        canSubmitEdit() {
            if (this.fund.passwordOld && this.fund.passwordNew && this.fund.confirmPsw && this.fund.passwordNew == this.fund.confirmPsw) {
                return true;
            }
            return false;
        },
        canSubmitEditLogin() {
            if (this.fund.passwordOld && this.fund.passwordNew && this.fund.confirmPsw && this.fund.passwordNew == this.fund.confirmPsw) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.type = this.$route.params.type ? this.$route.params.type : this.type;

        if (this.member && this.member.email && !this.member.phone) {
            this.verifyType = "email";
        }
        
        if (this.member && this.member.email) {
            this.fund.email = utils.emailHide(this.member.email);
        }

        if (this.member && this.member.phone) {
            this.fund.mobile = utils.phoneHide(this.member.phone);
        }
    },
    methods: {
        // 前往忘记密码界面
        goForgerPsw() {
            let username = this.member.phone ? this.member.phone : this.member.email;
            this.$router.push(`/findPassword?username=${username}`)
        },
        // 密码修改成功 返回
        goBack() {
            if (this.type=='fundPsw') {
                this.$store.dispatch('refreshUserInfo');
            }

            if (this.type=='fundPsw' && this.setPasswordEntry) {
                this.$router.push(this.setPasswordEntry);
            } else {
                this.$router.push("/mine/userInfo");
            }
        },
        // 密码显示改变 明文或密文显示
        changePswType(boolean, filed) {
            this[filed] = boolean;
        },
        // 输入登录密码
        checkPwdNew(filed) {
            if (this.fund[filed].length < 6 || this.fund[filed].length > 16) {
                this.showTip = true;
            }

            if (this.fund[filed].length >= 6 && this.fund[filed].length <= 16) {
                this.showTip = false;
            }
        },
        // 确认密码
        checkPwd(filed) {
            // this.tips = this.$t('my.setting.checkTwoPswTips');
            if (this.fund.confirmPsw != this.fund[filed]) {
                this.showTip = true;
            }

            if (this.fund.confirmPsw == this.fund[filed]) {
                this.showTip = false;
            }
        },
        // 手机验证码
        getCode() {
            let params = {
                mobile: this.member.phone,
                type: 5,
                areaCode: this.member.areaCode
            }
            sendVcode(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('entry.sendCodeTips'));
                    let time = 179;
                    let that = this;
                    this.isGetCode = false;
                    this.getCodeVal = time+'s';
                    this.timer = setInterval(() => {
                        if (time > 0) {
                            time --;
                            that.getCodeVal = time+'s';
                        } else {
                            that.getCodeVal = this.$t('common.getCode');
                            that.isGetCode = true;
                            clearInterval(this.timer);
                        }
                    }, 1000);
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        // 邮箱验证码
        getEmailCode() {
            let params = {
                email: this.member.email,
                type: 5
            }
            sendEmailVcode(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('entry.sendCodeTips'));
                    let time = 179;
                    let that = this;
                    this.isGetEmailCode = false;
                    this.getEmailCodeVal = time+'s';
                    this.timerEmail = setInterval(() => {
                        if (time > 0) {
                            time --;
                            that.getEmailCodeVal = time+'s';
                        } else {
                            clearInterval(this.timerEmail);
                            that.getEmailCodeVal = this.$t('common.getCode');
                            that.isGetEmailCode = true;
                        }
                    }, 1000);
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        setCashPassword() {
            let params = {};
            if (this.verifyType == "moblie") {
                params = {
                    mobile: this.member.phone,
                    password: this.fund.password,
                    vcode: this.fund.vcode,
                    areaCode: this.member.areaCode,
                    type: 1
                }
                
            } else {
                params = {
                    email: this.member.email,
                    password: this.fund.password,
                    vcode: this.fund.vcode,
                    type: 2 //1：手机号；2：邮箱
                }
            }

            cashPassword(params).then(res => {
                if (res.code == 0) {
                    this.step = 2;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log("catch");
            });
        },
        editCashPassword() {
            let params = {
                mobile: this.member.phone,
                passwordNew: this.fund.passwordNew,
                passwordOld: this.fund.passwordOld,
                vcode: this.fund.vcode,
                areaCode: this.member.areaCode
            }
            updateCashPassword(params).then(res => {
                if (res.code == 0) {
                    this.step = 2;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {

            });
        },
        editLoginPsw() {
            let params = {
                content: this.member.phone,
                newPassword: this.fund.passwordNew,
                oldPassword: this.fund.passwordOld,
                newConfirmPassword: this.fund.confirmPsw,
                uid: this.member.uid,
                type: 1
            }
            updatePwd(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('common.eidtSuccess'));
                    this.$router.push('/mine/userInfo/edit');
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {});
        },
        goOtherPage(path) {
            if (this.type=='fundPsw' && this.setPasswordEntry) {
                this.$router.push(this.setPasswordEntry);
            } else {
                this.$router.push(path);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
        clearInterval(this.timerEmail);
    }
}
</script>

<style lang="scss" scoped>
.setting_next {
    .tab {
        border-bottom: 1px solid rgba(238, 238, 238, 0.5);
        margin-bottom: 0.2rem;
        line-height: 0.8rem;
        height: 0.8rem;
    }
    .userInfo_next_cont {
        background: #fff;
        text-align: left;
        padding: 0.4rem 0.28rem 0.28rem;
        margin-bottom: 0.2rem;

        .pswTip {
            font-size: 0.24rem;
            margin-top: -0.05rem;
        }

        .itemTitle {
            margin-bottom: 0.12rem;
            font-size: .28rem;
            color: #414655;

            &.tips {
                color: #db6372;
                height: 0.35rem;    
                margin-bottom: 0.15rem;
            }

            &.margiTop {
                margin-top: 0.4rem;
            }
        }

        .van-cell {
            padding: 0;
        }

        .MT-margin.van-cell {
            margin-top: 0.3rem;
        }

        .cardsTab {
            padding: 0.28rem 0;
            background: #fff;

            ul {
                -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
                background: #EBEFF1;
                border-radius: 0.16rem;
                padding: 0.02rem;

                li {
                    flex: 1 1;
                    text-align: center;
                    line-height: 0.58rem;
                    height: 0.58rem;
                    color: #bbb;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.27rem;
                    margin: 0.02rem;

                    &.active {
                        background: #fff;
                        border-radius: 0.16rem;
                        color: #333;
                    }
                }
            }
        }
    }

    .confirmBtn {
        margin: 0.6rem auto 0;
        width: 5rem;
    }

    .finishBox {
        padding: 0 0.28rem;

        .marginTop {
            margin-top: 0.2rem;
        }

        .finish {
            margin: 1.17rem auto;

            i {
                font-size: 1.6rem;
                color: #52C41A;
            }

            p {
                color: #626872;
                margin-top: 0.6rem;
                font-size: 0.25rem;
            }
        }
    }

    .forgetPsw {
        margin-top: 0.4rem;
        color: #8cd5ff;
    }
}
</style>
<style lang="scss">
.setting_next .userInfo_next_cont .van-cell {
    .van-field__control {
        font-size: 0.3rem;
        color: #333;

        &:disabled {
            color: #333;
            -webkit-text-fill-color: #333;

            &::-webkit-input-placeholder {
                /*Webkit browsers*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }

            &:-moz-placeholder {
                /*Mozilla Firefox 4 to 8*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }

            &::moz-placeholder {
                /*Mozilla Firefox 19+*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }

            &:-ms-input-placeholder {
                /*Internet Explorer 10+*/
                color: #C4C4C4;
                -webkit-text-fill-color:#C4C4C4;
                font-size: 0.28rem;
            }
        }

        &::-webkit-input-placeholder{/*Webkit browsers*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }
        &:-moz-placeholder{/*Mozilla Firefox 4 to 8*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }
        &::moz-placeholder{/*Mozilla Firefox 19+*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }
        &:-ms-input-placeholder{/*Internet Explorer 10+*/
            color:#C4C4C4;
            font-size: 0.28rem;
        }

    }

    &.am-list-item {
        margin-bottom: 0.2rem;

        .van-field__body {
            border-bottom: 1px solid rgba(238,238,238,.5);
            padding-bottom: 0.2rem;
        }
    }
    
}
</style>